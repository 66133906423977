import React, { useState, useCallback, useEffect } from "react";
import { Typography, Button, Tooltip, IconButton } from "@material-ui/core";
import { Table } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  addFinancing,
  deleteFinancing,
  getCompaniesFinancingColumnWidths,
  updateFinancing,
} from "../../store/sourcing/sourcingThunks";
import DatePicker from "../../example-components/FormsDatepicker/FormsDatepicker1";
import Snackbar from "@material-ui/core/Snackbar";
import { Done, Close, Delete } from "@material-ui/icons";
import styles from "./styles.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { selectCompaniesFinancingsColumnWidths } from "../../store/sourcing/sourcingSlice";
import {
  formatColumnFormats,
  formatContent,
  checkFormat,
  FORMATTING_TYPES,
  parseDate,
} from "../../utils/formatting";
import Pagination from "../Pagination/Pagination";
import usePagination from "../../hooks/usePagination";
import MPLSelect from "../../pages/Sourcing/Financings/MPLSelect";
import EditIcon from "../EditIcon/EditIcon";
import DeleteIcon from "@material-ui/icons/Delete";

interface FinancingProps {
  tableData: any;
  companyId: number;
  pagesDisplayed?: boolean;
  handleRefresh: () => void;
}

const Financing = ({
  tableData,
  companyId,
  pagesDisplayed = true,
  handleRefresh,
}: FinancingProps) => {
  const [financingData, setFinancingData] = useState([] as any);
  const [rowToUpdate, setRowToUpdate] = useState([] as any);
  const [headers, setHeaders] = useState([] as any);
  const [columnFormatValues, setColumnFormatValues] = useState(null);
  let columnWidths = useSelector(selectCompaniesFinancingsColumnWidths);

  useEffect(() => {
    dispatch(getCompaniesFinancingColumnWidths());
  }, []);

  useEffect(() => {
    if (columnWidths?.rows?.length) {
      setColumnFormatValues(formatColumnFormats(columnWidths.rows));
    }
  }, [columnWidths]);

  useEffect(() => {
    let tableHeaders = tableData.columns;
    if (tableData.data.length) {
      tableHeaders = Object.keys(tableData.data[0]);
    }
    setHeaders(tableHeaders);
    setFinancingData(
      tableData.data.map((row: any) => {
        let finalRow = [] as any;
        tableHeaders.map((header: any) => finalRow.push(row[header]));

        return finalRow;
      })
    );
  }, [tableData.data]);

  const [insertedData, setInsertedData] = useState({} as any);
  const [isInsertOpen, setIsInsertOpen] = useState(false);
  const [updatedRowIndex, setUpdatedRowIndex] = useState(-1);
  const dispatch = useDispatch();
  const [notificationData, setNotificationData] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    toastrStyle: "",
    message: "This is a toastr/snackbar notification!",
  });

  const { vertical, horizontal, open, toastrStyle, message } = notificationData;
  const { currentPage, rowsPerPage, goToPageValue, goToPage, totalPages, handleRowsPerPageChange, handleGoToPageChange} = usePagination({ rows: financingData });

  const addRow = () => {
    setIsInsertOpen(true);
    let newRow = {} as any;
    headers.slice(2, headers.length - 1).forEach((header: string) => {
      if(checkFormat(
        header,
        columnFormatValues,
        FORMATTING_TYPES.DATE
      )) {
        newRow = { ...newRow, ...{ [header]: parseDate(new Date()) } };
      } else {
        newRow = { ...newRow, ...{ [header]: "" } };
      }
    });
    setInsertedData(newRow);
  };

  const cancelInsert = () => {
    setIsInsertOpen(false);
  };

  const handleInputChange = useCallback(
    (value: any, columnName: string) => {
      setInsertedData((previous: any) => {
        if (typeof value === "object") {
          previous[columnName] = parseDate(value);
        } else {
          previous[columnName] = value;
        }
        return { ...previous };
      });
    },
    [setInsertedData]
  );

  const insertRow = useCallback(async () => {
    setIsInsertOpen(false);
    await dispatch(
      addFinancing({
        data: insertedData,
        companyId: companyId.toString(),
      })
    );
    openNotification({
      message: "Data inserted successfully",
      toastrStyle: "toastr-primary",
      vertical: "top",
      horizontal: "right",
    });
    handleRefresh();
  }, [insertedData]);

  const handleRowUpdate = useCallback(
    (index: number) => {
      setRowToUpdate([...financingData[index]]);
      setUpdatedRowIndex(index);
    },
    [financingData]
  );

  const handleRowDelete = useCallback(
    async (index: number) => {
      if (financingData[index][0]) {
        await dispatch(deleteFinancing(financingData[index][0]));
      }

      openNotification({
        message: "Data deleted successfully",
        toastrStyle: "toastr-primary",
        vertical: "top",
        horizontal: "right",
      });

      handleRefresh();
    },
    [financingData]
  );

  const cancelUpdate = useCallback(
    (index: number) => {
      setFinancingData((previous: any) => {
        previous[index] = rowToUpdate;
        return [...previous];
      });
      setUpdatedRowIndex(-1);
    },
    [rowToUpdate]
  );

  const openNotification = useCallback((newState: any) => {
    setNotificationData({ open: true, ...newState });
    const timer = setTimeout(() => {
      setNotificationData({ ...notificationData, open: false });
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  const saveUpdate = useCallback(
    async (index: number) => {
      let finalData = {} as any;
      headers.map((col: string, i: number) => {
        finalData = {
          ...finalData,
          ...{ [col]: financingData[index][i] },
        };
      });

      let isDataFilled = true;

      Object.keys(finalData).forEach((d: any) => {
        if (!finalData[d]) {
          isDataFilled = false;
          return;
        }
      });

      setUpdatedRowIndex(-1);

      await dispatch(updateFinancing(finalData));
      if (isDataFilled) {
        openNotification({
          message: "Data updated successfully",
          toastrStyle: "toastr-primary",
          vertical: "top",
          horizontal: "right",
        });
      }
      handleRefresh();
    },
    [financingData]
  );

  const handleUpdateChange = useCallback(
    (value: any, rowIndex: number, colIndex: number) => {
      setFinancingData((previous: any) => {
        if (typeof value === "object") {
          previous[rowIndex][colIndex] = parseDate(value);
        } else {
          previous[rowIndex][colIndex] = value;
        }
        //previous[rowIndex][colIndex] = value;
        return [...previous];
      });
    },
    []
  );

  const handleClose = () => {
    setNotificationData({ ...notificationData, open: false });
  };

  const handleMPLSelect = (option: string, rowIndex: number, colIndex: number) => {
    handleUpdateChange(
        option,
        rowIndex,
        colIndex
    )
  };

  return (
    <>
      <div className={styles.financingHeader}>
        <Typography
          style={{ whiteSpace: "nowrap" }}
          className="mr-2 font-14 font-weight-bold"
        >
          Financing
        </Typography>
        {!isInsertOpen && (
          <div id="blockElement" className={styles.addNewEntryBtnContainer}>
            <Tooltip title="Add new entry" arrow placement="top">
              <Button
                id="blockElement"
                className="btn-primary mx-1 p-1 shadow-none border-0 d-inline-flex align-items-center justify-content-center"
                onClick={addRow}
              >
                <FontAwesomeIcon
                  icon={["fas", "plus"]}
                  className="font-size-sm"
                />
                <span className="ml-1 font-10">Add New</span>
              </Button>
            </Tooltip>
          </div>
        )}
      </div>
      <div className="table-responsive-md mb-spacing-2-x2">
        <Table
          className={`table table-hover table-striped table-bordered ${styles.table}`}
        >
          <thead className={styles.tableHead}>
            <tr className="font-12">
              {headers
                .slice(0, headers.length - 1)
                .map(
                  (header: string, index: number) =>
                    index > 1 && <th key={index}>{header}</th>
                )}
              <th id="actions" className={`${styles.actionsColumn} font-12`}>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {financingData.slice((currentPage - 1) * rowsPerPage, (currentPage - 1) * rowsPerPage + rowsPerPage)
            ?.map((row: any, index: number) => {
              const rowIndex = (currentPage - 1) * 5 + index;
              return (
                <tr
                  className={
                    (rowIndex !== updatedRowIndex && updatedRowIndex !== -1) ||
                    isInsertOpen
                      ? styles.disabledRow
                      : ""
                  }
                  key={index}
                >
                  {row
                    .slice(0, row.length - 1)
                    .map((col: any, colIndex: number) => {
                      if (colIndex > 1) {
                        return rowIndex !== updatedRowIndex ? (
                          <td key={colIndex} className="font-12">
                            {formatContent(
                              row,
                              col,
                              headers[colIndex],
                              columnFormatValues
                            )}
                          </td>
                        ) : (
                          <td key={colIndex}>
                            {checkFormat(
                              headers[colIndex],
                              columnFormatValues,
                              FORMATTING_TYPES.DATE
                            ) ? (
                              <DatePicker
                                handleSelectDate={(date: any) =>
                                  handleUpdateChange(date, rowIndex, colIndex)
                                }
                                selectedDate={col}
                              ></DatePicker>
                            ) : (
                              // @ts-ignore
                                headers[colIndex] === 'Miss / Pass / Lost' ? <MPLSelect handleSelect={(option) => handleMPLSelect(option, rowIndex, colIndex)} defaultValue={col}/> : <input
                                  type="text"
                                  value={col}
                                  className={styles.input}
                                  onChange={(e) =>
                                      handleUpdateChange(
                                          e.target.value,
                                          rowIndex,
                                          colIndex
                                      )
                                  }
                              />
                            )}
                          </td>
                        );
                      }
                    })}
                  <td id="actions" className="text-center">
                    {updatedRowIndex === -1 || rowIndex !== updatedRowIndex ? (
                      <>
                        <IconButton
                            aria-label="eidt"
                            size="small"
                            onClick={() => handleRowUpdate(rowIndex)}
                        >
                          <EditIcon fontSize='small' />
                        </IconButton>
                        <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={() => handleRowDelete(rowIndex)}
                        >
                          <DeleteIcon fontSize='small' />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        <Button
                          size="small"
                          className={`${"btn-neutral-primary"} mb-2 mr-1`}
                          onClick={() => saveUpdate(rowIndex)}
                        >
                          <Done/>
                        </Button>
                        <Button
                          size="small"
                          className={`${"btn-neutral-primary"} mb-2 ml-1`}
                          onClick={() => cancelUpdate(rowIndex)}
                        >
                          <Close/>
                        </Button>
                      </>
                    )}
                  </td>
                </tr>
              );
            })}
            {isInsertOpen && (
              <tr>
                {Object.keys(insertedData).map((col, index) => (
                  <td key={index}>
                    {checkFormat(
                              headers[index+2],
                              columnFormatValues,
                              FORMATTING_TYPES.DATE
                            ) ? (
                      <DatePicker
                        handleSelectDate={(date: any) =>
                          handleInputChange(date, col)
                        }
                        selectedDate={insertedData[col]}
                      ></DatePicker>
                      
                    ) : headers[index+2] === 'Miss / Pass / Lost' ?
                    <MPLSelect handleSelect={(option: any) => handleInputChange(option, col)} defaultValue={insertedData[col]} />
                   : (
                      <input
                        type="text"
                        value={insertedData[col]}
                        className={styles.input}
                        onChange={(e) => handleInputChange(e.target.value, col)}
                      />
                    )}
                  </td>
                ))}
                <td className="text-center">
                  <Button
                    size="small"
                    className={`${"btn-neutral-primary"} mb-2 mr-1`}
                    onClick={insertRow}
                  >
                    <Done/>
                  </Button>
                  <Button
                    size="small"
                    className={`${"btn-neutral-primary"} mb-2 ml-1`}
                    onClick={cancelInsert}
                  >
                    <Close/>
                  </Button>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        {pagesDisplayed && (
          <Pagination goToPage={goToPage} rowsPerPage={rowsPerPage} currentPage={currentPage} goToPageValue={goToPageValue} totalPages={totalPages}
          handleRowsPerPageChange={handleRowsPerPageChange} handleGoToPageChange={handleGoToPageChange} inCenter={true}/>
        )}
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          key={`${vertical},${horizontal}`}
          open={open}
          classes={{ root: toastrStyle }}
          onClose={handleClose}
          message={message}
        />
      </div>
    </>
  );
};

export default Financing;
