import React, { useState } from "react";
import { IconButton } from "@material-ui/core";
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatBoldIcon from '@material-ui/icons/FormatBold';

const editOptions = [
    { label: 'Bold', style: 'strong', Icon: FormatBoldIcon },
    { label: 'Italic', style: 'em', Icon: FormatItalicIcon },
    { label: 'Underline', style: 'u', Icon: FormatUnderlinedIcon },
    { label: 'Bullet List', style: 'ul', Icon: FormatListBulletedIcon },
    { label: 'Number List', style: 'ol', Icon: FormatListNumberedIcon }
];

function HtmlEditor({ note, setUpdatedNote }) {
    const [htmlContent, setHtmlContent] = useState(note);

    const updateHtmlContent = (newHtml) => {
        setHtmlContent(newHtml);
        if (setUpdatedNote) {
            setUpdatedNote(newHtml);
        }
    };

    const toggleHtmlTag = (tag) => {
        const div = document.querySelector('[contenteditable]');
        const selection = window.getSelection();
        if (!selection.rangeCount) return;

        const range = selection.getRangeAt(0);
        const selectedText = range.toString();
        const container = range.commonAncestorContainer;
        const parentElement = container.nodeType === 3 ? container.parentNode : container; // Check if the selection is text and get parent

        // Check if the parent or any ancestor has the specified tag (simplified check)
        const isAlreadyFormatted = parentElement.closest(tag);

        if (isAlreadyFormatted) {
            // If already formatted, remove the tags (this requires more specific handling based on your HTML structure)
            const newText = parentElement.textContent;
            const newTextNode = document.createTextNode(newText);
            parentElement.parentNode.replaceChild(newTextNode, parentElement);
        } else {
            // If not formatted, add the tags
            const span = document.createElement('span');
            span.innerHTML = `<${tag}>${selectedText}</${tag}>`;
            range.deleteContents();
            range.insertNode(span);
        }

        updateHtmlContent(div.innerHTML);
    };
    
    console.log('Log ::: htmlContent ===', htmlContent)

    return (
        <div>
            <div>
                {editOptions.map(({ Icon, label, style }) => (
                    <IconButton
                        key={label}
                        aria-label={label}
                        size="small"
                        onClick={() => toggleHtmlTag(style)}
                    >
                        <Icon fontSize='small' />
                    </IconButton>
                ))}
            </div>
            <div
                contentEditable
                dangerouslySetInnerHTML={{ __html: htmlContent }}
                onInput={(e) => updateHtmlContent(e.currentTarget.innerHTML)}
                style={{ border: '1px solid black', minHeight: '200px', padding: '5px', direction: 'ltr', textAlign: 'left' }}
            />
        </div>
    );
}

export default HtmlEditor;