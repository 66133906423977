import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    Collapse,
    TableBody,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Paper,
    Box,
    TextField,
    InputAdornment,
    Switch,
    Button,
    Tooltip,
} from '@material-ui/core';
import { Edit } from '@material-ui/icons';

import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import SearchTwoToneIcon from "@material-ui/icons/SearchTwoTone";
import usePagination from '../../hooks/usePagination';
import Pagination from '../Pagination/Pagination';
import EditNoteModal from '../EditNoteModal';

import { useNotification } from '../../hooks/useNotification';
import SelectionTable from '../SelectionTable';
import {
    selectCompaniesByInvestor,
    selectCompaniesByInvestorColumns,
    selectInvestorDetail,
    selectInvestorDetailColumns,
    selectCompaniesColumnWidths,
    selectCompaniesInvestorColumnWidths,
} from '../../store/sourcing/sourcingSlice';
import { getCompaniesByInvestor, getCompaniesByInvestorColumnWidths, getCompaniesByInvestorDetail, getCompaniesColumnWidths } from '../../store/sourcing/sourcingThunks';
import InvestorTableActions from '../InvestorTableActions';
import { useLocation } from 'react-router-dom';
import { DEFAULT_COMPANIES_BY_INVESTORS_COLUMNS, DEFAULT_COMPANIES_BY_INVESTORS_DETAIL_COLUMNS } from '../../constants';
import TSkeleton from '../TSkeleton';
import { useOwners } from '../../hooks/useOwners';
import { formatEmptyCell } from "../../utils/utils";
import AddIcon from '@material-ui/icons/Add';
import OutlookModal from '../../components/OutlookModal';
import { getComparator, stableSort, formatColumnWidths, formatColumnFormats, renderTableCellContent, findValueByKey, FORMATTING_TYPES, checkFormat, formatColumnDefaultValues } from '../../utils/formatting';
import UniversalSearch from '../UniversalSearch';
import { usePriority } from '../../hooks/usePriority';
import { useStatuses } from '../../hooks/useStatus';
import { useRelationships } from '../../hooks/useRelationships';
import { syncWithCompany } from '../../store/relationships/relationshipsThunks';
import Snackbar from '@material-ui/core/Snackbar';
import OwnersSearch from '../../pages/Sourcing/Flags/OwnersSearch';

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
        '& td': {
            padding: '8px',
        },
        '& th': {
            padding: '8px',
        },
    },
    tableCell: {
        border: '1px solid rgba(224, 224, 224, 1)',
        padding: '1rem',

        '&:last-child': {
            padding: '1rem !important',
        },
        position: 'relative !important',
        '&:hover $cellEditBtn': {
            visibility: 'visible',
        },
    },
    searchHighlight: {
        backgroundColor: 'rgba(110, 60, 250)',
        color: 'white',
    },
    highlighted: {
        background: '#EDECEC',
        marginRight: "5px"
    },
    tagContainer: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    technologyTag: {
        margin: '5px',
        backgroundColor: '#EDECEC',
    },
    cellEditBtn: {
        position: 'absolute',
        top: '0',
        right: '0',
        visibility: 'hidden'
    },
    editBtn: {
        width: '0.7em',
        height: '0.7em',
        color: 'grey'
    },
    addRecentOutlook: {
        position: 'absolute !important',
        right: '0',
        bottom: '0',
    },
    addRecentOutlookButton: {
        height: '15px !important',
        width: '15px !important',
    }
});

const useCellStyles = makeStyles({
    root: {
        '& th': {
            padding: '8px',
            background: '#6e3cfa',
            color: 'white'
        },
    },
    pagination: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    paginationContainer: {
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center'
    },
    currentPageInput: {
        border: '#6e3cfa 1px solid',
        color: '#6e3cfa'
    },
    headerCell: {
        height: '20px',
        lineHeight: '10px',
        color: '#fff !important',
        borderRight: '1px solid #fff',
        padding: '1rem',
        whiteSpace: 'nowrap',
        fontWeight: 'normal !important',
        '&:last-child': {
            padding: '1rem !important',
        },
        '& span:hover': {
            color: '#fff',
            cursor: 'default',
        },
        '& span:focus': {
            color: '#fff',
        }
    },
    emptyData: {
        textAlign: 'center'
    }
});

const FIXED_COLUMNS = {
    CINCHY_ID : 'cinchy id',
    INVESTOR_ID : 'investor id',
    INVESTOR_NAME : 'investor name'
}

const FIXED_DETAIL_COLUMNS = {
    CINCHY_ID : 'cinchy id',
    COMPANY_NAME : 'company name',
    COMPANY_NAME2: 'company name2'
}

const TABS = {
    'all': 'BY_INVESTOR',
    'sweetSpot': 'BY_INVESTOR_SWEET_SPOT',
}

const TABS_DETAILS = {
    'BY_INVESTOR': 'BY_INVESTOR_DETAIL',
    'BY_INVESTOR_SWEET_SPOT': 'BY_INVESTOR_DETAIL_SWEET_SPOT',
}

const rowsPerPageOptions = [25, 50, 100]

function Row(props) {
    const { row, combineSelectedRows, index, selectedRows, selectedInvestor, openCloseDetails, detailRows, detailCells, columnFormatValues,columnWidthValues, investorColumnFormatValues, refreshInvestorsAPI } = props;

    const [open, setOpen] = useState(false);
    const classes = useRowStyles();
    const [isOutlookModalOpen, setIsOutlookModalOpen] = useState(false);
    const [currentCompanyId, setCurrentCompanyId] = useState("");
    const [selectedIds, setSelected] = useState([]);
    const [isEditNotesModalOpen, setIsEditNotesModalOpen] = useState(false);
    const [editCompanyId, setEditCompanyId] = useState(null);
    const [editedNoteName, setEditedNoteName] = useState(null);

    const handleSelectedIdsChange = useCallback(
        (values) => {
            setSelected(values)
            combineSelectedRows(values, index)
        },
        [combineSelectedRows]
    );

    const editNotes = (companyId, companyName) => {
        setEditCompanyId(companyId)
        setEditedNoteName(companyName)
        setIsEditNotesModalOpen(true)
    };

    const handleOpenDetails = () => {
        openCloseDetails(row["cinchy id"], open)
        setOpen(!open)
    }

    useEffect(() => {
        if(selectedRows[index]) {
            setSelected(selectedRows[index].map(s=> s["cinchy id"]))
        }
    }, [selectedRows])

    const handleAddRecentOutlook = useCallback((companyId) => {
        setIsOutlookModalOpen(true);
        setCurrentCompanyId(companyId);
    }, []);

    return (
        <>
            <TableRow className={classes.root}>
                <TableCell className={classes.tableCell}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        // Implement this part when companies API will be ready
                        onClick={handleOpenDetails}
                        >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                {Object.keys(row).slice(1).map(r=> {
                     if(r!=='id') { return <TableCell className={`${classes.tableCell} font-14`} align="left">{checkFormat(
                        r,
                        investorColumnFormatValues,
                        FORMATTING_TYPES.INVESTOROUTLOOKSELECT
                      ) ? <>{formatEmptyCell(row[r])}
                      <Button
                          size="small"
                          className={`${classes.addRecentOutlook} btn-neutral-primary mb-2 mr-1`}
                      >
                          <AddIcon
                              onClick={() =>
                                  handleAddRecentOutlook(
                                      row["cinchy id"]
                                  )
                              }
                              className={
                                  classes.addRecentOutlookButton
                              }
                          ></AddIcon>
                      </Button> </> : renderTableCellContent(row, r, investorColumnFormatValues, classes)}
                     {r === "notes" && renderTableCellContent(row, r, investorColumnFormatValues, classes) && (
                        <Tooltip title="Edit Notes" arrow placement="top">
                            <Button
                                className={classes.cellEditBtn}
                                onClick={() => editNotes(row['cinchy id'], row['notes'])}
                            >
                                <Edit className={classes.editBtn}/>
                            </Button>
                        </Tooltip>
                     )}</TableCell>}
                })}
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            {Object.keys(selectedInvestor).length !== 0 ?
                                <SelectionTable columnWidthValues={columnWidthValues} columnFormatValues={columnFormatValues} headCells={detailCells} rows={detailRows} selectedIds={selectedIds} updateSelectedIds={handleSelectedIdsChange}/> :
                                <TSkeleton></TSkeleton>
                            }
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
            {isEditNotesModalOpen && <EditNoteModal
                isOpen={isEditNotesModalOpen}
                setIsOpen={setIsEditNotesModalOpen}
                companyId={editCompanyId}
                refreshApi={refreshInvestorsAPI}
                companyNameValue={editedNoteName}
            />}
            <OutlookModal
                isOpen={isOutlookModalOpen}
                companyId={currentCompanyId}
                selectedOutlook={null}
                setIsOpen={setIsOutlookModalOpen}
                handleRefresh={refreshInvestorsAPI}
                investorOutlook={true}
            />
        </>
    );
}

export default function TCompaniesByInvestor() {
    const dispatch = useDispatch();
    const classes = useCellStyles();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selectedRows, setSelectedRows] = useState([]);
    const [csvRows, setCsvRows] = useState([]);
    const [csvCells, setCsvCells] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [filteredCells, setFilteredCells] = useState([]);
    const [filteredDetailRows, setFilteredDetailRows] = useState([]);
    const [filteredDetailCells, setFilteredDetailCells] = useState([]);
    const [investorDetail, setInvestorDetail] = useState({});
    const [searchedValue, setSearchedValue] = useState('');
    const [searchedRows, setSearchedRows] = useState([]);
    const [rows, setRows] = useState([]);
    const [cells, setCells] = useState([]);
    const [isCompanySynced, setIsCompanySynced] = useState(false);
    const [companiesColumnWidthValues, setCompaniesColumnWidthValues] = useState(null);
    const [companiesColumnFormatValues, setCompaniesColumnFormatValues] = useState(null);
    const [investorColumnWidthValues, setInvestorColumnWidthValues] = useState(null);
    const [investorColumnFormatValues, setInvestorColumnFormatValues] = useState(null);
    const [defaultColumnValues, setDefaultColumnValues] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [activeTab, setActiveTab] = useState('');
    const [selectedColumns, setSelectedColumns] = useState(
        JSON.parse(localStorage.getItem('selectedCompanyByInvestorColumns')
        ))
    const [selectedDetailColumns, setSelectedDetailColumns] = useState(
        JSON.parse(localStorage.getItem('selectedCompanyByInvestorDetailColumns')
        ))

    const location = useLocation();

    const refreshInvestorsAPI = useCallback(() => {
        dispatch(getCompaniesByInvestor(DEFAULT_COMPANIES_BY_INVESTORS_COLUMNS, activeTab));
        setRows([])
      }, [dispatch]);

    let columnWidths = useSelector(selectCompaniesColumnWidths);
    let investorColumnWidths = useSelector(selectCompaniesInvestorColumnWidths);

    console.log('Log ::: investorColumnWidths ===', investorColumnWidths);

    const  { notificationData, openNotification } = useNotification();
    const {  open, message, toastrStyle, vertical, horizontal } = notificationData;

    useOwners();
    useStatuses();
    usePriority();
    useRelationships();

    useEffect(() => {
        dispatch(getCompaniesColumnWidths());
        dispatch(getCompaniesByInvestorColumnWidths());
    }, []);

    useEffect(() => {
        if(columnWidths?.rows?.length) {
            setCompaniesColumnWidthValues(formatColumnWidths(columnWidths.rows))
            setCompaniesColumnFormatValues(formatColumnFormats(columnWidths.rows))
        }
    }, [columnWidths]);

    useEffect(() => {
        const activeTabs = Object.keys(TABS);
        const selectedTab = activeTabs.find(tab => location.pathname.includes(tab));
        if(selectedTab) {
            setActiveTab(TABS[selectedTab]);
        } else {
            setActiveTab(TABS['all']);
        }
    }, [location.pathname])

    useEffect(() => {
        if(activeTab) {
            dispatch(getCompaniesByInvestor({activeTab: activeTab, columns: DEFAULT_COMPANIES_BY_INVESTORS_COLUMNS}));
        }
    }, [activeTab, dispatch]);

    useEffect(() => {
        const columnValues = defaultColumnValues || [];
        setSelectedColumns(JSON.parse(localStorage.getItem("selectedCompanyByInvestorColumns")
                ) || [FIXED_COLUMNS.CINCHY_ID, ...columnValues])
    }, [defaultColumnValues]);

    useEffect(() => {
        if(investorColumnWidths?.rows?.length) {
            setInvestorColumnWidthValues(formatColumnWidths(investorColumnWidths.rows))
            setInvestorColumnFormatValues(formatColumnFormats(investorColumnWidths.rows))
            setDefaultColumnValues(formatColumnDefaultValues(investorColumnWidths.rows))
        }
    }, [investorColumnWidths]);

    const data = useSelector(selectCompaniesByInvestor);
    const tableColumns = useSelector(selectCompaniesByInvestorColumns);
    const investorDetailData = useSelector(selectInvestorDetail);
    const investorDetailColumns = useSelector(selectInvestorDetailColumns);

    useEffect(() => {
        setRows(data?.rows)
        setCells(data?.cells)
    }, [data]);

    useEffect(() => {
        setInvestorDetail(investorDetailData)
    }, [investorDetailData]);

    useEffect(() => {
        if(cells?.length && selectedColumns === null) {
            setSelectedColumns(cells.map(c=>c["label"].toLocaleLowerCase()))
        }
    }, [cells]);

    useEffect(() => {
        if(investorDetailColumns.length && selectedDetailColumns === null) {
            setSelectedDetailColumns([FIXED_DETAIL_COLUMNS.CINCHY_ID, ...investorDetailColumns.map(c=>c["Column Name"].toLocaleLowerCase())])
        }
    }, [investorDetailColumns, selectedDetailColumns]);

    useEffect(() => {
        if(Object.keys(investorDetail).length !== 0) {
            setCsvCells([{label : "Investor"}, ...investorDetail["cells"]])
        }
    }, [investorDetail]);

  const handleFilterColumns = (columns) => {
    const selectedColumns = [FIXED_COLUMNS.CINCHY_ID, FIXED_COLUMNS.INVESTOR_NAME, ...columns];
    setSelectedColumns(selectedColumns);
    localStorage.setItem('selectedCompanyByInvestorColumns', JSON.stringify(selectedColumns));
};

const handleFilterDetailColumns = (columns) => {
    const selectedDetailColumns = [FIXED_DETAIL_COLUMNS.CINCHY_ID, FIXED_DETAIL_COLUMNS.COMPANY_NAME, FIXED_DETAIL_COLUMNS.COMPANY_NAME2,  ...columns];
    setSelectedDetailColumns(selectedDetailColumns);
    localStorage.setItem('selectedCompanyByInvestorDetailColumns', JSON.stringify(selectedDetailColumns));
};

    useEffect(() => {
        const filtered = investorDetailData.rows?.map((row) => {
        let filteredRow = {};
        Object.entries(row).forEach(([key, value]) => {
            if (selectedDetailColumns?.includes(key.toLowerCase())) {
              filteredRow[key] = value;
            }
        });
            return ({...filteredRow, 'cinchy id': row['cinchy id']});
        });
        setFilteredDetailRows(filtered);
    }, [selectedDetailColumns, investorDetailData?.rows]);
    useEffect(() => {
        if(selectedDetailColumns?.length) {
            const lowerCaseSelectedColumns = selectedDetailColumns?.map(col => col.toLowerCase());

            setFilteredDetailCells(
                investorDetailData.cells?.filter(cell =>
                    Object.values(cell).some(value =>
                        lowerCaseSelectedColumns.includes(value.toString().toLowerCase())
                    )
                )
            );
        }
    }, [investorDetailData?.cells, selectedDetailColumns]);

    useEffect(() => {
        if(selectedColumns?.length) {
                const lowerCaseSelectedColumns = selectedColumns?.map(col => col.toLowerCase());
                const filtered = rows?.map((row) => {
                let filteredRow = {};
                Object.entries(row).forEach(([key, value]) => {
                    if (lowerCaseSelectedColumns.includes(key.toLowerCase())) {
                      filteredRow[key] = value;
                    }
                });
                return filteredRow;
                });
                setFilteredRows(filtered);
        }
    }, [selectedColumns, rows]);


    useEffect(() => {
        if(selectedColumns?.length) {
            const lowerCaseSelectedColumns = selectedColumns.map(column => column.toLowerCase());

            setFilteredCells(
                cells?.filter(cell =>
                    Object.values(cell).some(value =>
                        lowerCaseSelectedColumns.includes(value.toString().toLowerCase())
                    )
                )
            );
        }
    }, [cells, selectedColumns]);

    useEffect(() => {
        setCsvRows(() => {
            let companyRows = []
            Object.keys(selectedRows).forEach(key=> {

                selectedRows[key].forEach(a=>{
                    companyRows.push(a)
                })
            })
            return companyRows
        });

    }, [selectedRows]);

  const handleInputChange = (event) => {
      if(!isNaN(event.target.value)){
        goToPage(parseInt(event.target.value))
      }
  };

  const handleCombineSelectedRows = useCallback(
    (values, index) => {
        setSelectedRows((previous) => {
            const selectedCompanies = investorDetail.rows.filter(c=> {
                return values.includes(c["cinchy id"])
            })
            const investorCompanies = selectedCompanies.map(c=> {
                return {...c, ...{"investor": rows[index]["investor name"]}}
            })
            previous[index] = investorCompanies;
            return { ...previous };
          });
    },
    [rows, investorDetail]
);

const openCloseDetails = useCallback(
    (id, isOpen) => {
        if(!isOpen) {
            dispatch(getCompaniesByInvestorDetail({investorId: id, columns: DEFAULT_COMPANIES_BY_INVESTORS_DETAIL_COLUMNS, activeTab: TABS_DETAILS[activeTab]}));
        } else {
            setInvestorDetail({})
        }
    },
    [dispatch, selectedDetailColumns, activeTab]
);

const handleSyncWithCompanies = useCallback(
    async () => {
        await dispatch(syncWithCompany())
        openNotification({
            message: "Companies synced successfully",
            toastrStyle: "toastr-primary",
            vertical: "top",
            horizontal: "right",
        })
        setIsCompanySynced(true)
    },
    [dispatch]
);

const filteredOverdueRows = useMemo(() => {
    let currentRows = filteredRows
    if (searchedValue) {
        currentRows = filteredRows.filter(row => {
            return !!(row['investor name'] && row['investor name'].toLowerCase().includes(searchedValue.toLowerCase()));
        })
    }

    if (selectedOptions.length === 0) {
      return currentRows;
    }
    return currentRows.filter((item) => {
      const owners = item.owners?.split(';')
      const ownerIncluded = owners?.some(owner => selectedOptions.includes(owner))
      const ownerMatches = selectedOptions.length > 0 ? ownerIncluded : true;
      return ownerMatches;
    });
  }, [selectedOptions, searchedValue, filteredRows]);

  const { currentPage, rowsPerPage, goToPageValue, goToPage, totalPages, handleRowsPerPageChange, handleGoToPageChange} = usePagination({ rows: filteredOverdueRows, rowsPerPageValue : rowsPerPageOptions[0] });


  console.log('Log ::: filteredOverdueRows ===', filteredOverdueRows, tableColumns)
  
    useEffect(() => {
        if (searchedValue) {
            const searchedData = filteredRows.filter(row => {
                return !!(row['investor name'] && row['investor name'].toLowerCase().includes(searchedValue.toLowerCase()));

            })
            setSearchedRows(searchedData);
        } else {
            setSearchedRows([]);
        }
    }, [searchedValue]);

    const handleSearch = (e) => {
        setSearchedValue(e.target.value);
    };

    return (
        <>
            {data.isLoading ?  <TSkeleton/> : 
            <>
            <div className="d-flex justify-content-end">
              <OwnersSearch setSelectedValues={setSelectedOptions} selectedValues={selectedOptions}  width={600} />
            </div>
            <div className="search-wrapper w-100">
                <TextField
                    variant="outlined"
                    size="small"
                    className="bg-white w-100"
                    classes={{ root: 'input-border-0' }}
                    id="search-tag"
                    placeholder="Search investors..."
                    onChange={handleSearch}
                    InputProps={{
                        style: {
                            fontFamily: 'Quicksand, sans-serif',
                            fontSize: '14px',
                        },
                        disableUnderline: true,
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchTwoToneIcon />
                            </InputAdornment>
                        )
                    }}
                />
            </div>

            <div class="divider mt-2 mb-2"></div>

            <InvestorTableActions
                rows={csvRows}
                cells={csvCells}
                columns={tableColumns}
                selectedColumns={selectedColumns}
                handleSelectColumns={handleFilterColumns}
                detailColumns={Object.keys(investorDetail).length !== 0 ? investorDetailColumns : null}
                selectedDetailColumns={selectedDetailColumns}
                handleDetailSelectColumns={handleFilterDetailColumns}
            />
            <div className="mb-3">
                <Switch
                    checked={isCompanySynced}
                    onClick={handleSyncWithCompanies}
                    disabled={isCompanySynced}                            
                    className="switch-small toggle-switch-primary ml-auto"
                />
                <label className="ml-1">Sync Companies</label>
            </div>
            <div className="mb-spacing-6-x2 ">
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead stickyHeader
                            className={classes.root}
                            aria-labelledby="tableTitle"
                            size='medium'
                            aria-label="enhanced table">
                            <TableRow>
                                <TableCell className={classes.headerCell}/>
                                {filteredCells.slice(1).map(item => <TableCell style={{minWidth: findValueByKey(investorColumnWidthValues, item.label)}} className={`${classes.headerCell} font-14`} key={item.label} component="th" align="left">{item.label}</TableCell>)}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {stableSort(filteredOverdueRows, getComparator(order, orderBy)).slice((currentPage-1) * rowsPerPage, (currentPage-1) * rowsPerPage+rowsPerPage).map((row, index) =>
                                <Row key={row.investor} row={row} combineSelectedRows={handleCombineSelectedRows} index={(currentPage-1)* rowsPerPage + index}
                                 selectedRows={selectedRows} selectedInvestor={investorDetail} openCloseDetails={openCloseDetails}
                                 detailRows={filteredDetailRows} detailCells={filteredDetailCells} columnFormatValues={companiesColumnFormatValues}
                                 columnWidthValues={companiesColumnWidthValues} investorColumnFormatValues={investorColumnFormatValues} refreshInvestorsAPI={refreshInvestorsAPI}/>
                            )}
                        </TableBody>
                    </Table>
                    {(filteredOverdueRows.length === 0) && <div className={classes.emptyData}><p>No data matching selected filters</p></div>}
                </TableContainer>
                <Pagination goToPage={goToPage} rowsPerPage={rowsPerPage} currentPage={currentPage} goToPageValue={goToPageValue} totalPages={totalPages}
                     handleRowsPerPageChange={handleRowsPerPageChange} handleGoToPageChange={handleGoToPageChange} options={rowsPerPageOptions}
                     hasArrows={true} hasGoToPage={true}/>
            </div>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                classes={{ root: toastrStyle }}
                open={open}
                message={message}
            />
            </>
        }
        </>
    );
}