import React, { useMemo, useState, useEffect } from 'react';
import { FieldHookConfig, useField } from 'formik';
import Select, { components } from 'react-select';
import { useSelector } from "react-redux";
import { selectTagOptions } from "../../store/sourcing/sourcingSlice";

export type IOption = { label: string; value: string | number };
export interface AbstractCheckboxProps<T> {
    prefixCls?: string;
    className?: string;
    defaultChecked?: boolean;
    checked?: boolean;
    style?: React.CSSProperties;
    disabled?: boolean;
    onChange?: (e: T) => void;
    onClick?: React.MouseEventHandler<HTMLElement>;
    onMouseEnter?: React.MouseEventHandler<HTMLElement>;
    onMouseLeave?: React.MouseEventHandler<HTMLElement>;
    onKeyPress?: React.KeyboardEventHandler<HTMLElement>;
    onKeyDown?: React.KeyboardEventHandler<HTMLElement>;
    value?: any;
    tabIndex?: number;
    name?: string;
    children?: React.ReactNode;
    id?: string;
    autoFocus?: boolean;
    type?: string;
    skipGroup?: boolean;
}
export interface CheckboxProps extends AbstractCheckboxProps<CheckboxChangeEvent> {
    indeterminate?: boolean;
}
export interface CheckboxChangeEventTarget extends CheckboxProps {
    checked: boolean;
}
export interface CheckboxChangeEvent {
    target: CheckboxChangeEventTarget;
    stopPropagation: () => void;
    preventDefault: () => void;
    nativeEvent: MouseEvent;
}

export interface IMultiselect {
    isSelected?: boolean;
    options: Record<string, IOption>;
    name: string;
    menuPlacement?: string;
    onChange(value: IOption['value'][] | null): void;
    onBlur?(): void;
    label?: string;
    value?: IOption['value'][];
    defaultValue?: IOption;
    required?: boolean;
}

// @TODO: replace any
const Control: React.FC<any> = ({ children, ...props }) => {
    const { onSelectAll, isAllSelected } = props.selectProps;

    const handleChange = (e: any) => {
        if (onSelectAll) onSelectAll(e.target.checked);
    };

    return (
        <components.Control {...props}>
            <input
                onChange={handleChange}
                checked={isAllSelected}
                style={{ marginLeft: '8px' }}
                type='checkbox'
            />
            {children}
        </components.Control>
    );
};

export const Multiselect: React.FC<IMultiselect> = ({
    isSelected,
    options = {},
    name,
    onChange,
    label,
    value,
    required = false,
    ...props
                                                    }) => {
    const defaultValue = useMemo(() => {
        if (!value) return [];
        return value.map((val) => options[val]);
    }, [value, options]);

    const labelElement = label && (
        <span
            style={{
                marginBottom: 4,
            }}
        >
            {label}
        </span>
    );
    const requiredElement = required && (
    <span
        style={{
            marginBottom: 4,
            marginLeft: 3,
            color: defaultValue.length ? '#646464' : 'red',
        }}
    >
            *
    </span>
    );

    return (
        <div style={{ margin: '5px 0' }}>
            <div className='d-flex'>
                {labelElement}
                {requiredElement}
            </div>
            <Select
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 4,
                    colors: {
                        ...theme.colors,
                        primary25: isSelected ? 'rgba(110, 60, 250, 0.2)' : 'rgba(110, 60, 250, 0.05)',
                        primary: '#6e3cfa',
                    },
                })}
                styles={{
                    input: (providedValue) => ({
                        ...providedValue,
                    }),
                    placeholder: (providedValue) => ({
                        ...providedValue,
                        margin: '0 4px',
                    }),
                }}
                name={name}
                options={Object.values(options)}
                isMulti
                isSearchable
                closeMenuOnSelect={false}
                value={defaultValue}
                onChange={(selected) => {
                    const selectedValues = (selected || []).map(({ value }) => value);
                    onChange(selectedValues);
                }}
                // @ts-ignore
                isAllSelected={defaultValue.length === Object.values(options).length}
                onSelectAll={(select: boolean) => {
                    if (select) {
                        const allValues = Object.values(options).map(({ value }) => value);
                        onChange(allValues);
                    } else {
                        onChange([]);
                    }
                }}
                components={{ Control }}
                {...props}
            />
        </div>
    );
};

export const MultiselectFormik: React.FC<
    IMultiselect & FieldHookConfig<string>
> = ({ onChange, name, options, menuPlacement, ...props }) => {
    const [tagNames, setTagNames] = useState([]);
    const [tagSelectOptions, setTagSelectOptions] = useState([] as any);
    const tagsData = useSelector(selectTagOptions);
    // @TODO: figure out type
    // @ts-ignore
    const [field, , helpers] = useField(props.field);
    useEffect(() => {
        setTagSelectOptions(
          tagNames
            ?.slice()
            .reverse()
            .map((option: any) => ({
              value: option?.trim(),
              label: option?.trim(),
            }))
        );
      }, [tagNames]);

      useEffect(() => {
        setTagNames(tagsData?.map((o: any) => o["Tag"]));
      }, [tagsData]);
    return (
        <Multiselect
            {...props}
            name={name}
            value={field.value}
            options={field.name === 'Tags' ? tagSelectOptions : options}
            onChange={(selectedValues) => {
                helpers.setValue(selectedValues);
            }}
            menuPlacement={menuPlacement ? menuPlacement : 'bottom'}
            onBlur={() => {
                helpers.setTouched(true);
                props.onBlur && props.onBlur();
            }}
        />
    );
};
