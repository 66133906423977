import discover from "../../assets/images/discover.png";
import track from "../../assets/images/track.png";
import diligence from "../../assets/images/diligence.png";

export const homePageData = {
    title: "Intelligence Platform",
    description: "Leverage AI to optimize the process of discovering, tracking, and performing diligence on companies in our investible universe",
    sections: [
        {
            name: "Discovery",
            image: discover,
            button: 'Companies',
            description: "Cut through the noise",
            absolute: false,
            path: '/sourcing/companies/company',
            features: [
                { name: "Company Search", description: "Search for a specific company", path: '/sourcing/hygiene' },
                { name: "Recent Financings", description: "View relevant recent financings", path: '/sourcing/financings/all' },
                { name: "Categories", description: "Prioritize and tag categories in FPC taxonomy", path: '/sourcing/categories' }
            ]
        },
        {
            name: "Tracking",
            image: track,
            button: 'Dashboard',
            description: "Nothing falls through the cracks",
            absolute: false,
            path: '/dashboard',
            features: [
                { name: "Pipeline Hygiene", description: "Update stale company relationships", path: '/sourcing/hygiene' },
                { name: "Opportunities", description: "Track active investment opportunities", path: '/sourcing/opportunities' },
                { name: "Investors", description: "Track target investors", path: 'relationships/investors/all'  },
            ]
        },
        {
            name: "Diligence",
            image: diligence,
            button: 'Forgepoint Gpt',
            description: "AI-assisted diligence",
            absolute: true,
            path: 'https://forgepoint.photoninsights.com/',
            features: [
                { name: "DD Button", description: "AI-generated investment memos", absolute: true, path: 'https://forgepoint.photoninsights.com/diligence-suite' },
                { name: "Meeting Notes", description: "AI-generated company notes", path: '/sourcing/ai-notes' },
                { name: "People", description: "Track relationships and key entrepreneurs", path: '/relationships/people' }
            ]
        }
    ]
};