import React, { useState } from "react";
import { PRIMARY_COMPETITORS_DISPLAY_COUNT } from "../../constants";
import {Button, Typography, makeStyles, IconButton} from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";

import styles from './styles.module.scss';
import PrimaryCompetitorsModal from "../PrimaryCompetitorsModal";
import EditIcon from "../EditIcon/EditIcon";
import DeleteIcon from "@material-ui/icons/Delete";

type PrimaryCompetitorsProps = {
    primaryCompetitors: any,
    companyId: string | number
}

const useStyles = makeStyles({
    collapseBtn: {
        backgroundColor: '#6e3cfa',
        color: '#ffffff',
        marginTop: '0.5rem',

        '&:hover': {
            backgroundColor: '#6e3cfa',
            color: '#ffffff',
        }
    }
})

const PrimaryCompetitors: React.FC<PrimaryCompetitorsProps> = ({
    primaryCompetitors,
    companyId
}) => {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
    const [isPrimaryCompModalOpen, setIsPrimaryCompModalOpen] = useState(false);
    const classes = useStyles();

    const openPrimaryCompetitorsModal = () => {
        setIsPrimaryCompModalOpen(true);
    };

    // Determine how many items to display based on the isCollapsed state
    const displayedCompetitors = isCollapsed
        ? primaryCompetitors.slice(0, PRIMARY_COMPETITORS_DISPLAY_COUNT)
        : primaryCompetitors;

    const handleOnClickCompetitor = (companyId: any) => {
        window.open(`/sourcing/companies/details/${companyId}`, "_blank")
    };

    return (
        <div>
            <div className={styles.editable}>
                <Typography className='mr-2 font-14 font-weight-bold'>Primary Competitors</Typography>
                <div>
                    <IconButton
                        aria-label="eidt"
                        size="small"
                        onClick={openPrimaryCompetitorsModal}
                    >
                        <EditIcon fontSize='small' />
                    </IconButton>
                    <Button
                        id="blockElement"
                        onClick={() => setIsCollapsed(!isCollapsed)}
                    >
                        {isCollapsed ? (
                            <><span className={`${styles.collapseBtn} font-12`}>Show All</span><ArrowDropDown /></>
                        ) : (
                            <><span className={`${styles.collapseBtn} font-12`}>Collapse</span><ArrowDropUp /></>
                        )}
                    </Button>
                </div>
            </div>

            <div className={styles.summaryContainer}>
                {displayedCompetitors.map((competitor: any, index: number) => {
                    return <p onClick={() => handleOnClickCompetitor(competitor['Company Cinchy Id'])} 
                    className={`${styles.competitors} cursor-pointer font-12`} key={index}>{competitor['Primary Competitors']}</p>
                })}
            </div>
            <PrimaryCompetitorsModal
                isOpen={isPrimaryCompModalOpen}
                companyId={companyId}
                primaryCompetitors={primaryCompetitors.length ? primaryCompetitors : []}
                setIsOpen={setIsPrimaryCompModalOpen}
            />
        </div>
    )
}

export default PrimaryCompetitors;