import React, { useEffect, useRef, useState } from 'react';
import { Grid, Button, Paper } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import CompanySearch from "../../components/UniversalSearch/CompanySearch";
import { homePageData } from './data';
import styles from './styles.module.scss';



export default function Home() {
  const sourcingRef = useRef(null);
  const [isSourcingRefReady, setSourcingRefReady] = useState(false);

  useEffect(() => {
    if (sourcingRef.current) {
      setSourcingRefReady(true);
    }
  }, [sourcingRef.current]);

  return (
    <>
      <div className={styles.pageContainer}>
        <div className={styles.cardsContainer}>
          <div className={styles.infoContainer}>
            <p className={styles.pageTitle}>{homePageData.title}</p>
            <div className='font-14'>{homePageData.description}</div>
          </div>
          <Grid container spacing={3}>
            {homePageData.sections.map((section, idx) => (
                <Grid item key={idx} xs={12} sm={6} md={4} lg={4} className='p-0'>
                  <Paper elevation={0} >
                    <div className={styles.cardTop}>
                      <div className='p-3'>
                        <div className={`${styles.sectionDescription}`}>{section.name}</div>
                        <div className={`${styles.sectionTitle} font-12`}>{section.description}</div>
                        {section.absolute ? <Button variant="contained" className={`p-2 mt-2 mb-2 btn-square btn-second font-12 font-weight-bold ${styles.fixedWidthButton}`}>
                          <a target='blank' className={styles.linkButton} href={section.path}>{section.button}</a>
                        </Button> : <NavLink to={section.path}>
                          <Button className={`p-2 mt-2 mb-2 btn-square btn-second font-12 font-weight-bold ${styles.fixedWidthButton}`} >
                            {section.button}
                          </Button>
                        </NavLink>}
                        <img src={section.image} alt={section.name} className={styles.sectionImage}/>
                      </div>
                    </div>
                    <div className={styles.featureContainer}>
                      {section.features.map((feature, featureIdx) => (
                          <div key={featureIdx} className={styles.feature}>
                            <div className={`p-3 ${styles.featureWrapper}`}>
                              <div className={`${styles.featureName}`}>{feature.name}</div>
                              <div className={`font-12`}>{feature.description}</div>
                              {feature.name === 'Company Search' ? <div ref={sourcingRef}>
                                {isSourcingRefReady && <CompanySearch top='40px' right='unset' parentElement={sourcingRef.current} />}
                              </div> : !feature.absolute ? <NavLink to={feature.path}>
                                    <Button className={`mt-2 btn-square btn-second font-12 font-weight-bold ${styles.fixedWidthButton}`}>
                                      {feature.name}
                                    </Button>
                                  </NavLink>
                                  :
                                  <Button variant="contained" className={`mt-2 btn-square btn-second font-12 font-weight-bold ${styles.fixedWidthButton}`}>
                                    <a target='blank' className={styles.linkButton} href={feature.path}>{feature.name}</a>
                                  </Button>}
                            </div>
                          </div>
                      ))}
                    </div>
                  </Paper>
                </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </>
  );
}
