// @ts-nocheck
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import IntelligenceModal from '../../../components/IntelligenceModal';
import {
  selectFlags,
  selectFlagsColumnWidths,
} from "../../../store/sourcing/sourcingSlice";
import {
  getFlags,
  getFlagsColumnWidths,
} from "../../../store/sourcing/sourcingThunks";
import {
  DEFAULT_FLAGS_OVERDUE_COLUMNS,
} from "../../../constants";
import usePagination from '../../../hooks/usePagination';
import Pagination from '../../../components/Pagination/Pagination';
import { formatEmptyCell } from "../../../utils/utils";
import styles from "./styles.module.scss";
import UniversalSearch from "../../../components/UniversalSearch";
import OutlookModal from "../../../components/OutlookModal";
import StatusSelect from "../../../components/StatusSelect";
import OwnersSelect from '../../../components/OwnersSelect';
import FlagDetailsSearch from './FlagDetailsSearch';
import OwnersSearch from './OwnersSearch';
import { useOwners } from '../../../hooks/useOwners';
import { formatDate, formatColumnWidths } from '../../../utils/formatting';
import GenericTable from "../../../components/GenericTable";
import TSkeleton from "../../../components/TSkeleton";
import { useStatuses } from "../../../hooks/useStatus";
import { PAGE_NAMES } from '../../../constants';
import FPCIPriorities from '../../../components/FPCIPrio';
import { useFPCIPriorities } from '../../../hooks/useFPCIPriorities';
import SearchBar from "./SearchBar";

const changeOptions = {
  null: "--",
  true: "Yes",
  false: "No",
};

function extractString(value: string) {
  // @ts-ignore
  return changeOptions[value] || value;
}

const companyId = 'Company Cinchy Id';

const rowsPerPageOptions = [15, 50, 100];

const renderCell = ({ column, row, handleAddRecentOutlook, handleCompanyWebsiteSplit, handleIntelligenceOpen, index}) => {
  const defaultValue = formatEmptyCell(extractString(row[column]));
  switch (column) {
    case "Company": return (
      <div className={styles.cell}>
        {handleCompanyWebsiteSplit(formatEmptyCell(row[column]), row[companyId])}
        <div className={styles.boxContainer}>
          <div
            style={{
              background: `#${row["Company Name2"].split("|")[1].trim()}`,
              }}
            className={styles.fpcScoreBoxContainer}
          >
            <span className={styles.fpcScore} style={{ fontSize: "12px" }}>
              {row["Company Name2"].split("|")[0]}
            </span>
          </div>
          <div
            className={styles.flagColor}
              style={{
                background: `#${row["Company Name2"].split("|")[2].trim()}`,
              }}
          ></div>
        </div>          
        <Tooltip title="Intelligence" arrow placement="top">
          <IconButton
            className={styles.cellIntelligenceBtn}
            size="small"
            aria-label="delete"
            onClick={() => handleIntelligenceOpen(row[companyId], row["Company"])}
          >
            <InfoIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </div>
    );
    
    case "Owner": return <OwnersSelect
        affinityId={
          row[companyId]
        }
        ownersInfo={row[column]?.split(';')}
    ></OwnersSelect>
    case "Status": return <StatusSelect
        name="status"
        key={row[companyId]}
        defaultValue={{ label: row[column], value: row[column] }}
        cinchyId={row[companyId]}
    />
    case "Priority": return <FPCIPriorities 
          key={row[companyId]} 
          cinchyId={row[companyId]} 
          defaultValue={{ label: row[column], value: row[column] }} />
    case "Flag Detail": return <div className='text-nowrap'>{defaultValue}</div>
    case "Recent Outlook": return <>
      {row[column]}
      <Button
          size="small"
          className={`${styles.addFPCContact} btn-neutral-primary mb-2 mr-1`}
      >
        <AddIcon
            onClick={() =>
                handleAddRecentOutlook(
                    row[companyId],
                    index
                )
            }
            className={
              styles.addFPCContactButton
            }
        ></AddIcon>
      </Button>
    </>;
    case 'Last Contact / Meeting Date':
    case 'Recent Outlook Date':
    case 'Flag Date':
      return formatEmptyCell(formatDate(row[column]))
    default: return defaultValue;
  }
}

export default function Flags() {

  const [isOutlookModalOpen, setIsOutlookModalOpen] = useState(false);
  const [currentCompanyId, setCurrentCompanyId] = useState("");

  const [selectedOptions, setSelectedOptions] = useState([] as any);
  const [selectedFlagDetails, setSelectedFlagDetails] = useState([]);
  const [columnWidthValues, setColumnWidthValues] = useState(null);
  const [isIntelligenceModalOpen, setIsIntelligenceModalOpen] = useState(false);
  const [updateIndex, setUpdateIndex] = useState(null);
  const [currentCompanyName, setCurrentCompanyName] = useState("");
  const [searchValue, setSearchValue] = useState('');
  const [column, setColumn] = useState('Company');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFlagsColumnWidths());
}, []);

  useOwners();
  useStatuses();
  useFPCIPriorities();

  const { rows: overdueRowsData, cells: overdueCellsData } = useSelector(
    selectFlags
  );

  let columnWidths = useSelector(selectFlagsColumnWidths);

  useEffect(() => {
    if(columnWidths?.rows?.length) {
        setColumnWidthValues(formatColumnWidths(columnWidths.rows))
    }
  }, [columnWidths]);

  const findValueByKey = (array, key) => {
    const obj = array?.find(item => key in item);
    return obj ? obj[key] : undefined;
  };

  const getHeadCellStyle = (cell) =>  ({
        minWidth: findValueByKey(columnWidthValues, cell),
  })

  const cellsToDisplay = overdueCellsData.filter(item => item !== companyId);

  const filteredOverdueRows = useMemo(() => {
    let filteredRows = overdueRowsData
    if(searchValue) {
      filteredRows = overdueRowsData.filter(item => item[column]?.toLowerCase()?.includes(searchValue?.toLowerCase()))
    }
    if (selectedOptions.length === 0 && selectedFlagDetails.length === 0) {
      return filteredRows;
    }
    return filteredRows.filter((item) => {
      const owners = item.Owner?.split(';')
      const ownerIncluded = owners?.some(owner => selectedOptions.includes(owner))
      const ownerMatches = selectedOptions.length > 0 ? ownerIncluded : true;
      const flagDetailMatches = selectedFlagDetails.length > 0 ? selectedFlagDetails.includes(item["Flag Detail"]) : true;
      return ownerMatches && flagDetailMatches;
    });
  }, [overdueRowsData, selectedOptions, searchValue, selectedFlagDetails]);

  const { currentPage, rowsPerPage, goToPageValue, goToPage, totalPages, handleRowsPerPageChange, handleGoToPageChange} = usePagination({ rows: filteredOverdueRows, rowsPerPageValue : rowsPerPageOptions[0] });

  useEffect(() => {
    dispatch(getFlags(DEFAULT_FLAGS_OVERDUE_COLUMNS));
  }, []);

  const refreshFlagsApi = useCallback(() => {
    dispatch(getFlags(DEFAULT_FLAGS_OVERDUE_COLUMNS));
  }, []);

  const handleAddRecentOutlook = useCallback((companyId: string, index: number) => {
    setIsOutlookModalOpen(true);
    setCurrentCompanyId(companyId);
    setUpdateIndex(index)
  }, []);

  const handleOpenCompanyDetails = (companyId: string) => {
    window.open(`/sourcing/companies/details/${companyId}`, '_blank');
  };

  const handleCompanyWebsiteSplit = useCallback((value, id) => {
    const [companyName, website] = value.split(' | ');
    return <> 
              <span className={styles.hoverBlack} onClick={() => handleOpenCompanyDetails(id)} style={{ cursor: 'pointer', color: 'black', display: 'block', fontSize: '14px', fontWeight: 'bold' }}>
                {companyName}
              </span>
              <span  style={{ cursor: 'pointer', color: 'black' }}>
                <a href={website?.startsWith('http') ? website : `http://${website}`}
                  target="_blank" className={styles.hoverBlack} style={{ color: 'grey', fontSize: '12px' }}>{website}</a>
              </span>
           </>
  }, []);

  const rowsToDraw = useMemo(() => filteredOverdueRows
      ?.slice(
          (currentPage - 1) * rowsPerPage,
          (currentPage - 1) * rowsPerPage + rowsPerPage
      ), [filteredOverdueRows, currentPage, rowsPerPage])

    const handleIntelligenceOpen = useCallback((companyId, company) => {
        setIsIntelligenceModalOpen(true);
        setCurrentCompanyId(companyId);
        setCurrentCompanyName(company);
    }, []);

  const renderTCell = (column, row, index) => {
    return renderCell({ row, column, handleAddRecentOutlook, handleCompanyWebsiteSplit, handleIntelligenceOpen, index })
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
}

  return (
    <>
      {overdueRowsData?.length > 0 ? <>
      <div className="mb-2">
      </div>
        <GenericTable
            title={`Hygiene (${filteredOverdueRows.length} results)`}
            fixedColumnHint="Flag Color"
            rows={rowsToDraw.filter(row => row !== 'Company Name2')}
            headCells={cellsToDisplay.filter(cell => cell !== 'Flag Color' && cell !== 'Company Name2')}
            tableActions={<div className="d-flex">
              <OwnersSearch setSelectedValues={setSelectedOptions} selectedValues={selectedOptions}   />
              <FlagDetailsSearch setSelectedFlagDetails={setSelectedFlagDetails} selectedFlagDetails={selectedFlagDetails} />
            </div>}
            getHeadCellStyle={getHeadCellStyle}
            getCellStyle={() => {}}
            renderCell={renderTCell}
              getActionsCell={() => <div className='d-flex w-100 justify-content-center'><div className={styles.colorBarRed}></div></div>}
            searchBar={<SearchBar columns={cellsToDisplay} handleSearch={handleSearch} column={column} setColumn={setColumn} />}
        >
          <Pagination
              goToPage={goToPage}
              totalPages={totalPages}
              rowsPerPage={rowsPerPage}
              currentPage={currentPage}
              goToPageValue={goToPageValue}
              handleRowsPerPageChange={handleRowsPerPageChange}
              handleGoToPageChange={handleGoToPageChange}
          />
        </GenericTable>
        <OutlookModal
          isOpen={isOutlookModalOpen}
          companyId={currentCompanyId}
          selectedOutlook={null}
          setIsOpen={setIsOutlookModalOpen}
          handleRefresh={refreshFlagsApi}
          index={updateIndex}
          pageName={PAGE_NAMES.FLAGS}
        />
        <IntelligenceModal
          id={currentCompanyId}
          company={currentCompanyName}
          isOpen={isIntelligenceModalOpen}
          setIsOpen={() => setIsIntelligenceModalOpen(false)}>
        </IntelligenceModal>
      </> : <TSkeleton/>}
    </>
  );
}