import React, { useState, useCallback, useEffect } from "react";
import { Typography, Button, Tooltip } from "@material-ui/core";
import { Table } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { ArrowLeft, ArrowRight } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import {
  addHeadcount,
  updateHeadcount,
  deleteHeadcount,
} from "../../store/sourcing/sourcingThunks";
import DatePicker from "../../example-components/FormsDatepicker/FormsDatepicker1";
import Snackbar from "@material-ui/core/Snackbar";
import { Done, Close, Delete } from "@material-ui/icons";
import { formatEmptyCell } from "../../utils/utils";
import styles from "./styles.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import usePagination from "../../hooks/usePagination";
import Pagination from "../Pagination/Pagination";
import EditIcon from "../EditIcon/EditIcon";
import DeleteIcon from "@material-ui/icons/Delete";

function parseQuarterDate(quarterDate: string) {
  const parts = quarterDate.split(" ");

  if (parts.length !== 2) {
    throw new Error('Invalid input format. Expected "Q YYYY"');
  }

  const quarter = parseInt(parts[0], 10);
  const year = parseInt(parts[1], 10);

  if (isNaN(quarter) || isNaN(year)) {
    throw new Error("Invalid quarter or year format");
  }

  const month = 1 + (quarter - 1) * 3;

  const date = new Date(`${year}-${month.toString().padStart(2, "0")}-1`);

  return date;
}

function parseDate(date: Date) {
  const inputDate = new Date(date);
  const month = inputDate.getMonth() + 1;
  const day = inputDate.getDate();
  const year = inputDate.getFullYear();

  return `${month}/${day}/${year}`;
}

const defaultHeaders = [
  'TableId', 'Date', 'Headcount'
]

interface HeadcountOverviewProps {
  tableData: any;
  companyId: number;
  handleRefresh: () => void;
}

const HeadcountHistory = ({
  tableData,
  companyId,
  handleRefresh,
}: HeadcountOverviewProps) => {
  const [headCountData, setHeadCountData] = useState([] as any);
  const [rowToUpdate, setRowToUpdate] = useState([] as any);
  const [headers, setHeaders] = useState([] as any);

  useEffect(() => {
    let tableHeaders = defaultHeaders
    setHeaders(defaultHeaders)
    if(tableData.length) {
      tableHeaders = Object.keys(tableData[0]);
    }
    setHeaders(tableHeaders)
    
    setHeadCountData(
      tableData.map((row: any) => {
        let finalRow = [] as any;
        tableHeaders.map((header) => finalRow.push(row[header]));

        return finalRow;
      })
    );
  }, [tableData]);

  const [insertedData, setInsertedData] = useState({} as any);
  const [isInsertOpen, setIsInsertOpen] = useState(false);
  const [updatedRowIndex, setUpdatedRowIndex] = useState(-1);
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [notificationData, setNotificationData] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    toastrStyle: "",
    message: "This is a toastr/snackbar notification!",
  });

  const { vertical, horizontal, open, toastrStyle, message } = notificationData;
  const { currentPage, rowsPerPage, goToPageValue, goToPage, totalPages, handleRowsPerPageChange, handleGoToPageChange} = usePagination({ rows: headCountData, rowsPerPageValue: 3 });

  const handleSelectDate = (date: Date) => {
    setSelectedDate(date);
  };

  const addRow = () => {
    setIsInsertOpen(true);
    let newRow = {} as any;
    headers.slice(1).forEach((header: string) => {
      newRow = { ...newRow, ...{ [header]: "" } };
    });
    setInsertedData(newRow);
  };

  const cancelInsert = () => {
    setIsInsertOpen(false);
  };

  const handleInputChange = useCallback(
    (value: string, columnName: string) => {
      setInsertedData((previous: any) => {
        previous[columnName] = value;
        return { ...previous };
      });
    },
    [setInsertedData]
  );

  const insertRow = useCallback(async () => {
    const formattedDate = parseDate(selectedDate);
    const finalData = insertedData;
    finalData["Date"] = formattedDate;
    setIsInsertOpen(false);
    await dispatch(
      addHeadcount({
        data: finalData,
        companyId: companyId.toString(),
      })
    );
    openNotification({
      message: "Data inserted successfully",
      toastrStyle: "toastr-primary",
      vertical: "top",
      horizontal: "right",
    });
    handleRefresh();
  }, [insertedData, addHeadcount, selectedDate]);

  const handleRowUpdate = useCallback(
    (index: number) => {
      setRowToUpdate([...headCountData[index]]);
      setUpdatedRowIndex(index);
      const newDate = parseQuarterDate(headCountData[index][1]);
      setSelectedDate(newDate);
    },
    [headCountData]
  );

  const handleRowDelete = useCallback(
    async (index: number) => {
      if (headCountData[index][0]) {
        await dispatch(deleteHeadcount(headCountData[index][0]));
      }

      openNotification({
        message: "Data deleted successfully",
        toastrStyle: "toastr-primary",
        vertical: "top",
        horizontal: "right",
      });
      handleRefresh();
    },
    [headCountData]
  );

  const cancelUpdate = useCallback(
    (index: number) => {
      setHeadCountData((previous: any) => {
        previous[index] = rowToUpdate;
        return [...previous];
      });
      setUpdatedRowIndex(-1);
    },
    [rowToUpdate]
  );

  const openNotification = useCallback((newState: any) => {
    setNotificationData({ open: true, ...newState });
    const timer = setTimeout(() => {
      setNotificationData({ ...notificationData, open: false });
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  const saveUpdate = useCallback(
    async (index: number) => {
      let finalData = {} as any;
      headers.map((col: string, i: number) => {
        finalData = { ...finalData, ...{ [col]: headCountData[index][i] } };
      });
      const formattedDate = parseDate(selectedDate);
      finalData["Date"] = formattedDate;

      let isDataFilled = true;

      Object.keys(finalData).forEach((d: any) => {
        if (!finalData[d]) {
          isDataFilled = false;
          return;
        }
      });

      setUpdatedRowIndex(-1);

      await dispatch(updateHeadcount(finalData));
      if (isDataFilled) {
        openNotification({
          message: "Data updated successfully",
          toastrStyle: "toastr-primary",
          vertical: "top",
          horizontal: "right",
        });
      }
      handleRefresh();
    },
    [headCountData, selectedDate]
  );

  const handleUpdateChange = useCallback(
    (value: string, rowIndex: number, colIndex: number) => {
      setHeadCountData((previous: any) => {
        previous[rowIndex][colIndex] = value;
        return [...previous];
      });
    },
    [headCountData]
  );

  const handleClose = () => {
    setNotificationData({ ...notificationData, open: false });
  };

  return (
    <>
      <div className={styles.headCountHeader}>
        <Typography className={`${styles.headcountText} font-14 font-weight-bold`}>
          Headcount history
        </Typography>
        {!isInsertOpen && (
          <div id="blockElement" className={styles.addNewEntryBtnContainer}>
            <Tooltip title="Add new entry" arrow placement="top">
              <Button
                id="blockElement"
                className="btn-primary mx-1 p-1 shadow-none border-0 d-inline-flex align-items-center justify-content-center"
                onClick={addRow}
              >
                <FontAwesomeIcon
                  icon={["fas", "plus"]}
                  className="font-size-sm"
                />
                <span className="ml-1 font-10">Add New</span>
              </Button>
            </Tooltip>
          </div>
        )}
      </div>
      <div className="table-responsive-md mb-spacing-2-x2">
        <Table
          className={`table table-hover table-striped table-bordered ${styles.table}`}
        >
          <thead className={styles.tableHead}>
            <tr className="font-12">
              {headers.map(
                (header: string, index: number) =>
                  index !== 0 && <th key={index}>{header}</th>
              )}
              <th id="actions" className={`${styles.actionsColumn} font-12`}>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {headCountData?.slice((currentPage - 1) * rowsPerPage, (currentPage - 1) * rowsPerPage + rowsPerPage)?.map((row: any, index: number) => {
              const rowIndex = (currentPage - 1) * 3 + index;
              return (
                <tr
                  className={
                    (rowIndex !== updatedRowIndex && updatedRowIndex !== -1) ||
                    isInsertOpen
                      ? styles.disabledRow
                      : ""
                  }
                  key={index}
                >
                  {row.map((col: string, colIndex: number) => {
                    if (colIndex !== 0) {
                      return rowIndex !== updatedRowIndex ? (
                        <td key={colIndex} className="font-12">
                          {formatEmptyCell(col)}
                        </td>
                      ) : (
                        <td key={colIndex}>
                          {headers[colIndex] !== "Date" ? (
                            <input
                              type="text"
                              value={col}
                              className={styles.input}
                              onChange={(e) =>
                                handleUpdateChange(
                                  e.target.value,
                                  rowIndex,
                                  colIndex
                                )
                              }
                            />
                          ) : (
                            <DatePicker
                              handleSelectDate={(date: any) =>
                                handleSelectDate(date)
                              }
                              selectedDate={selectedDate}
                            ></DatePicker>
                          )}
                        </td>
                      );
                    }
                  })}
                  <td id="actions" className="text-center">
                    {updatedRowIndex === -1 || rowIndex !== updatedRowIndex ? (
                      <>
                        <IconButton
                            aria-label="eidt"
                            size="small"
                            onClick={() => handleRowUpdate(rowIndex)}
                        >
                          <EditIcon fontSize='small' />
                        </IconButton>
                        <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={() => handleRowDelete(rowIndex)}
                        >
                          <DeleteIcon fontSize='small' />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        <Button
                          size="small"
                          className={`${"btn-neutral-primary"} mb-2 mr-1`}
                          onClick={() => saveUpdate(rowIndex)}
                        >
                          <Done/>
                        </Button>
                        <Button
                          size="small"
                          className={`${"btn-neutral-primary"} mb-2 ml-1`}
                          onClick={() => cancelUpdate(rowIndex)}
                        >
                          <Close/>
                        </Button>
                      </>
                    )}
                  </td>
                </tr>
              );
            })}
            {isInsertOpen && (
              <tr>
                {Object.keys(insertedData).map((col, index) => (
                  <td key={index}>
                    {col === "Date" ? (
                      <DatePicker
                        handleSelectDate={(date: any) => handleSelectDate(date)}
                        selectedDate={selectedDate}
                      ></DatePicker>
                    ) : (
                      <input
                        type="text"
                        value={insertedData[col]}
                        className={styles.input}
                        onChange={(e) => handleInputChange(e.target.value, col)}
                      />
                    )}
                  </td>
                ))}
                <td className="text-center">
                  <Button
                    size="small"
                    className={`${"btn-neutral-primary"} mb-2 mr-1`}
                    onClick={insertRow}
                  >
                    <Done/>
                  </Button>
                  <Button
                    size="small"
                    className={`${"btn-neutral-primary"} mb-2 ml-1`}
                    onClick={cancelInsert}
                  >
                    <Close/>
                  </Button>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <Pagination goToPage={goToPage} rowsPerPage={rowsPerPage} currentPage={currentPage} goToPageValue={goToPageValue} totalPages={totalPages}
                    handleRowsPerPageChange={handleRowsPerPageChange} handleGoToPageChange={handleGoToPageChange} inCenter={true}/>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          key={`${vertical},${horizontal}`}
          open={open}
          classes={{ root: toastrStyle }}
          onClose={handleClose}
          message={message}
        />
      </div>
    </>
  );
};

export default HeadcountHistory;
