import React from 'react';

import { formatEmptyCell } from '../../../utils/utils';
import {
    formatInvestors,
    formatMultiple,
    formatOriginalDate,
    FORMATTING_TYPES,
    formatTotalRaised,
    removeEmailFromOwner
} from '../../../utils/formatting';

import StatusSelect from '../../../components/StatusSelect';
import OwnersSelect from '../../../components/OwnersSelect';
import styles from './styles.module.scss';
import PeoplePriorities from '../../../components/PeoplePrioritySelect';

export const renderCell = ({
   column,
   row,
   columnFormat
}) => {
    let lowerCasedColumnName = column.toLocaleLowerCase()
    let returnedValue = formatEmptyCell(row[column])
    /* eslint-disable no-unused-expressions */
    columnFormat?.forEach(c => {
        if (c[lowerCasedColumnName] === FORMATTING_TYPES.LINK) {
            returnedValue = <a href={row[column]?.startsWith('http') ? row[column] : `http://${row[column]}`}
                               target="_blank" onClick={handleUrlClick}>{formatEmptyCell(row[column])}</a>
        } else if (c[lowerCasedColumnName] === FORMATTING_TYPES.STATUSSELECT) {
            returnedValue = <StatusSelect cinchyId={row['Company Cinchy Id']}
                                          defaultValue={{label: row[column], value: row[column]}}/>
        } else if (c[lowerCasedColumnName] === FORMATTING_TYPES.PEOPLE_PRIORITY_SELECT) {
            returnedValue = <PeoplePriorities cinchyId={row['Cinchy Id']}
                                          defaultValue={{label: row[column], value: row[column]}}/>
        } else if (c[lowerCasedColumnName] === FORMATTING_TYPES.OWNERSELECT) {
            const refactoredData = removeEmailFromOwner(row[column])
            returnedValue = <OwnersSelect key={row['Table Cinchy Id']} affinityId={row["Cinchy Id"]}
                                          ownersInfo={refactoredData}></OwnersSelect>
        } else if (c[lowerCasedColumnName] === FORMATTING_TYPES.OWNERSELECTPEOPLE) {
            const refactoredData = removeEmailFromOwner(row[column])
            returnedValue = <OwnersSelect key={row['Table Cinchy Id']} affinityId={row["Cinchy Id"]}
                                          ownersInfo={refactoredData} peopleSelect={true}></OwnersSelect>
        } else if (c[lowerCasedColumnName] === FORMATTING_TYPES.DATE) {
            returnedValue = formatEmptyCell(formatOriginalDate(row[column]))
        } else if (c[lowerCasedColumnName] === FORMATTING_TYPES.MILLIONS) {
            returnedValue = formatEmptyCell(formatTotalRaised(row[column]))
        } else if (c[lowerCasedColumnName] === FORMATTING_TYPES.BACKGROUNDCOMMA) {
            returnedValue = formatInvestors(row[column], styles['highlight-investors'])
        } else if (c[lowerCasedColumnName] === FORMATTING_TYPES.MULTIPLE) {
            returnedValue = formatEmptyCell(formatMultiple(row[column]))
        }
    })

    return returnedValue
}

 function handleUrlClick(event) {
    event.stopPropagation();
};