import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { usePeoplePriorities } from '../../../hooks/usePeoplePriorities';
import { selectPeoplePrios } from '../../../store/sourcing/sourcingSlice';

function PrioritySearch({ setSelectedValues, selectedValues }) {
    const [priorityOptions, setPriorityOptions] = useState([]);

    usePeoplePriorities();
    const priorityData = useSelector(selectPeoplePrios);

    useEffect(() => {
        setPriorityOptions(priorityData.map(priorityData => ({ label: priorityData['Status'], value: priorityData['Status'] })));
    }, [priorityData])

    return (
        <Select
            theme={(theme) => ({
                ...theme,
                borderRadius: 4,
                colors: {
                    ...theme.colors,
                    primary25: "rgba(110, 60, 250, 0.05)",
                    primary: "#6e3cfa",
                },
            })}
            styles={{
                input: (providedValue) => ({
                    ...providedValue,
                    zIndex: 0,
                    fontSize: '12px',
                }),
                placeholder: (providedValue) => ({
                    ...providedValue,
                    margin: "0 4px",
                    zIndex: 0,
                    fontSize: '12px',
                }),
                control: (base) => ({
                    ...base,
                    width: "300px",
                    zIndex: 0,
                }),
                option: (provided) => ({
                    ...provided,
                    fontSize: 12,
                }),
            }}
            options={priorityOptions}
            isSearchable
            closeMenuOnSelect={false}
            placeholder="Select Priority"
            value={selectedValues}
            onChange={(selected) => {
                setSelectedValues(selected);
            }}
        />
    );
}

export default PrioritySearch;