import React from "react";
import GaugeChart from "react-gauge-chart";

export default function CoverageChart({ isFpc, coverageData }) {
  return (
    <div style={{ textAlign: "center" }}>
      <h5>{isFpc ? "FPC Coverage Score" : "Personal Coverage Score"}</h5>
      {coverageData && (
        <div>
          <div className="py-4 my-2" style={{ width: "100%" }}>
            <GaugeChart
              hideText
              id="chartsGauges2A"
              nrOfLevels={5}
              colors={["#FA0101", "#D46B09", "#FFCC00", "#34C759", "#047230"]}
              arcWidth={0.3}
              percent={
                isFpc
                  ? coverageData["FPC Coverage Score"].split("%")[0] / 100
                  : coverageData["Coverage Score"].split("%")[0] / 100
              }
            />
          </div>
          <div style={{ textAlign: "center", marginTop: "-60px" }}>
            <p
              style={{
                fontWeight: "bold",
                color: isFpc
                  ? `#${coverageData["FPC Coverge Score Color"]}`
                  : `#${coverageData["Coverge Score Color"]}`,
                fontSize: "30px",
                marginTop: "10px",
              }}
            >
              {isFpc ? coverageData["FPC Coverage Score"].split("%")[0] :  coverageData["Coverage Score"].split("%")[0]}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
