import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { Edit } from '@material-ui/icons';
import {
    Button,
    Checkbox,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Tooltip,
} from '@material-ui/core';

import {
    selectCompaniesLoading,
    selectCompanyEditColumns,
    selectCompanyTags,
    selectCompanyTagsLoading,
    selectCompaniesColumnWidths,
    selectActiveTab,
    setActiveTab,
    selectCompaniesByTab,
} from '../../store/sourcing/sourcingSlice';
import {
    deleteCompanyTag,
    getCompaniesByCompany,
    getCompanyTags,
    getCompaniesColumnWidths,
    getCompanyTableColumns,
    getFinancingsTable,
} from '../../store/sourcing/sourcingThunks';
import TSkeleton from '../TSkeleton';

import usePagination from '../../hooks/usePagination';
import Pagination from '../Pagination/Pagination';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';

import * as NotificationHelper from '../../helpers/notification';
import { filterCompany } from '../../pages/Sourcing/Companies/helper';
import { setCompaniesFilterOptions } from '../../store/filter/filterSlice';
import { selectThemeOptions } from '../../store/themeOptions/themeOptionsSlice';

import { useFilter } from '../../hooks/useFilter';
import { useOwners } from '../../hooks/useOwners';

import { getComparator, renderTableCellContent, stableSort, formatColumnWidths, formatColumnFormats, formatColumnDefaultValues } from '../../utils/formatting';
import CreateCompanyModal from '../CreateCompanyModal';
import TaxonomyModal from '../TaxonomyModal';
import CSVPortal from '../CSVPortal';
import UniversalSearch from '../UniversalSearch';
import { TableHead } from './TableHead';
import { TableToolbar } from './Toolbar';

import { defaultColumn, highLightColumn } from '../../pages/Sourcing/utils';
import { checkboxStyle, paginationItemHeight, selectedRowStyle, useStyles } from './styles';
import EditCompanyModal from '../EditCompanyModal';
import OutlookModal from '../OutlookModal';
import { useSectors } from '../../hooks/useSectors';
import { useTags } from '../../hooks/useTags';
import IntelligenceModal from '../../components/IntelligenceModal';
import { useStatuses } from '../../hooks/useStatus';
import { formatEmptyCell } from '../../utils/utils';
import { useFPCIPriorities } from '../../hooks/useFPCIPriorities';
import { useFPCIEngagement } from '../../hooks/useFPCIEngagements';
import { PAGE_NAMES } from '../../constants';

const rowsPerPageOptions = [25, 50, 100]


const TABS = {
    'company': 'BY_COMPANY',
    'sweet-spot': 'SWEET_SPOT',
    'priority-backlog': 'PRIORITY_BACKLOG',
    'trouble-breaking-in': 'TROUBLE_BREAKING_IN',
    'last-weeks-meetings': 'LAST_WEEKS_MEETINGS',
    'next-weeks-meetings': 'NEXT_WEEKS_MEETINGS',
    'recent-updates': 'RECENT_UPDATES',
    'needs-primary-contact': 'PRIMARY_CONTACTS',
    'fpci': 'FPCI',
}

export default function TCompaniesByCompany() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [order, setOrder] = useState('');
    const [orderBy, setOrderBy] = useState('');
    const [selected, setSelected] = useState([]);
    const [rows, setRows] = useState([]);
    const [cells, setCells] = useState([]);
    const { filterOptions } = useFilter({});

    const { sidebarToggle  } = useSelector(selectThemeOptions);
    const activeTab = useSelector(selectActiveTab);

    const [paginationItemWidth, setPaginationItemWidth] = useState(0);
    const location = useLocation();
    const [searchValue, setSearchValue] = useState('');

    const [isOutlookModalOpen, setIsOutlookModalOpen] = useState(false);
    const [currentCompanyId, setCurrentCompanyId] = useState("");
    const [defaultColumnValues, setDefaultColumnValues] = useState(null);

    useEffect(() => {
        const activeTabs = Object.keys(TABS);
        const selectedTab = activeTabs.find(tab => location.pathname.includes(tab));
        if(selectedTab) {
            if(activeTab !== TABS[selectedTab]) {
                dispatch(setActiveTab(TABS[selectedTab]))
                setSearchValue("")
                setSelectedColumns(JSON.parse(localStorage.getItem(`selectedColumns${activeTab}`)
                ) || defaultColumnValues)
            }
        } else {
            dispatch(setActiveTab(TABS['company']))
        }
    }, [location.pathname, activeTab, defaultColumnValues])


    useOwners();
    useStatuses();
    useSectors();
    useFPCIPriorities();
    useFPCIEngagement();
    useTags();

    useEffect(() => {
        setPaginationItemWidth(sidebarToggle ? 60 : 352);
    }, [sidebarToggle])



    const tableContainerRef = useRef(null);
    const [tableOffsetFromTop, setTableOffsetFromTop] = useState(null);

    useEffect(() => {
        const offsetFromTop = tableContainerRef.current?.getBoundingClientRect?.()?.top;
        setTableOffsetFromTop(Math.floor(offsetFromTop));
    }, [tableContainerRef.current])


    const [selectedColumns, setSelectedColumns] = useState(
        JSON.parse(localStorage.getItem(`selectedColumns${activeTab}`)
        ))

    const [editableTagsCell, setEditableTagsCell] = useState(null);
    const [isRequestPending, setIsRequestPending] = useState(false);
    const [isKeyWordModalOpen, setIsKeyWordModalOpen] = useState(false);
    const [isEditCompanyModalOpen, setIsEditCompanyModalOpen] = useState(false);
    const [isCreateCompModalOpen, setIsCreateCompModalOpen] = useState(false);
    const [keyWordCompanyId, setKeyWordCompanyId] = useState(null);
    const [keyWordIndex, setKeyWordIndex] = useState(null);
    const [editCompanyId, setEditCompanyId] = useState(null);
    const [editedCompanyName, setEditedCompanyName] = useState(null);
    const [keyWordUpdatedList, setKeyWordUpdatedList] = useState(null);
    const [columnWidthValues, setColumnWidthValues] = useState(null);
    const [columnFormatValues, setColumnFormatValues] = useState(null);
    const [currentCompanyName, setCurrentCompanyName] = useState("");
    const [isIntelligenceModalOpen, setIsIntelligenceModalOpen] = useState(false);

    // TODO CR-7 remove after tabs new feature full testing
    // const data = useSelector(selectCompaniesByCompany)
    const isLoading = useSelector(selectCompaniesLoading)

    const data = useSelector(selectCompaniesByTab(activeTab));

    useEffect(() => {
        const propertiesToDivideBySix = ['total raised', 'recent valuation'];
        if(data) {
            setRows(data["rows"].map(obj => {
                const newObj = { ...obj };
                propertiesToDivideBySix.forEach(prop => {
                    if (newObj[prop] !== undefined) {
                      newObj[prop] /= 1000000;
                    }
                });
                return newObj
              }));
            setCells(data["cells"]);
        }
    }, [data]);

    useEffect(() => {
        setSelectedColumns(JSON.parse(localStorage.getItem(`selectedColumns${activeTab}`)
                ) || defaultColumnValues)
    }, [defaultColumnValues]);

    // TODO CR-3 Refactor later
    window.generateFilters = window.generateFilters ? window.generateFilters : false;
    let columnWidths = useSelector(selectCompaniesColumnWidths);

    useEffect(() => {
        if(data?.rows?.length && !window.generateFilters) {
            window.generateFilters = true
            dispatch(setCompaniesFilterOptions(data));

        }
    }, [data]);

    useEffect(() => {
        dispatch(getCompaniesColumnWidths());
    }, []);

    useEffect(() => {
        if(columnWidths?.rows?.length) {
            setColumnWidthValues(formatColumnWidths(columnWidths.rows))
            setColumnFormatValues(formatColumnFormats(columnWidths.rows))
            setDefaultColumnValues(formatColumnDefaultValues(columnWidths.rows))
        }
    }, [columnWidths]);

    const companyTags = useSelector(selectCompanyTags);
    const companyTagsLoading = useSelector(selectCompanyTagsLoading);

    const tableColumns = useSelector(selectCompanyEditColumns);

    const filteredRows = useMemo(() => {
        const actualRows =  Object.keys(filterOptions).length ? filterCompany(filterOptions,  rows, 'company')
            : rows
        if(searchValue) {
            return actualRows.filter(item => item['Company Name']?.toLowerCase()?.includes(searchValue?.toLowerCase()))
        }
        return actualRows;
    }, [filterOptions, rows?.length, rows])

    const { currentPage, rowsPerPage, goToPageValue, goToPage, totalPages, handleRowsPerPageChange, handleGoToPageChange} = usePagination({ rows: filteredRows, rowsPerPageValue : rowsPerPageOptions[0] });


    const handleFilterColumns = (columns) => {
        const selectedColumns = ['Cinchy Id', 'Company Name', 'Affinity Org Id', ...columns];
        setSelectedColumns(selectedColumns);
        localStorage.setItem(`selectedColumns${activeTab}`, JSON.stringify(selectedColumns));
    };

    const handleRequestSort = (event, property) => {
        console.log('Log ::: property ===', property);
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = filteredRows.map((n) => n['Cinchy Id']);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        event.stopPropagation();
        
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const handleInputChange = (event) => {
        if (!isNaN(event.target.value)) {
            goToPage(parseInt(event.target.value))
        }
    };

    // const orderedTableColumns = cells?.map(cell => cell.label);
    const orderedTableColumns = useMemo(() => cells?.map(cell => cell?.['Column Name']).filter(item => selectedColumns.includes(item)), [selectedColumns, cells]);


    const editCompanyTags = (e, companyId) => {
        e.stopPropagation();

        if (!editableTagsCell) {
            setEditableTagsCell(companyId);
        } else {
            setEditableTagsCell(null);
        }
    };

    const editCompanyKeyWords = (e, companyId, keyWords, index) => {
        e.stopPropagation();
        setKeyWordIndex(index)
        setKeyWordCompanyId(companyId)
        setKeyWordUpdatedList(keyWords?.split(", "))
        setIsKeyWordModalOpen(true)
    };

    const editCompanyName = (e, companyId, companyName) => {
        e.stopPropagation();
        setEditCompanyId(companyId)
        setEditedCompanyName(companyName)
        setIsEditCompanyModalOpen(true)
    };

    const handleDeleteTag = async (e, tagId) => {
        e.stopPropagation();
        
        setIsRequestPending(true);
        try {
            await dispatch(deleteCompanyTag({tagId}));
            await dispatch(getCompanyTags(editableTagsCell));
            NotificationHelper.show('Tag successfully deleted', 'success');
        } catch (e) {
            NotificationHelper.show('Something went wrong', 'error');
        }
        setIsRequestPending(false);
    };

    const handleOpenCompanyDetails = (companyId) => {
        window.open(`/sourcing/companies/details/${companyId}`, "_blank")
    };

    const preventActions = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };


    // DO is ASAP
    useEffect(() => {
        dispatch(getCompaniesByCompany(activeTab));
    }, [activeTab]);

    const refreshCompaniesAPI = useCallback(() => {
        dispatch(getCompaniesByCompany(activeTab));
        setRows([])
      }, [dispatch, activeTab]);

    useEffect(() => {
        if (editableTagsCell) {
            dispatch(getCompanyTags(editableTagsCell));
        }
    }, [editableTagsCell]);

    useEffect(() => {
        dispatch(getCompanyTableColumns());
    }, []);

    const handleClickCompanyName = useCallback((cinchyId) => {
        handleOpenCompanyDetails(cinchyId)
    }, []);

    const handleCompanyWebsiteSplit = useCallback((value, id, logo, flagData = []) => {
        const [companyName, website] = value.split(' | ');
        const [fpci, fpciColor, flagColor] = flagData.split(' | ');
        return <>
                <div className='d-flex align-items-center relative pr-1'>
                    {/*<CompLogo alt={companyName} src={logo} /> */}
                    <div>

                    <span className={classes.hoverBlack} onClick={() => handleClickCompanyName(id)} style={{ cursor: 'pointer', color: 'black', display: 'block', fontSize: '12px', fontWeight: 'bold' }}>
                        {companyName}
                    </span>
                    <span  style={{ cursor: 'pointer', color: 'black', display: 'block' }}>
                        <a href={website?.startsWith('http') ? website : `http://${website}`} target="_blank" className={classes.hoverBlack} style={{ color: 'grey', fontSize: '12px' }}>{website}</a>
                    </span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', position: "absolute", right: '16px' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: `#${fpciColor}`, width: '18px', height: '18px', borderRadius: '2px', color: 'white' }}>
                            <span className='font-12' >{fpci}</span>
                        </div>
                        <div style={{ background: `#${flagColor}`, width: '18px', height: '18px', borderRadius: '2px' }}></div>
                    </div>
                </div>
               </>
    }, []);

    const checkTodaysDate = useCallback((filterOptions, item) => {
        if(filterOptions[item]?.length === 2) {
            return new Date(filterOptions[item][0]).setHours(0,0,0,0) !== new Date().setHours(0,0,0,0) || 
            new Date(filterOptions[item][1]).setHours(0,0,0,0) !== new Date().setHours(0,0,0,0)
        }
        return true
    }, []);

    const columnsToHighLight = useMemo(() => {
        let filterArray  = Object.keys(filterOptions).includes("Relationships") ? Object.keys(filterOptions).concat(['Investor Relationships', 'Company Relationships', 'Customer Relationships']) : Object.keys(filterOptions)
        let options = new Set(filterArray);
        return orderedTableColumns.filter((item) => (options.has(item.toLowerCase()) || options.has(item) && checkTodaysDate(filterOptions, item)));
    }, [filterOptions, orderedTableColumns])

    const keyWordSearchText = filterOptions['Key Words'] ||  '';


    const handleSearch = (e) => {
        setSearchValue(e.target.value);
    }

    const handleAddRecentOutlook = useCallback((companyId, index) => {
        setKeyWordIndex(index)
        setIsOutlookModalOpen(true);
        setCurrentCompanyId(companyId);
    }, []);

    const handleIntelligenceOpen = useCallback((companyId, company) => {
        setIsIntelligenceModalOpen(true);
        setCurrentCompanyId(companyId);
        setCurrentCompanyName(company);
    }, []);


    return (
        <>
            {/*<LivePreviewExample />*/}
            <CSVPortal rows={rows} cells={cells}  selectedCompaniesIds={selected} />
            {isLoading  ? <TSkeleton></TSkeleton> : <div className="mb-spacing-6-x2">
                {/*// TODO figure this out after DEMO*/}
                {/*<SavedSearch*/}
                {/*    searchedValue={searchedValue}*/}
                {/*    setSearchedValue={setSearchedValue}*/}
                {/*/>*/}
                {/*<div className="divider mb-3"></div>*/}
            <div className="d-flex">
                {/*// TODO CR-4 remove this properly as it is not being */}
                {/*<KeywordsSearch rows={rows} setSearchedRows={setSearchedRows} />*/}
            </div>
                {/*// DOTO change the styling after Demo */}
                <Paper className={classes.paper} style={{ maxWidth: `calc(100vw - ${paginationItemWidth}px)` }} >
                    <TableToolbar
                        selectedCompaniesIds={selected}
                        numSelected={selected.length}
                        filterResultsCount={filteredRows.length}
                        selectedColumns={selectedColumns}
                        columns={tableColumns}
                        rows={filteredRows}
                        cells={cells}
                        handleCreateCompany={setIsCreateCompModalOpen}
                        handleSelectColumns={handleFilterColumns}
                        handleSearch={handleSearch}
                    />
                    <TableContainer ref={tableContainerRef} style={{ minHeight: `calc(100vh - ${tableOffsetFromTop}px - ${paginationItemHeight}px)`, maxHeight: `calc(100vh - ${tableOffsetFromTop}px - ${paginationItemHeight}px)`}}>
                        <Table
                            stickyHeader
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size='medium'
                            aria-label="enhanced table">
                            <TableHead
                                classes={classes}
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={filteredRows?.length}
                                headCells={cells}
                                selected={selectedColumns}
                                columnsToHighLight={columnsToHighLight}
                                columnWidths={columnWidthValues}
                            />
                            <TableBody>
                                {stableSort(filteredRows, getComparator(order, orderBy))
                                    .slice((currentPage - 1) * rowsPerPage, (currentPage - 1) * rowsPerPage + rowsPerPage)
                                    .map((row, rowIndex) => {
                                        const isItemSelected = isSelected(row['Cinchy Id']);

                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row.id}
                                                selected={isItemSelected}
                                                style={isItemSelected ? selectedRowStyle : null}
                                            >
                                                <TableCell className={clsx(classes.fixedCell, classes.whiteBackground)} padding="checkbox">
                                                    <Checkbox
                                                        style={checkboxStyle}
                                                        checked={isItemSelected}
                                                        onClick={(event) => handleClick(event, row['Cinchy Id'])}
                                                    />
                                                </TableCell>
                                                {orderedTableColumns.map((column, index) => (
                                                    <React.Fragment key={`${index}-${column}`}>
                                                            <TableCell
                                                                style={columnsToHighLight.includes(column) ? highLightColumn : defaultColumn}
                                                                key={index}
                                                                align="left"
                                                                padding="none"
                                                                className={clsx(
                                                                    classes.tableCell,
                                                                    column === "Cinchy Id" && classes.displayCell,
                                                                    column === "Company Name" && classes.left,
                                                                    'font-14'
                                                                )}
                                                            >
                                                                {column !== "Company Name" && renderTableCellContent(row, column, columnFormatValues, classes, keyWordSearchText, rowIndex, { handleAddRecentOutlook })}
                                                                {column === "Key Words" && renderTableCellContent(row, column, columnFormatValues) && (
                                                                    <Tooltip title="Edit Key Words" arrow placement="top">
                                                                        <Button
                                                                            className={classes.cellEditBtn}
                                                                            onClick={(e) => editCompanyKeyWords(e, row['Cinchy Id'], row['Key Words'], rowIndex)}
                                                                        >
                                                                            <Edit className={classes.editBtn}/>
                                                                        </Button>
                                                                    </Tooltip>
                                                                )}
                                                                {column === "Category" && renderTableCellContent(row, column, columnFormatValues) && (
                                                                    <Tooltip title="Edit Category" arrow placement="top">
                                                                        <Button
                                                                            className={classes.cellEditBtn}
                                                                            onClick={(e) => editCompanyKeyWords(e, row['Cinchy Id'], row['Key Words'], rowIndex)}
                                                                        >
                                                                            <Edit className={classes.editBtn}/>
                                                                        </Button>
                                                                    </Tooltip>
                                                                )}
                                                                {column === "Sub Category" && renderTableCellContent(row, column, columnFormatValues) && (
                                                                    <Tooltip title="Edit Sub Category" arrow placement="top">
                                                                        <Button
                                                                            className={classes.cellEditBtn}
                                                                            onClick={(e) => editCompanyKeyWords(e, row['Cinchy Id'], row['Key Words'], rowIndex)}
                                                                        >
                                                                            <Edit className={classes.editBtn}/>
                                                                        </Button>
                                                                    </Tooltip>
                                                                )}
                                                                {column === "Technology" && renderTableCellContent(row, column, columnFormatValues) && (
                                                                    <Tooltip title="Edit Technology" arrow placement="top">
                                                                        <Button
                                                                            className={classes.cellEditBtn}
                                                                            onClick={(e) => editCompanyKeyWords(e, row['Cinchy Id'], row['Key Words'], rowIndex)}
                                                                        >
                                                                            <Edit className={classes.editBtn}/>
                                                                        </Button>
                                                                    </Tooltip>
                                                                )}
                                                                {column === "Company Name"  && (
                                                                    <>
                                                                    {handleCompanyWebsiteSplit(formatEmptyCell(row[column]), row['Cinchy Id'], row.Logo, row['Company Name2'])}
                                                                    <Tooltip title="Intelligence" arrow placement="top">
                                                                        <IconButton className={classes.cellIntelligenceBtn} size="small" aria-label="delete">
                                                                            <InfoIcon stylle={{ color: 'black' }} fontSize='small' onClick={() => handleIntelligenceOpen(row["Cinchy Id"], row["Company"])} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip title="Edit Company Name" arrow placement="top">
                                                                        <Button
                                                                            className={classes.cellEditBtn}
                                                                            onClick={(e) => editCompanyName(e, row['Cinchy Id'], row['Company Name'])}
                                                                        >
                                                                            <Edit className={classes.editBtn}/>
                                                                        </Button>
                                                                    </Tooltip>
                                                                    </>
                                                                )}
                                                            </TableCell>
                                                    </React.Fragment>
                                                ))}
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                        {filteredRows?.length === 0 && <div className={classes.emptyData}><p>No data matching selected filters</p></div>}
                    </TableContainer>
                    <Pagination goToPage={goToPage} rowsPerPage={rowsPerPage} currentPage={currentPage} goToPageValue={goToPageValue} totalPages={totalPages}
                     handleRowsPerPageChange={handleRowsPerPageChange} handleGoToPageChange={handleGoToPageChange} options={rowsPerPageOptions}
                     hasArrows={true} hasGoToPage={true}/>
                </Paper>
            </div>}
            {isKeyWordModalOpen && <TaxonomyModal
                isOpen={isKeyWordModalOpen}
                setIsOpen={setIsKeyWordModalOpen}
                companyId={keyWordCompanyId}
                refreshApi={refreshCompaniesAPI}
                activeTab={activeTab}
                index={keyWordIndex}
            />}
            {isEditCompanyModalOpen && <EditCompanyModal
                isOpen={isEditCompanyModalOpen}
                setIsOpen={setIsEditCompanyModalOpen}
                companyId={editCompanyId}
                refreshApi={refreshCompaniesAPI}
                companyNameValue={editedCompanyName}
            />}
            <OutlookModal
                isOpen={isOutlookModalOpen}
                companyId={currentCompanyId}
                selectedOutlook={null}
                setIsOpen={setIsOutlookModalOpen}
                handleRefresh={() => dispatch(getFinancingsTable(activeTab))}
                activeTab={activeTab}
                index={keyWordIndex}
                pageName={PAGE_NAMES.COMPANIES}
            />
            <CreateCompanyModal
                isOpen={isCreateCompModalOpen}
                onClose={() => setIsCreateCompModalOpen(false)}
            />
            <IntelligenceModal
                    id={currentCompanyId}
                    company={currentCompanyName}
                    isOpen={isIntelligenceModalOpen}
                    setIsOpen={() => setIsIntelligenceModalOpen(false)}>
            </IntelligenceModal>
        </>
    );
}
