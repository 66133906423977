import {useEffect, useState} from 'react';
import {getData} from "../api/sourcing";
import {QUERIES, STATUSES} from "../constants";
import {whitespaceGroupingByRow} from "../utils/TGroping";
import {useDispatch} from "react-redux";

const getCompanyNameOptions = async ({ dispatch }) => {
    const { status, response  } = await getData(`${QUERIES.UNIVERSAL_SEARCH}`, dispatch)
    if(status === STATUSES.SUCCESS) {
        return whitespaceGroupingByRow(response);
    }
}

const useCompanyNameOptions = () => {
    const [options, setOptions] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        getCompanyNameOptions({ dispatch }).then((res) => {
            setOptions(res.map(item => ({ value: `${item['Company Name']}`, label: `${item['Company Name']} | ${item['Company Website']}`, ...item })))
        })
    }, [])

    return options
}

export { useCompanyNameOptions };