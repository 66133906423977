import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { ERROR_TYPE } from "../../constants";
import {
  getCompaniesByCompany,
  getCompaniesByInvestor,
  getCompanyBoardData,
  getCompanyFinancialsData,
  getCompanyOutlook,
  getHeadcountHistory,
  getHubspotLists,
  getSavedSearch,
  getSingleEntryDetails,
  getCompanyCustomers,
  addCompanyCustomer,
  updateCompanyCustomer,
  addPrimaryCompetitors,
  addCompanyOutlook,
  updateCompanyOutlook,
  updateInternalNotes,
  getPrimaryCompetitorsByCompany,
  getPrimaryCompetitorsList,
  getBenchmarks,
  getTagOptions,
  addCompanyTag,
  createTagOption,
  getCompanyTableColumns,
  getCompanyTags,
  getResearch,
  createResearch,
  getListOfTechnologies,
  getListOfCompanies,
  getCompanyPrimaryResearch,
  getResearchId,
  taggingResearchByTechnology,
  taggingResearchByCompany,
  deleteResearch,
  getKeyWords,
  updateResearch,
  getCompaniesByInvestorDetail,
  getCompanyKeyWords,
  getCompanyComps,
  getOwnersList,
  getCompanyListedComps,
  getFlags,
  getTopicInsights,
  getTopicInsightsTracking,
  getTopicInsightsFunding,
  getRecentOverview,
  getCompanyContacts,
  getPrimaryContacts,
  getPass,
  getTenPager,
  getComingToMarket,
  getTracking,
  getPipelineStatusList,
  getOnePager,
  getFinalPreso,
  getSingleFundraising,
  getFinancings,
  getFinancingsTable,
  getCompaniesColumnWidths,
  getFinancingColumnWidths,
  getCompaniesByInvestorColumnWidths,
  getCompaniesFinancingColumnWidths,
  getFinancialsColumnWidths,
  getKeyCustomersColumnWidths,
  getSingleFundraisingPartOne,
  getSingleFundraisingPartTwo,
  getFundraisingColumnWidths,
  getSecondaryCompetitors,

  getPriorityList,
  getRelationshipList,
  getSectorsList,
  getCompanyIntelligence,
  getStatuses,
  getFlagsColumnWidths,
  getMissPassLossList,
  getFPCIPriorities,
  getFPCIEngagement,
  getFinancingSyncedData,
  getPeoplePriorities,
} from './sourcingThunks';

interface SourcingState {
  companies: any;
  opportunities: any;
  loading: boolean;
  errors: any;
  singleCompanyDetails: any;
  primaryCompetitorsList: any;
  financings: {
    rows: any[];
    columns: any[];
    isLoading: boolean;
    columnWidths: any[];
  };
  research: {
    rows: any[];
    columns: string[];
    error: string;
    loading: boolean;
    listOfTechnologies: any[];
  };
  listOfTechnologies: any;
  listOfCompanies: any;
  owners: any;
  statuses: any[];
  FPCIEngagements: any[];
  FPCIPrios: any[];
  PeoplePrios: any[];
  sectors: any;
  missPassLoss: any;
  priorities: any;
  relationshipOptions: any;
  overdueFlags: {
    rows: any[];
    cells: any[];
    columnWidths: any[];
  };
  topicInsights: any;
  opportunitiesPage: any;
  pipelineStatusList: any;
  intelligence: any;
}

const initialState: SourcingState = {
  companies: {
    activeTab: 'BY_COMPANY',
    TByCompany: { rows: [], cells: [], error: "", isLoading: false },
    'BY_COMPANY': { rows: [], cells: [], error: "", isLoading: false },
    TByInvestor: { rows: [], cells: [], isLoading: false },
    columns: { data: [], error: "" },
    investorColumns: [],
    investorDetail: {},
    investorDetailColumns: [],
    // selectedColumns: JSON.parse(localStorage.getItem('selectedColumns') as string) || DEFAULT_COLUMNS,
    hubspotLists: [],
    savedSearch: [],
    selectedCompanies: [],
    tagOptions: { data: [], error: "" },
    tags: {
      data: [],
      error: "",
      loading: false,
    },
    keyWords: [],
    companyKeyWords: [],
    columnWidths: [],
    investorColumnWidths: [],
  },
  singleCompanyDetails: {
    board: { data: [], error: "" },
    financials: { data: [], error: "", columnWidths: [] },
    internalNotes: { data: [], error: "" },
    fundraisingHistory1: {},
    fundraisingHistory2: {},
    summary: 
      {
        // Description: '',
        // 'Most Recent Outlook': null,
        // 'Primary Competitors': null,
        // 'Key Investors': null,
        data: [],
        error: ""
      },
    
    investorsPeople: { data: [], error: "" },
    relationships: { data: [], error: "" },
    fundraisingHistory: {part1: {data: [], columns: []}, part2: {data: [], columns: []}, error: "", columnWidths: []},
    keyMetrics: { data: [], error: "" },
    companyOutlook: { data: [], error: "" },
    taxonomy: { data: [], error: "" },
    crm: { data: [], error: "" },
    demographics: { data: [], error: "" },
    headcountHistory: [],
    contactInfo: { data: [], error: "" },
    ownersInfo: [],
    keyCustomers: { data: [], error: "", columnWidths: [] },
    primaryCompetitors: { data: [], error: "" },
    benchmarks: { data: [], error: "" },
    primaryResearch: [],
    comps: [],
    listedComps: [],
    recentOverview: [],
    secondaryCompetitors: [],
    companyContacts: {data: [], columns: []},
    financings: {data: [], columns: [], companyName: "", columnWidths: []},
    financingSyncedData: [],
  },
  primaryCompetitorsList: { data: [], error: "" },
  opportunities: {},
  financings: { rows: [], columns: [], isLoading: false, columnWidths: [] },
  research: { rows: [], error: '', loading: false, listOfTechnologies: [], columns: [] },
  listOfTechnologies: { data: [], error: "", loading: false },
  listOfCompanies: { data: [], error: "", loading: false },
  owners: [],
  statuses: [],
  FPCIEngagements: [],
  FPCIPrios: [],
  PeoplePrios: [],
  sectors: [],
  priorities: [],
  missPassLoss: [],
  relationshipOptions: [],
  overdueFlags: {
    rows: [],
    cells: [],
    columnWidths: [],
  },
  topicInsights: {
    searchedResult: [],
    tracking: [],
    funded: [],
  },
  opportunitiesPage: {
    pass: [],
    tenPager: [],
    onePager: [],
    comingToMarket: [],
    tracking: [],
    finalPreso: [],
  },
  pipelineStatusList: [],
  intelligence: [],
  loading: false,
  errors: [],
};

const sourcingSlice = createSlice({
  name: "sourcing",
  initialState,
  reducers: {
    setSelectedCompaniesIds: (state, action: PayloadAction<number[]>) => {
      state.companies.selectedCompanies = action.payload;
    },
    setSelectedColumns: (state, action: PayloadAction<any>) => {
      const selectedColumns = action.payload;
      state.companies.selectedColumns = selectedColumns;
      localStorage.setItem('selectedColumns', JSON.stringify(selectedColumns));
    },
    emptyBoardError: (state) => {
      state.loading = true;
      state.singleCompanyDetails.board.error = null;
    },
    emptyKeyMetricsError: (state) => {
      state.loading = true;
      state.singleCompanyDetails.keyMetrics.error = null;
    },
    emptyContactInfoError: (state) => {
      state.loading = true;
      state.singleCompanyDetails.contactInfo.error = null;
    },
    emptyFundraisingHistoryError: (state) => {
      state.loading = true;
      state.singleCompanyDetails.fundraisingHistory.error = null;
    },
    emptyInternalNotesError: (state) => {
      state.loading = true;
      state.singleCompanyDetails.internalNotes.error = null;
    },
    emptySummaryError: (state) => {
      state.loading = true;
      state.singleCompanyDetails.summary.error = null;
    },
    emptyTaxonomyError: (state) => {
      state.loading = true;
      state.singleCompanyDetails.taxonomy.error = null;
      state.singleCompanyDetails.taxonomy.data = [];
    },
    emptyDemographicsError: (state) => {
      state.loading = true;
      state.singleCompanyDetails.demographics.error = null;
    },
    emptyCRMError: (state) => {
      state.loading = true;
      state.singleCompanyDetails.crm.error = null;
    },
    emptyFinancialsError: (state) => {
      state.loading = true;
      state.singleCompanyDetails.financials.error = null;
    },
    emptyKeyCustomersError: (state) => {
      state.loading = true;
      state.singleCompanyDetails.keyCustomers.error = null;
    },
    emptyInvestorsPeopleError: (state) => {
      state.loading = true;
      state.singleCompanyDetails.investorsPeople.error = null;
    },
    emptyRelationshipsError: (state) => {
      state.loading = true;
      state.singleCompanyDetails.relationships.error = null;
    },
    setActiveTab: (state, action) => {
      state.companies.activeTab = action.payload;
    },
    updateCompaniesData: (state, action) => {
      Object.keys(action.payload.data).forEach(key => {
        state.companies[action.payload.activeTab].rows[action.payload.index][key] = action.payload.data[key];
      });
    },
    updateFinancingsData: (state, action) => {
      Object.keys(action.payload.data).forEach(key => {
        // @ts-ignore
        state.financings[action.payload.activeTab].rows[action.payload.index][key] = action.payload.data[key];
      });
    },
    updateFlagsData: (state, action) => {
      Object.keys(action.payload.data).forEach(key => {
        // @ts-ignore
        state.overdueFlags.rows[action.payload.index][key] = action.payload.data[key];
      });
    },
  },
  extraReducers: (builder) => {
    builder
      // companies filtered by company
      .addCase(getCompaniesByCompany.pending, (state) => {
        state.companies.isLoading = true;
      })
      .addCase(
        getCompaniesByCompany.fulfilled,
        (state, action: PayloadAction<any>) => {
          const { activeTab, ...rest } = action.payload;
          state.companies.isLoading = false;
          state.companies[activeTab] = rest;
          if (action?.payload?.length === 0) {
            state.companies.TByCompany.error = ERROR_TYPE.EMPTY_DATA;
          }
        }
      )
      .addCase(getCompaniesByCompany.rejected, (state) => {
        state.companies.TByCompany.error = ERROR_TYPE.SERVER_ERROR;
        state.companies.isLoading = false;
        state.errors = "Something went wrong";
      })

      // companies filtered by company
      .addCase(getCompaniesByInvestor.pending, (state) => {
        state.companies.TByInvestor.isLoading = true;
      })
      .addCase(
        getCompaniesByInvestor.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.companies.TByInvestor.isLoading = false;
          state.companies.TByInvestor = action.payload;
          state.companies.investorColumns = action.payload?.cells?.map((column: {[key: string]: string}) => ({ Category: 'Investor Columns', 'Column Name': column.label} ))
          .filter((column: any) => column["Column Name"].toLowerCase() !== 'investor id' && column["Column Name"].toLowerCase() !== 'cinchy id' && column["Column Name"].toLowerCase() !== 'investor name'
         );
        }
      )
      .addCase(getCompaniesByInvestor.rejected, (state) => {
        state.companies.TByInvestor.isLoading = false;
        state.errors = "Something went wrong";
      })

      .addCase(getHubspotLists.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getHubspotLists.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.companies.hubspotLists = action.payload;
        }
      )
      .addCase(getHubspotLists.rejected, (state) => {
        state.loading = false;
        state.errors = "Something went wrong";
      })

      .addCase(getSavedSearch.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getSavedSearch.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.companies.savedSearch = action.payload;
        }
      )
      .addCase(getSavedSearch.rejected, (state) => {
        state.loading = false;
        state.errors = "Something went wrong";
      })
      .addCase(getSingleEntryDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getSingleEntryDetails.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.singleCompanyDetails.internalNotes.data =
            action.payload.internalNotes;
          state.singleCompanyDetails.keyMetrics.data =
            action.payload.keyMetrics;
          state.singleCompanyDetails.investorsPeople.data =
            action.payload.investorsPeople;
          state.singleCompanyDetails.relationships.data =
            action.payload.relationships;
          state.singleCompanyDetails.summary.data = action.payload.summary;
          state.singleCompanyDetails.taxonomy.data = action.payload.taxonomy;
          state.singleCompanyDetails.crm.data = action.payload.crm;
          state.singleCompanyDetails.demographics.data = action.payload.demographics;
          state.singleCompanyDetails.ownersInfo = action.payload.ownersInfo;
          state.singleCompanyDetails.contactInfoExtra = action.payload.contactInfo;
          state.singleCompanyDetails.hubspotSequence = action.payload.hubspotSequence;
          state.singleCompanyDetails.financings.companyName = action.payload.companyName;
          state.singleCompanyDetails.hubspotSequence = action.payload.hubspotSequence;
          if (action.payload.keyMetrics.length === 0) {
            state.singleCompanyDetails.keyMetrics.error = ERROR_TYPE.EMPTY_DATA;
          }
          if (action.payload.fundraisingHistory1.length === 0 || action.payload.fundraisingHistory2.length === 0) {
            state.singleCompanyDetails.fundraisingHistory.error = ERROR_TYPE.EMPTY_DATA;
          }
          if (action.payload.internalNotes.length === 0) {
            state.singleCompanyDetails.internalNotes.error = ERROR_TYPE.EMPTY_DATA;
          }
          if (action.payload.summary.length === 0) {
            state.singleCompanyDetails.summary.error = ERROR_TYPE.EMPTY_DATA;
          }
          if (action.payload.taxonomy.length === 0) {
            state.singleCompanyDetails.taxonomy.error = ERROR_TYPE.EMPTY_DATA;
          }
          if (action.payload.demographics.length === 0) {
            state.singleCompanyDetails.demographics.error = ERROR_TYPE.EMPTY_DATA;
          }
          if (action.payload.crm.length === 0) {
            state.singleCompanyDetails.crm.error = ERROR_TYPE.EMPTY_DATA;
          }
          if (action.payload.investorsPeople.length === 0) {
            state.singleCompanyDetails.investorsPeople.error = ERROR_TYPE.EMPTY_DATA;
          }
          if (action.payload.relationships.length === 0) {
            state.singleCompanyDetails.relationships.error = ERROR_TYPE.EMPTY_DATA;
          }
        }
      )
      .addCase(getSingleEntryDetails.rejected, (state) => {
        state.loading = false;
        state.singleCompanyDetails.keyMetrics.error = ERROR_TYPE.SERVER_ERROR;
        state.singleCompanyDetails.contactInfo.error = ERROR_TYPE.SERVER_ERROR;
        state.singleCompanyDetails.fundraisingHistory.error = ERROR_TYPE.SERVER_ERROR;
        state.singleCompanyDetails.internalNotes.error = ERROR_TYPE.SERVER_ERROR;
        state.singleCompanyDetails.summary.error = ERROR_TYPE.SERVER_ERROR;
        state.singleCompanyDetails.taxonomy.error = ERROR_TYPE.SERVER_ERROR;
        state.singleCompanyDetails.demographics.error = ERROR_TYPE.SERVER_ERROR;
        state.singleCompanyDetails.crm.error = ERROR_TYPE.SERVER_ERROR;
        state.singleCompanyDetails.investorsPeople.error = ERROR_TYPE.SERVER_ERROR;
        state.errors = "Something went wrong";
      })
      .addCase(getCompanyBoardData.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getCompanyBoardData.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.singleCompanyDetails.board.data = action.payload;
          if (action.payload.length === 0) {
            state.singleCompanyDetails.board.error = ERROR_TYPE.EMPTY_DATA;
          }
        }
      )
      .addCase(getCompanyBoardData.rejected, (state) => {
        state.singleCompanyDetails.board.error = ERROR_TYPE.SERVER_ERROR;
        state.loading = false;
        state.errors = "Something went wrong";
      })
      .addCase(getCompanyFinancialsData.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getCompanyFinancialsData.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.singleCompanyDetails.financials.data = action.payload;
          if (action.payload.length === 0) {
            state.singleCompanyDetails.financials.error = ERROR_TYPE.EMPTY_DATA;
          }
        }
      )
      .addCase(getCompanyFinancialsData.rejected, (state) => {
        state.singleCompanyDetails.financials.error = ERROR_TYPE.SERVER_ERROR;
        state.loading = false;
        state.errors = "Something went wrong";
      })

      .addCase(getCompanyOutlook.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getCompanyOutlook.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          if (action.payload) {
            state.singleCompanyDetails.companyOutlook.data = action.payload;
          }
        }
      )
      .addCase(getCompanyOutlook.rejected, (state) => {
        state.loading = false;
        state.singleCompanyDetails.companyOutlook.error = ERROR_TYPE.EMPTY_DATA;
      })

      .addCase(getHeadcountHistory.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getHeadcountHistory.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.singleCompanyDetails.headcountHistory = action.payload;
        }
      )
      .addCase(getHeadcountHistory.rejected, (state) => {
        state.loading = false;
        state.errors = "Something went wrong";
      })

      .addCase(getCompaniesColumnWidths.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getCompaniesColumnWidths.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.companies.columnWidths = action.payload;
        }
      )
      .addCase(getCompaniesColumnWidths.rejected, (state) => {
        state.loading = false;
        state.errors = "Something went wrong";
      })

      .addCase(getCompaniesByInvestorColumnWidths.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getCompaniesByInvestorColumnWidths.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.companies.investorColumnWidths = action.payload;
        }
      )
      .addCase(getCompaniesByInvestorColumnWidths.rejected, (state) => {
        state.loading = false;
        state.errors = "Something went wrong";
      })

      .addCase(getFinancingColumnWidths.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getFinancingColumnWidths.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.financings.columnWidths = action.payload;
        }
      )
      .addCase(getFinancingColumnWidths.rejected, (state) => {
        state.loading = false;
        state.errors = "Something went wrong";
      })

      .addCase(getCompaniesFinancingColumnWidths.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getCompaniesFinancingColumnWidths.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.singleCompanyDetails.financings.columnWidths = action.payload;
        }
      )
      .addCase(getCompaniesFinancingColumnWidths.rejected, (state) => {
        state.loading = false;
        state.errors = "Something went wrong";
      })

      .addCase(getFinancialsColumnWidths.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getFinancialsColumnWidths.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.singleCompanyDetails.financials.columnWidths = action.payload;
        }
      )
      .addCase(getFinancialsColumnWidths.rejected, (state) => {
        state.loading = false;
        state.errors = "Something went wrong";
      })

      .addCase(getFlagsColumnWidths.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getFlagsColumnWidths.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.overdueFlags.columnWidths = action.payload;
        }
      )
      .addCase(getFlagsColumnWidths.rejected, (state) => {
        state.loading = false;
        state.errors = "Something went wrong";
      })

      .addCase(getKeyCustomersColumnWidths.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getKeyCustomersColumnWidths.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.singleCompanyDetails.keyCustomers.columnWidths = action.payload;
        }
      )
      .addCase(getKeyCustomersColumnWidths.rejected, (state) => {
        state.loading = false;
        state.errors = "Something went wrong";
      })

      .addCase(getFundraisingColumnWidths.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getFundraisingColumnWidths.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.singleCompanyDetails.fundraisingHistory.columnWidths = action.payload;
        }
      )
      .addCase(getFundraisingColumnWidths.rejected, (state) => {
        state.loading = false;
        state.errors = "Something went wrong";
      })

      .addCase(getPrimaryContacts.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getPrimaryContacts.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.singleCompanyDetails.contactInfo.data = action.payload;
        }
      )
      .addCase(getPrimaryContacts.rejected, (state) => {
        state.loading = false;
        state.errors = "Something went wrong";
      })
      .addCase(getCompanyCustomers.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getCompanyCustomers.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.singleCompanyDetails.keyCustomers.data = action.payload;
          if (action.payload.length === 0) {
            state.singleCompanyDetails.keyCustomers.error = ERROR_TYPE.EMPTY_DATA;
          }
        }
      )
      .addCase(getCompanyCustomers.rejected, (state) => {
        state.singleCompanyDetails.keyCustomers.error = ERROR_TYPE.SERVER_ERROR;
        state.loading = false;
        state.errors = "Something went wrong";
      })

      .addCase(addCompanyCustomer.pending, (state) => {
        state.loading = true;
      })
      .addCase(addCompanyCustomer.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(addCompanyCustomer.rejected, (state) => {
        state.loading = false;
        state.errors = "Something went wrong";
      })

      .addCase(getResearch.pending, (state) => {
        state.research.loading = true;
      })
      .addCase(getResearch.fulfilled, (state, action: PayloadAction<any>) => {
        state.research.loading = false;
        state.research = action.payload;
        const columns = action.payload!.cells
        .map((column: {[key: string]: string}) => ({ Category: 'Columns', 'Column Name': column.label} ));
        const filteredColuns = columns
        .filter((column: any) => column["Column Name"].toLowerCase() !== 'cinchy id')
        state.research.columns = filteredColuns;
      })
      .addCase(getResearch.rejected, (state) => {
        state.research.loading = false;
        // state.research.errors = "Something went wrong";
      })

      .addCase(updateCompanyCustomer.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCompanyCustomer.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateCompanyCustomer.rejected, (state) => {
        state.loading = false;
        state.errors = "Something went wrong";
      })

      .addCase(addPrimaryCompetitors.pending, (state) => {
        state.loading = true;
      })
      .addCase(addPrimaryCompetitors.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(addPrimaryCompetitors.rejected, (state) => {
        state.loading = false;
        state.errors = "Something went wrong";
      })

      .addCase(addCompanyOutlook.pending, (state) => {
        state.loading = true;
      })
      .addCase(addCompanyOutlook.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(addCompanyOutlook.rejected, (state) => {
        state.loading = false;
        state.errors = "Something went wrong";
      })

      .addCase(updateCompanyOutlook.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCompanyOutlook.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateCompanyOutlook.rejected, (state) => {
        state.loading = false;
        state.errors = "Something went wrong";
      })

      .addCase(updateInternalNotes.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateInternalNotes.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateInternalNotes.rejected, (state) => {
        state.loading = false;
        state.errors = "Something went wrong";
      })

      .addCase(getPrimaryCompetitorsByCompany.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPrimaryCompetitorsByCompany.fulfilled, (state, action) => {
        state.singleCompanyDetails.primaryCompetitors.data = action.payload;
        state.loading = false;
      })
      .addCase(getPrimaryCompetitorsByCompany.rejected, (state) => {
        state.singleCompanyDetails.primaryCompetitors.error = ERROR_TYPE.EMPTY_DATA;
        state.loading = false;
      })

      .addCase(getSecondaryCompetitors.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSecondaryCompetitors.fulfilled, (state, action) => {
        state.singleCompanyDetails.secondaryCompetitors = action.payload;
        state.loading = false;
      })
      .addCase(getSecondaryCompetitors.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getRecentOverview.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRecentOverview.fulfilled, (state, action) => {
        state.singleCompanyDetails.recentOverview = action.payload;
        state.loading = false;
      })
      .addCase(getRecentOverview.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getCompanyContacts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCompanyContacts.fulfilled, (state, action) => {
        state.singleCompanyDetails.companyContacts = action.payload;
        state.loading = false;
      })
      .addCase(getCompanyContacts.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getFinancings.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFinancings.fulfilled, (state, action: any) => {
        state.singleCompanyDetails.financings.data = action.payload.data;
        state.singleCompanyDetails.financings.columns = action.payload.columns;
        state.loading = false;
      })
      .addCase(getFinancings.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getSingleFundraising.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleFundraising.fulfilled, (state, action: PayloadAction<any>) => {
        state.singleCompanyDetails.fundraisingHistory.part1 =
            action.payload.fundraisingHistory1;
          state.singleCompanyDetails.fundraisingHistory.part2 =
            action.payload.fundraisingHistory2;
        state.loading = false;
      })
      .addCase(getSingleFundraising.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getSingleFundraisingPartOne.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleFundraisingPartOne.fulfilled, (state, action: PayloadAction<any>) => {
        state.singleCompanyDetails.fundraisingHistory.part1.data = action.payload.data;
        state.singleCompanyDetails.fundraisingHistory.part1.columns = action.payload.columns;
        state.loading = false;
      })
      .addCase(getSingleFundraisingPartOne.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getSingleFundraisingPartTwo.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleFundraisingPartTwo.fulfilled, (state, action: PayloadAction<any>) => {
        state.singleCompanyDetails.fundraisingHistory.part2.data = action.payload.data;
        state.singleCompanyDetails.fundraisingHistory.part2.columns = action.payload.columns;
        state.loading = false;
      })
      .addCase(getSingleFundraisingPartTwo.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getTopicInsights.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTopicInsights.fulfilled, (state, action) => {
        state.topicInsights.searchedResult = action.payload;
        state.loading = false;
      })
      .addCase(getTopicInsights.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getTopicInsightsTracking.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTopicInsightsTracking.fulfilled, (state, action) => {
        state.topicInsights.tracking = action.payload;
        state.loading = false;
      })
      .addCase(getTopicInsightsTracking.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getTopicInsightsFunding.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTopicInsightsFunding.fulfilled, (state, action) => {
        state.topicInsights.funded = action.payload;
        state.loading = false;
      })
      .addCase(getTopicInsightsFunding.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getPrimaryCompetitorsList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPrimaryCompetitorsList.fulfilled, (state, action) => {
        state.primaryCompetitorsList.data = action.payload;
        state.loading = false;
      })
      .addCase(getPrimaryCompetitorsList.rejected, (state) => {
        state.primaryCompetitorsList.error = ERROR_TYPE.EMPTY_DATA;
        state.loading = false;
      })

      .addCase(getCompanyIntelligence.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCompanyIntelligence.fulfilled, (state, action) => {
        state.intelligence = action.payload;
        state.loading = false;
      })
      .addCase(getCompanyIntelligence.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getBenchmarks.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBenchmarks.fulfilled, (state, action) => {
        state.singleCompanyDetails.benchmarks.data = action.payload;
        state.loading = false;
      })
      .addCase(getBenchmarks.rejected, (state) => {
        state.singleCompanyDetails.benchmarks.error = ERROR_TYPE.EMPTY_DATA;
        state.loading = false;
      })
      .addCase(getFinancingSyncedData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFinancingSyncedData.fulfilled, (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.singleCompanyDetails.financingSyncedData = action.payload;
      })
      .addCase(getFinancingSyncedData.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getTagOptions.pending, (state) => {})
      .addCase(getTagOptions.fulfilled, (state, action) => {
        state.companies.tagOptions.data = action.payload;
      })
      .addCase(getTagOptions.rejected, (state) => {
        state.companies.tagOptions.error = ERROR_TYPE.EMPTY_DATA;
      })

      .addCase(addCompanyTag.pending, (state) => {
        state.companies.tags.loading = true;
      })
      .addCase(addCompanyTag.fulfilled, (state, action) => {
        // state.companies.tags.data = action.payload;
        state.companies.tags.loading = false;
      })
      .addCase(addCompanyTag.rejected, (state) => {
        state.companies.tagOptions.error = ERROR_TYPE.EMPTY_DATA;
        state.companies.tags.loading = false;
      })

      .addCase(createTagOption.pending, (state) => {})
      .addCase(createTagOption.fulfilled, (state, action) => {})
      .addCase(createTagOption.rejected, (state) => {})

      .addCase(getCompanyTableColumns.pending, (state) => {})
      .addCase(getCompanyTableColumns.fulfilled, (state, action) => {
        state.companies.columns.data = action.payload;
      })
      .addCase(getCompanyTableColumns.rejected, (state) => {
        state.companies.columns.error = ERROR_TYPE.EMPTY_DATA;
      })

      .addCase(getCompanyTags.pending, (state) => {
        state.companies.tags.loading = true;
      })
      .addCase(getCompanyTags.fulfilled, (state, action) => {
        state.companies.tags.loading = false;
        state.companies.tags.data = action.payload;
      })
      .addCase(getCompanyTags.rejected, (state) => {
        state.companies.tags.loading = false;
        state.companies.tags.error = ERROR_TYPE.EMPTY_DATA;
      })

      .addCase(createResearch.pending, (state) => {
        state.loading = true;
      })
      .addCase(createResearch.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createResearch.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getListOfTechnologies.pending, (state) => {
        state.listOfTechnologies.loading = true;
      })
      .addCase(getListOfTechnologies.fulfilled, (state, action) => {
        state.listOfTechnologies.loading = false;
        state.listOfTechnologies.data = action.payload;
      })
      .addCase(getListOfTechnologies.rejected, (state) => {
        state.listOfTechnologies.loading = false;
        state.listOfTechnologies.error = ERROR_TYPE.EMPTY_DATA;
      })

      .addCase(getListOfCompanies.pending, (state) => {
        state.listOfCompanies.loading = true;
      })
      .addCase(getListOfCompanies.fulfilled, (state, action) => {
        state.listOfCompanies.loading = false;
        state.listOfCompanies.data = action.payload;
      })
      .addCase(getListOfCompanies.rejected, (state) => {
        state.listOfCompanies.loading = false;
        state.listOfCompanies.error = ERROR_TYPE.EMPTY_DATA;
      })
      .addCase(getCompanyPrimaryResearch.pending, (state) => {
        state.companies.tags.loading = true;
      })
      .addCase(getCompanyPrimaryResearch.fulfilled, (state, action) => {
        state.companies.tags.loading = false;
        state.singleCompanyDetails.primaryResearch = action.payload;
      })
      .addCase(getCompanyPrimaryResearch.rejected, (state) => {
        state.companies.tags.loading = false;
      })

      .addCase(getCompanyComps.pending, (state) => {
        state.companies.tags.loading = true;
      })
      .addCase(getCompanyComps.fulfilled, (state, action) => {
        state.companies.tags.loading = false;
        state.singleCompanyDetails.comps = action.payload;
      })
      .addCase(getCompanyComps.rejected, (state) => {
        state.companies.tags.loading = false;
      })

      .addCase(getCompanyListedComps.pending, (state) => {
        state.companies.tags.loading = true;
      })
      .addCase(getCompanyListedComps.fulfilled, (state, action) => {
        state.companies.tags.loading = false;
        state.singleCompanyDetails.listedComps = action.payload;
      })
      .addCase(getCompanyListedComps.rejected, (state) => {
        state.companies.tags.loading = false;
      })

      .addCase(getResearchId.pending, (state) => {
        state.loading = true;
      })
      .addCase(getResearchId.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getResearchId.rejected, (state) => {
        state.loading = false;
      })

      .addCase(taggingResearchByTechnology.pending, (state) => {
        state.loading = true;
      })
      .addCase(taggingResearchByTechnology.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(taggingResearchByTechnology.rejected, (state) => {
        state.loading = false;
      })

      .addCase(taggingResearchByCompany.pending, (state) => {
        state.loading = true;
      })
      .addCase(taggingResearchByCompany.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(taggingResearchByCompany.rejected, (state) => {
        state.loading = false;
      })

      .addCase(deleteResearch.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteResearch.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteResearch.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getKeyWords.pending, (state) => {
        state.loading = true;
      })
      .addCase(getKeyWords.fulfilled, (state, action) => {
        state.companies.keyWords = action.payload;
        state.loading = false;
      })
      .addCase(getKeyWords.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getFlags.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFlags.fulfilled, (state, action) => {
        // @ts-ignore
        state.overdueFlags = action.payload;
        state.loading = false;
      })
      .addCase(getFlags.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getPass.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPass.fulfilled, (state, action) => {
        state.opportunitiesPage.pass = action.payload;
        state.loading = false;
      })
      .addCase(getPass.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getTenPager.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTenPager.fulfilled, (state, action) => {
        state.opportunitiesPage.tenPager = action.payload;
        state.loading = false;
      })
      .addCase(getTenPager.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getOnePager.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOnePager.fulfilled, (state, action) => {
        state.opportunitiesPage.onePager = action.payload;
        state.loading = false;
      })
      .addCase(getOnePager.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getFinalPreso.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFinalPreso.fulfilled, (state, action) => {
        state.opportunitiesPage.finalPreso = action.payload;
        state.loading = false;
      })
      .addCase(getFinalPreso.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getComingToMarket.pending, (state) => {
        state.loading = true;
      })
      .addCase(getComingToMarket.fulfilled, (state, action) => {
        state.opportunitiesPage.comingToMarket = action.payload;
        state.loading = false;
      })
      .addCase(getComingToMarket.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getTracking.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTracking.fulfilled, (state, action) => {
        state.opportunitiesPage.tracking = action.payload;
        state.loading = false;
      })
      .addCase(getTracking.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getCompaniesByInvestorDetail.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCompaniesByInvestorDetail.fulfilled, (state, action) => {
        state.companies.investorDetail = action.payload;
        state.companies.investorDetailColumns = action.payload?.cells?.map((column: {[key: string]: string}) => ({ Category: 'Company Columns', 'Column Name': column.label} ))
          .filter((column: any) => column["Column Name"].toLowerCase() !== 'cinchy id'
         );
        state.loading = false;
      })
      .addCase(getCompaniesByInvestorDetail.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getCompanyKeyWords.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCompanyKeyWords.fulfilled, (state, action) => {
        state.companies.companyKeyWords = action.payload;
        state.loading = false;
      })
      .addCase(getCompanyKeyWords.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getOwnersList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOwnersList.fulfilled, (state, action) => {
        state.owners = action.payload;
        state.loading = false;
      })
      .addCase(getOwnersList.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getMissPassLossList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMissPassLossList.fulfilled, (state, action) => {
        state.missPassLoss = action.payload;
        state.loading = false;
      })
      .addCase(getMissPassLossList.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getStatuses.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStatuses.fulfilled, (state, action) => {
        // @ts-ignore
        state.statuses = action.payload;
        state.loading = false;
      })
      .addCase(getStatuses.rejected, (state) => {
        state.loading = false;
      })

      //FPCI PRIO
      .addCase(getFPCIPriorities.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFPCIPriorities.fulfilled, (state, action) => {
        // @ts-ignore
        state.FPCIPrios = action.payload;
        state.loading = false;
      })
      .addCase(getFPCIPriorities.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getPeoplePriorities.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPeoplePriorities.fulfilled, (state, action) => {
        // @ts-ignore
        state.PeoplePrios = action.payload;
        state.loading = false;
      })
      .addCase(getPeoplePriorities.rejected, (state) => {
        state.loading = false;
      })

      //FPCI Engagement
      .addCase(getFPCIEngagement.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFPCIEngagement.fulfilled, (state, action) => {
        // @ts-ignore
        state.FPCIEngagements = action.payload;
        state.loading = false;
      })
      .addCase(getFPCIEngagement.rejected, (state) => {
        state.loading = false;
      })

        .addCase(getSectorsList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSectorsList.fulfilled, (state, action) => {
        state.sectors = action.payload;
        state.loading = false;
      })
      .addCase(getSectorsList.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getPriorityList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPriorityList.fulfilled, (state, action) => {
        state.priorities = action.payload;
        state.loading = false;
      })
      .addCase(getPriorityList.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getRelationshipList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRelationshipList.fulfilled, (state, action) => {
        state.relationshipOptions = action.payload;
        state.loading = false;
      })
      .addCase(getRelationshipList.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getPipelineStatusList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPipelineStatusList.fulfilled, (state, action) => {
        state.pipelineStatusList = action.payload;
        state.loading = false;
      })
      .addCase(getPipelineStatusList.rejected, (state) => {
        state.loading = false;
      })

      .addCase(updateResearch.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateResearch.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateResearch.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getFinancingsTable.pending, (state) => {
        state.financings.isLoading = true;
      })
      .addCase(getFinancingsTable.fulfilled,         (state, action: PayloadAction<any>) => {
        const { activeTab, ...rest } = action.payload;
        // @ts-ignore
        state.financings[activeTab] = rest;
        state.financings.isLoading = false;
      })
      .addCase(getFinancingsTable.rejected, (state) => {
        state.financings.isLoading = false;
      })


  },
});

export const {
  emptyBoardError,
  emptyKeyMetricsError,
  emptyContactInfoError,
  emptyFundraisingHistoryError,
  emptyInternalNotesError,
  emptySummaryError,
  emptyTaxonomyError,
  emptyDemographicsError,
  emptyCRMError,
  emptyFinancialsError,
  emptyInvestorsPeopleError,
  emptyRelationshipsError,
  setActiveTab,
  updateCompaniesData,
  updateFinancingsData,
  updateFlagsData
} = sourcingSlice.actions;

export const selectCompanies = (state: RootState) => state.sourcing.companies;
export const selectOpportunities = (state: RootState) =>
  state.sourcing.opportunities;

export const selectCompaniesByCompany = (state: RootState) =>
  state.sourcing.companies.TByCompany;

export const selectCompaniesByTab = (activeTab: string) => (state: RootState) => {
  return state.sourcing.companies[activeTab];
};
export const selectCompaniesLoading = (state: RootState) =>
  state.sourcing.companies.isLoading;
export const selectCompaniesByInvestor = (state: RootState) =>
  state.sourcing.companies.TByInvestor;
export const selectSelectedColumns = (state: RootState) =>
  state.sourcing.companies.selectedColumns;
export const selectHubspotLists = (state: RootState) =>
  state.sourcing.companies.hubspotLists;
export const selectSavedSearch = (state: RootState) =>
  state.sourcing.companies.savedSearch;
export const selectSingleCompanyDetails = (state: RootState) =>
  state.sourcing.singleCompanyDetails;
export const selectCompanyDetailsHubspotSequence = (state: RootState) =>
  state.sourcing.singleCompanyDetails.hubspotSequence;
export const selectLoading = (state: RootState) =>
  state.sourcing.loading;
export const selectPrimaryCompetitorsList = (state: RootState) =>
  state.sourcing.primaryCompetitorsList.data;  
export const selectSecondaryCompetitorsList = (state: RootState) =>
  state.sourcing.singleCompanyDetails.secondaryCompetitorsList; 
export const selectBenchmarks = (state: RootState) =>
  state.sourcing.singleCompanyDetails.benchmarks.data;
export const selectTagOptions = (state: RootState) =>
  state.sourcing.companies.tagOptions.data;
export const selectKeyWords = (state: RootState) =>
  state.sourcing.companies.keyWords;
export const selectCompanyEditColumns = (state: RootState) =>
  state.sourcing.companies.columns.data;
export const selectCompaniesByInvestorColumns = (state: RootState) =>
  state.sourcing.companies.investorColumns;
export const selectCompanyTags = (state: RootState) =>
  state.sourcing.companies.tags.data;
export const selectCompanyTagsLoading = (state: RootState) =>
  state.sourcing.companies.tags.loading;
export const selectResearch = (state: RootState) =>
  state.sourcing.research;
export const selectFlags = (state: RootState) =>
  state.sourcing.overdueFlags;
export const selectPass = (state: RootState) =>
  state.sourcing.opportunitiesPage.pass;
export const selectTenPager = (state: RootState) =>
  state.sourcing.opportunitiesPage.tenPager;
export const selectOnePager = (state: RootState) =>
  state.sourcing.opportunitiesPage.onePager;
export const selectFinalPreso = (state: RootState) =>
  state.sourcing.opportunitiesPage.finalPreso;
export const selectComingToMarket = (state: RootState) =>
  state.sourcing.opportunitiesPage.comingToMarket;
export const selectTracking = (state: RootState) =>
  state.sourcing.opportunitiesPage.tracking;
export const selectResearchLoading = (state: RootState) =>
  state.sourcing.research.loading;
export const selectListOfTechnologies = (state: RootState) =>
  state.sourcing.listOfTechnologies.data;
export const selectListOfCompanies = (state: RootState) =>
  state.sourcing.listOfCompanies.data;
export const selectPrimaryResearch = (state: RootState) =>
  state.sourcing.singleCompanyDetails.primaryResearch;
export const selectCompanyComps = (state: RootState) =>
  state.sourcing.singleCompanyDetails.comps;
export const selectCompanyListedComps = (state: RootState) =>
  state.sourcing.singleCompanyDetails.listedComps;
export const selectResearchColumns = (state: RootState) =>
  state.sourcing.research.columns;
export const selectInvestorDetail = (state: RootState) =>
  state.sourcing.companies.investorDetail;
export const selectCompanyKeyWords = (state: RootState) =>
  state.sourcing.companies.companyKeyWords;
export const selectInvestorDetailColumns = (state: RootState) =>
  state.sourcing.companies.investorDetailColumns;
export const selectOwners = (state: RootState) =>
  state.sourcing.owners;
export const selectMissPassLoss = (state: RootState) =>
  state.sourcing.missPassLoss;
export const selectStatuses = (state: RootState) =>
  state.sourcing.statuses;
export const selectFPCIPrios = (state: RootState) =>
  state.sourcing.FPCIPrios;
export const selectPeoplePrios = (state: RootState) =>
  state.sourcing.PeoplePrios;
export const selectFPCIEngagment = (state: RootState) =>
    state.sourcing.FPCIEngagements;
export const selectSectors = (state: RootState) =>
  state.sourcing.sectors;
export const selectPriorities = (state: RootState) =>
  state.sourcing.priorities;
export const selectRelationshipOptions = (state: RootState) =>
  state.sourcing.relationshipOptions;
export const selectPipelineStatusList = (state: RootState) =>
  state.sourcing.pipelineStatusList;
export const selectTopicInsights = (state: RootState) =>
  state.sourcing.topicInsights.searchedResult;
export const selectTopicInsightsTracking = (state: RootState) =>
  state.sourcing.topicInsights.tracking;
export const selectTopicInsightsFunded = (state: RootState) =>
  state.sourcing.topicInsights.funded;
export const selectFinancingsTable = (state: RootState) =>
  state.sourcing.financings;

export const selectFinancingsByTab = (activeTab: string) => (state: RootState) => {
  if(activeTab === 'BY_COMPANY') {
    activeTab = 'all'
  }
  // @ts-ignore
  return {data: state.sourcing.financings[activeTab], isLoading: state.sourcing.financings.isLoading};
};
export const selectCompaniesColumnWidths = (state: RootState) =>
  state.sourcing.companies.columnWidths;
export const selectCompaniesInvestorColumnWidths = (state: RootState) =>
  state.sourcing.companies.investorColumnWidths;
export const selectFinancingsColumnWidths = (state: RootState) =>
  state.sourcing.financings.columnWidths;
export const selectFlagsColumnWidths = (state: RootState) =>
  state.sourcing.overdueFlags.columnWidths;
export const selectCompaniesFinancingsColumnWidths = (state: RootState) =>
  state.sourcing.singleCompanyDetails.financings.columnWidths;
export const selectFinancialsColumnWidths = (state: RootState) =>
  state.sourcing.singleCompanyDetails.financials.columnWidths;
export const selectFundraisingColumnWidths = (state: RootState) =>
  state.sourcing.singleCompanyDetails.fundraisingHistory.columnWidths;
export const selectCompanyIntelligence = (state: RootState) =>
  state.sourcing.intelligence;
export const selectKeyCustomersColumnWidths = (state: RootState) =>
  state.sourcing.singleCompanyDetails.keyCustomers.columnWidths;

export const selectActiveTab = (state: RootState) =>
  state.sourcing.companies.activeTab;
export const selectFinancingSyncedData = (state: RootState) => 
  state.sourcing.singleCompanyDetails.financingSyncedData;


export default sourcingSlice.reducer;
