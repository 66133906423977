import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getPeoplePriorities } from '../store/sourcing/sourcingThunks';

const usePeoplePriorities = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getPeoplePriorities());
    }, []);
}

export { usePeoplePriorities };