import React, {useCallback, useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';

import {  Button, makeStyles, TextField, Switch } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';

import ModalForm from '../../../components/ModalForm';
import { useNotification } from '../../../hooks/useNotification';
import { updatePeople, getPeople } from '../../../store/relationships/relationshipsThunks';

const useStyles = makeStyles((theme) => ({
    saveBtn: {
        color: '#ffffff',
        fontSize: '1rem',
        padding: '0.75rem 2rem',
        backgroundColor: '#6e3cfa',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: 'rgb(86, 47, 204)',
        },
    },
    textField: {
        marginTop: '2px',
        '& p': {
            fontSize: '10px',
            color: '#7a7b97',
            fontFamily: 'Quicksand',
            margin: 0,
        },
        '& input': {
            fontFamily: 'Quicksand'
        }
    }
}));

const ADD_FIELDS = [
    { name: 'Full Name', key: '@fullname', component: 'Input', type: 'text'},
    { name: 'Notes', key: '@notes', component: 'Input', type: 'text'},
    { name: 'Target Entrepreneur', key: '@targetentrepreneur', component: 'Input', type: 'boolean'},
    { name: 'BD Contact', key: '@bdcontact', component: 'Input', type: 'boolean'},
];

export default function EditPeopleModal (props) {
    const { isOpen, onClose, row, openNotification } = props;
    const classes = useStyles();
    const [editedData, setEditedData] = useState({});

    const dispatch = useDispatch();

    useEffect(() => {
        setEditedData(ADD_FIELDS.reduce((curr, item) => {
            curr[item.key] = row[item.name] || (item.type === 'boolean' ? false : '');
            return curr;
        }, {}));
    }, [row])


    const editData = useCallback((value, name) => {
        setEditedData((previous) => {
            previous[name] = value;
            return { ...previous };
        });
    }, []);


    const updateCompany = useCallback(async () => {
        await dispatch(updatePeople({...editedData,
            '@cinchyid': row['Cinchy Id']
        }));
        onClose();
        await dispatch(getPeople())
        openNotification("Row successfully updated")
    }, [editedData]);

    return (
        <>
            <ModalForm
                open={isOpen}
                handleClose={onClose}
                maxWidth="sm"
                fullWidth={true}
            >
                <div className="border-0">
                    <div className="card-body px-lg-5 py-lg-5">
                        <div className="text-center mb-4">
                            <h6>
                                Update Person
                            </h6>
                        </div>
                        <div>
                            {ADD_FIELDS.map(({ type, name, key  }) => (type === 'boolean' ?  <section key={key}>
                                <label className={`font-14 mr-3 mb-3`}>{name}</label>
                                    <Switch
                                        checked={editedData[key]}
                                        onClick={()=>editData(!editedData[key], key)}
                                        className="switch-small toggle-switch-primary ml-auto"
                                    />
                            </section> : <section key={key}>
                                    <label className={`font-14`}>{name}</label>
                                    <TextField
                                        className={classes.textField}
                                        variant="outlined"
                                        fullWidth
                                        label={null}
                                        margin="dense"
                                        type={type}
                                        value={editedData[key] || ''}
                                        onChange={(e) => editData(e.target.value, key)}
                                    />
                                </section>))
                            }
                        </div>
                    </div>
                </div>
                <div className="text-center pb-4">
                    <Button
                        variant="contained"
                        className={classes.saveBtn}
                        onClick={updateCompany}
                    >
                        <span className='font-12'>Update Person</span>
                    </Button>
                </div>
            </ModalForm>
        </>
    );
}