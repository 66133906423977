import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { Tooltip } from '@material-ui/core';

import { formatDate, formatPercentage, formatTotalRaised } from '../../utils/formatting';
import { formatEmptyCell } from '../../utils/utils';
import EditOpportunityModal from './EditOpportunityModal';
import { deleteOpportunity } from './api';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        cursor: 'pointer',
        flexDirection: 'column',
        margin: '16px',
        padding: '8px 16px',
        minHeight: '140px',
        borderRadius: '5px',
        backgroundColor: 'white',
        position: 'relative',
        '&:hover $actions': {
            opacity: 1,
            transform: 'translateX(0)',
        },
        boxShadow: '0px 1px 1px #091E4240, 0px 0px 1px #091E424F',
    },
    nameAndWebsite: {
        position: "relative",
        marginRight: '52px'
    },
    companyName: {
        cursor: 'pointer',
        display: 'block',
        fontSize: '14px',
        fontWeight: 'bold',
        "&:hover": {
            color: "rgb(86, 47, 204) !important",
        },
    },
    website: {
        color: 'grey',
        fontSize: '10px',
        "&:hover": {
            color: "rgb(86, 47, 204) !important",
        },
    },
    actions: {
        position: 'absolute',
        top: '1px',
        right: '-1px',
        opacity: 0,
        transition: 'opacity 0.3s ease, transform 0.3s ease',
        transform: 'translateX(10px)',
        display: 'flex',
    },
    actionIcon: {
        color: 'grey',
    },
    series: {
        display: 'flex',
        fontSize: '12px',
        justifyContent: 'space-between',
    },
    date: {
        fontSize: '10px',
    },
    owners: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    bold: {
        fontWeight: 'bold',
    },
    owner: {
        background: 'rgba(185, 179, 179, 0.25)',
        margin: '1px 3px 1px 0px',
        padding: '1px 3px',
        fontSize: '10px',
        borderRadius: '4px',
        width: 'fit-content',
        'textWrap': 'nowrap',
    },
    tContainer: {
        fontSize: '12px',
    },
    tHeader: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    tContent: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    tDivider: {
        borderBottom: '1px solid grey',
        margin: '4px 0',
    },
    cell: {
        margin: '2px',
    }

}));

export function Card({
    setColumns,
    id,
    Owners,
    status,
    Website,
    refreshData,
    onDragStart,
    triggerLoading,
    'Company Name': text,
    'FPC Investment ($M)': fpc,
    'Recent Outlook Date': date,
    'Company Cinchy Id': cinchyId,
    'Table Cinchy Id': tableCinchyId,
    ...card
}) {
    const classes = useStyles();
    const [isEditModelOpen, setIsEditModelOpen] = useState(false);
    const [isCardLoading, setIsCardLoading] = useState(false);
    const dispatch = useDispatch();


    useEffect(() => {
       setIsCardLoading(triggerLoading);
    }, [triggerLoading])


    const handleDragStart = (e) => {
        onDragStart(e);
        e.dataTransfer.setData('text/plain', id);
    };

    const handleOpenCompanyDetails = (companyId) => {
        window.open(`/sourcing/companies/details/${companyId}`, "_blank")
    };

    const handleDeleteItem = (tableCinchyId) => {
            setIsCardLoading(true);
            deleteOpportunity({ dispatch, tableCinchyId  }).then(res => {
                setColumns(prevColumns => Object.entries(prevColumns).reduce((newCols, [colId, cards]) => {
                        newCols[colId] = cards.filter(card => card.id !== tableCinchyId);
                        return newCols;
                    }, {}));
            })
    }

    const handleUpdateItem = (tableCinchyId) => {
        setIsEditModelOpen(true);
    }

    const ownersList = Owners?.split(';');

    return (
        <div
            id={id}
            className={classes.root}
            draggable="true"
            onDragStart={handleDragStart}
        >
            {isCardLoading ? <>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
            </> : <>
                <div className={classes.actions}>
                    <Tooltip title="Edit Item">
                        <IconButton size="small" aria-label="delete">
                            <EditRoundedIcon className={classes.actionIcon} fontSize='small' onClick={() => handleUpdateItem(tableCinchyId)} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Item">
                        <IconButton size="small" aria-label="delete">
                            <DeleteOutlineRoundedIcon className={classes.actionIcon} fontSize='small' onClick={() => handleDeleteItem(tableCinchyId)} />
                        </IconButton>
                    </Tooltip>
                </div>
                <div className={classes.nameAndWebsite}>
                    <div className={classes.companyName} onClick={() => handleOpenCompanyDetails(cinchyId)}>
                        {text}
                    </div>
                </div>
                <div>
                    <div className={classes.series}>
                        <span>{formatEmptyCell(formatTotalRaised(card['Series Size ($M)']))} </span>
                        <span>{card['Series']}</span>
                        {card['FPC Investment ($M)'] && <span>FPC: {formatEmptyCell(formatTotalRaised(card['FPC Investment ($M)']))}</span>}
                    </div>
                    {card['Pre-$ Valuation ($M)'] && <div className={classes.series}>
                        {formatEmptyCell(formatTotalRaised(card['Pre-$ Valuation ($M)']))} pre-$
                    </div>}
                    <div className={classes.owners}>
                        {ownersList?.map(item => <span className={classes.owner}>{item}</span>)}
                    </div>
                    <div className={classes.series}>
                        Previously Raised {formatEmptyCell(formatTotalRaised(card['Previously Raised']))}
                    </div>
                </div>
                <div className={classes.tContainer}>
                    <div className={classes.tHeader}>
                        <div className={classes.cell}>ARR</div>
                        <div className={classes.cell}>{formatEmptyCell(formatTotalRaised(card['ARR ($M)']))}</div>
                        <div className={classes.cell}>{formatPercentage(card['ARR Growth'])}</div>
                    </div>
                    <div className={classes.tDivider}></div>
                    <div className={classes.tContent}>
                        <div className={classes.cell}>HC&nbsp;</div>
                        <div className={classes.cell}>{formatEmptyCell(card['Headcount'])}</div>
                        <div className={classes.cell}>{formatPercentage(card['Headcount Growth'])}</div>
                    </div>
                </div>
                <div className={classes.date}>
                    Last Update: {formatEmptyCell(formatDate(date))}
                </div>
            </>}
            <EditOpportunityModal
                fieldsData={{
                    status,
                    tableCinchyId,
                    name: text,
                    series: card['Series'],
                    fpcInvestment: card['FPC Investment ($M)'],
                    seriesSize: card['Series Size ($M)'],
                    preValuation: card['Pre-$ Valuation ($M)'],
                }}
                refreshData={refreshData}
                isOpen={isEditModelOpen} onClose={() => setIsEditModelOpen(false)} />
        </div>
    );
}