// {columnName: "Cinchy Id", type: "Int64"}
// {columnName: "Full Name", type: "String"}
// {columnName: "Current Experiences", type: "String"}
// {columnName: "Past Experiences", type: "String"}
// {columnName: "FPC Contact", type: "String"}
// {columnName: "City", type: "String"}
// {columnName: "State", type: "String"}
// {columnName: "Stealth", type: "Boolean"}
// {columnName: "FPC Employee", type: "Boolean"}
// {columnName: "Person URL", type: "String"}

import { MultiselectFormik } from '../../components/MultiSelect/MultiSelect';
import { SingleSelectFormik } from "../../components/SingleSelect/SIngleSelect";
import FreeSearch from '../../components/OpenTextSearch/OpenTextSearch';
export const peopleFilterFields = [
    {
        name: 'Priority',
        label: 'Priority',
        component: SingleSelectFormik,
    },
    {
        name: 'Owners',
        label: 'Owners',
        component: MultiselectFormik,
    },
    {
        name: 'target entrepreneur',
        label: 'Target Entrepreneur',
        component: SingleSelectFormik,
    },
    {
        name: 'bd contact',
        label: 'BD Contact',
        component: SingleSelectFormik,
    },
    {
        name: 'Current Experiences',
        label: 'Current Experiences',
        component: FreeSearch,
    },
    {
        name: 'current co in database',
        label: 'Current Co in Database',
        component: SingleSelectFormik,
    },
]

export const PeopleStaticOptions = {
    'current co in database': new Set(['Yes', 'No']),
    'stealth': new Set(['Yes', 'No']),
    'target entrepreneur': new Set(['Yes', 'No']),
    'bd contact': new Set(['Yes', 'No']),
}