import { COLOR_CODES } from '../constants';

export const customStyles = {
    valueContainer: (provided) => ({
        ...provided,
        fontSize: 12,
    }),
    singleValue: (provided, state) => {
        const color = state?.options.find((item) => item.label === state?.data?.label)?.Color
        const hex = state?.options.find((item) => item.label === state?.data?.label)?.Hex
        return ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            '::before': {
                content: '" "',
                display: 'inline-block',
                marginRight: '10px',
                height: '10px',
                width: '10px',
                borderRadius: '50%',
                // @ts-ignore
                backgroundColor: hex ? `#${hex}` : `#${color}`  || 'grey',
            }
        })
    },
    option: (provided, state) => {
        return ({
            ...provided,
            fontSize: 12,
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            padding: '10px 15px',
            color: state.data.color,
            '::before': {
                content: '" "',
                display: 'inline-block',
                marginRight: '10px',
                height: '10px',
                width: '10px',
                borderRadius: '50%',
                // @ts-ignore
                backgroundColor: state?.data?.Hex ? `#${state?.data?.Hex}` : COLOR_CODES[state?.data?.Color] || 'grey',
            }

        })
    },
};