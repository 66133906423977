import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';

import {  Button, makeStyles, TextField, Switch } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';

import OwnersSearch from './OwnersSearch';
import PrioritySearch from './PrioritySearch';

import ModalForm from '../../../components/ModalForm';
import { insertPeople, getPeople } from '../../../store/relationships/relationshipsThunks';
import { useNotification } from '../../../hooks/useNotification';
import CompanyNameSelect from '../../../components/CreateCompanyModal/CompanyNameSelect';


const useStyles = makeStyles((theme) => ({
    saveBtn: {
        color: '#ffffff',
        fontSize: '1rem',
        padding: '0.75rem 2rem',
        backgroundColor: '#6e3cfa',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: 'rgb(86, 47, 204)',
        },
    },
    textField: {
        marginTop: '2px',
        '& p': {
            fontSize: '10px',
            color: '#7a7b97',
            fontFamily: 'Quicksand',
            margin: 0,
        },
        '& input': {
            fontFamily: 'Quicksand'
        }
    }
}));

const ADD_FIELDS = [
    { name: 'Full Name', key: '@fullname', component: 'Input', type: 'text'},
    { name: 'Owners', key: '@owner', component: 'Input', type: 'ownerSelect'},
    { name: 'Tracked Company', key: '@trackedcompany', component: 'Input', type: 'companySelect'},
    { name: 'Notes', key: '@notes', component: 'Input', type: 'text'},
    { name: 'Person Url', key: '@personurl', component: 'Input', type: 'text'},
    { name: 'Priority', key: '@priority', component: 'Input', type: 'prioritySelect'},
    { name: 'Target Entrepreneur', key: '@targetentrepreneur', component: 'Input', type: 'boolean'},
];

export default function InsertRowModal (props) {
    const { isOpen, onClose, openNotification } = props;
    const classes = useStyles();
    const [editedData, setEditedData] = useState({});
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [priorityOptions, setPriorityOptions] = useState([]);

    const dispatch = useDispatch();


    const editData = useCallback((value, name) => {
        setEditedData((previous) => {
            previous[name] = value;
            return { ...previous };
        });
    }, []);

    const editCompanyName = (option) => editData(option, '@trackedcompany')

    const insertRow = useCallback(async () => {
        await dispatch(insertPeople({...editedData, '@owner': selectedOptions.join(";"), '@priority': priorityOptions?.label || "", "@trackedcompany" : editedData["@trackedcompany"].split(" | ")[1]}));
        onClose();
        await dispatch(getPeople())
        openNotification("Row successfully added")
    }, [editedData]);

    return (
        <>
            <ModalForm
                open={isOpen}
                handleClose={onClose}
                maxWidth="sm"
                fullWidth={true}
            >
                <div className="border-0">
                    <div className="card-body px-lg-5 py-lg-5">
                        <div className="text-center mb-4">
                            <h6>
                                Add Person
                            </h6>
                        </div>
                        <div>
                            {ADD_FIELDS.map(({ type, name, key  }) => (type === 'boolean' ?  <section key={key}>
                                    <label className={`font-14 mr-3 mb-3`}>{name}</label>
                                    <Switch
                                        checked={editedData[key]}
                                        onClick={()=>editData(!editedData[key], key)}
                                        className="switch-small toggle-switch-primary ml-auto"
                                    />
                                </section> : type === 'ownerSelect' ? 
                                <section key={key}>
                                    <label className={`font-14`}>{name}</label>
                                    <OwnersSearch setSelectedValues={setSelectedOptions} selectedValues={selectedOptions} />
                                 </section>
                                    : type === 'prioritySelect' ? <section key={key}>
                                    <label className={`font-14`}>{name}</label>
                                    <PrioritySearch setSelectedValues={setPriorityOptions} selectedValues={priorityOptions}/>
                                 </section> : type === 'companySelect' ?  <section key={key}>
                                    <label className={`font-14`}>{name}</label><CompanyNameSelect setName={editCompanyName} insertPeople={true}/></section>: <section key={key}>
                                        <label className={`font-14`}>{name}</label>
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            fullWidth
                                            label={null}
                                            margin="dense"
                                            type={type}
                                            value={editedData[key] || ''}
                                            onChange={(e) => editData(e.target.value, key)}
                                        />
                                </section>))
                            }
                        </div>
                    </div>
                </div>
                <div className="text-center pb-4">
                    <Button
                        variant="contained"
                        className={classes.saveBtn}
                        onClick={insertRow}
                    >
                        <span className='font-12'>Insert Person</span>
                    </Button>
                </div>
            </ModalForm>
        </>
    );
}