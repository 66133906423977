import { getData } from '../../../api/sourcing';
import { QUERIES, STATUSES } from '../../../constants';

const getCircleBackNotes = async ({dispatch}) => {
    const { response, status} = await getData(QUERIES.CIRCLEBACK_NOTES.GET_NOTES, dispatch);
    if (status === STATUSES.SUCCESS) {
        return response
    }
}

const deleteCircleBackNote = async ({ dispatch, cinchyId }) => {
    const { status} = await getData(`${QUERIES.CIRCLEBACK_NOTES.DELETE}?%40cinchyid=${cinchyId}`, dispatch);
    if (status === STATUSES.SUCCESS) {
        return status;
    }
}

const updateMeetingTitle = async ({ dispatch, cinchyId, name }) => {
    const { status } = await getData(`${QUERIES.CIRCLEBACK_NOTES.UPDATE_MEETING_TITLE}?%40cinchyid=${cinchyId}&%40name=${name}`, dispatch);
    if (status === STATUSES.SUCCESS) {
        return status;
    }
}

const updateNotes = async ({ dispatch, cinchyId, notes }) => {
    const { status } = await getData(`${QUERIES.CIRCLEBACK_NOTES.UPDATE_NOTES}?%40cinchyid=${cinchyId}&%40notes=${notes}`, dispatch);
    if (status === STATUSES.SUCCESS) {
        return status;
    }
}

const updateCompanyName = async ({ dispatch, cinchyId, companyCinchyId }) => {
    const { status } = await getData(`${QUERIES.CIRCLEBACK_NOTES.UPDATE_COMPANY_NAME}?%40cinchyid=${cinchyId}&%40companycinchyid=${companyCinchyId}`, dispatch);
    if (status === STATUSES.SUCCESS) {
        return status;
    }
}


export { updateNotes, updateCompanyName, deleteCircleBackNote, getCircleBackNotes }
