import React from 'react';
import ModalForm from "../../../components/ModalForm";
import {Button, TextField} from "@material-ui/core";
import TextEditor from "../../../components/TextEditor/TextEditor";


function EdiNotesModal({ note, isOpen, handleClose, handleSave, setUpdatedNote, isSaveDisabled }) {
    return (
        <ModalForm
            open={isOpen}
            handleClose={handleClose}
            maxWidth="sm"
            fullWidth
        >
            <div className="border-0">
                <div className="card-body px-lg-5 py-lg-5">
                    <div className="text-center mb-4">
                        <h6>Edit Note</h6>
                    </div>
                    <div>
                        <section>
                            <label className={`font-14`}>Note</label>
                            {/*<TextField*/}
                            {/*    className={classes.textField}*/}
                            {/*    variant="outlined"*/}
                            {/*    fullWidth*/}
                            {/*    label={null}*/}
                            {/*    value={note}*/}
                            {/*    multiline*/}
                            {/*    margin="dense"*/}
                            {/*    helperText="Required field"*/}
                            {/*    onChange={(e) => setOutlookText(e.target.value)}*/}
                            {/*/>*/}
                            {/*<LivePreviewExample handleChange={handleSave} value={note} setValue={setUpdatedNote} />*/}
                            <TextEditor />
                        </section>
                    </div>
                </div>
            </div>
            <div className="text-center pb-4">
                <Button
                    variant="contained"
                    // className={classes.saveBtn}
                    onClick={handleSave}
                    // disabled={isSaveDisabled}
                >
                    <span className="font-12">Save</span>
                </Button>
            </div>
        </ModalForm>
    );
}

export default EdiNotesModal;