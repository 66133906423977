// @ts-nocheck
import React from "react";
// import ReactMarkdown from 'react-markdown';
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import { IOutlook} from "../../utils/dataGrouping";
import { deleteCompanyOutlook, getCompanyOutlook } from "../../store/sourcing/sourcingThunks";
import * as NotificationHelper from "../../helpers/notification";
import { selectLoading } from "../../store/sourcing/sourcingSlice";

import styles from "./styles.module.scss";
import { ArrowDropDown, ArrowDropUp, Delete } from "@material-ui/icons";
import { markdownToHTML } from "../../utils/markdownToHTML";
import { Button, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "../EditIcon/EditIcon";

type OutlookProps = {
  outlook: IOutlook;
  openOutlookModal: () => void;
};


const Outlook: React.FC<OutlookProps> = ({ outlook, openOutlookModal }) => {
  const dispatch = useDispatch();
  const [readAll, setReadAll] = React.useState(false);
  const isLoading = useSelector(selectLoading);

  const handleDeleteOutlook = async () => {
    await dispatch(deleteCompanyOutlook(outlook["Table Cinchy Id"]));
    dispatch(getCompanyOutlook(outlook["Company Cinchy Id"]));
    NotificationHelper.show("Company outlook successfully deleted", "success");
  };

  const handleReadAll = () => {
    setReadAll(!readAll);
  }

  return (
    <>
      <div className={styles.internalNotesCardContainer}>
        <div className={styles.singleSection}>
          <div className={styles.internalNotesTextSection}>
            <div className='relative'>
              <div className="text-black opacity-5 font-12">
                {moment(outlook.Date).format("MM/DD/YYYY")}
              </div>
              <div className={`font-12 ${styles.outlookAuthor}`} >{outlook.Author}</div>
                <div className={`${styles.markdown} font-12`} dangerouslySetInnerHTML={{ __html: markdownToHTML(outlook.Outlook.split('\n').slice(0, readAll ? undefined : 10).join('\n') + (!readAll && outlook.Outlook.split('\n').length > 10 ? '...' : '')) }} />
                <Button onClick={handleReadAll} id="blockElement">
                  <span className={`${styles.collapseBtn} font-12`}>
                        {readAll ? 'Collapse' : 'Expand'}
                  </span>
                  {readAll ?  <ArrowDropUp /> : <ArrowDropDown />}
                </Button>
            </div>
            <div className={styles.actionsContainer}>
              <div className="text-right">
                <IconButton
                    aria-label="eidt"
                    size="small"
                    onClick={openOutlookModal}
                >
                  <EditIcon fontSize='small' />
                </IconButton>
                <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={handleDeleteOutlook}
                >
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Outlook;
