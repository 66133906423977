import React, { ChangeEvent, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Grid, Card, Button, TextField, List, ListItem, ModalProps, Tooltip } from "@material-ui/core"
import InputAdornment from "@material-ui/core/InputAdornment";

import clsx from "clsx"
import { Delete } from "@material-ui/icons";
import SearchTwoToneIcon from "@material-ui/icons/SearchTwoTone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TagOption } from "../../../interfaces";
import { MoonLoader } from "react-spinners";
import { deleteTagOption, getTagOptions } from "../../../store/sourcing/sourcingThunks";
import * as NotificationHelper from '../../../helpers/notification';

import styles from '../styles.module.scss';

type ModalContentProps = {
  tags: TagOption[];
  handleSearch: (e: ChangeEvent<HTMLInputElement>) => void;
}

const ModalContent: React.FC<ModalContentProps> = ({ tags, handleSearch }) => {
  const [inputBg, setInputBg] = useState(false);
  const [isTagsListCollapsed, setIsTagsListCollapsed] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number | null>(null);
  const [isRequestPending, setIsRequestPending] = useState(false);

  const dispatch = useDispatch();

  const toggleInputBg = () => setInputBg(!inputBg);
  const toggleTagsList = () => setIsTagsListCollapsed(!isTagsListCollapsed);
  
  const handleDeleteTagOption = async (tagId: number) => {
    setIsRequestPending(true);
    await dispatch(deleteTagOption({ tagId }));
    await dispatch(getTagOptions());
    setIsRequestPending(false);
    NotificationHelper.show('Tag option successfully deleted', 'success');
  };

  // Pagination
  const itemsPerPage = 10;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const sortedTags = [...tags].sort((a, b) => Number(b["Cinchy Id"]) - Number(a["Cinchy Id"]));
  
  useEffect(() => {
    setTotalPages(Math.ceil(tags.length / itemsPerPage));
  }, [tags]);

  useEffect(() => {
    if (totalPages && currentPage > totalPages) {
      setCurrentPage(totalPages);
    }
  }, [totalPages]);


  return (
    <Card className="card-box w-100 mt-2">
        <>
          <div
            className={clsx(
              'd-flex transition-base align-items-center justify-content-between py-0 px-4',
              { 'bg-secondary': !inputBg }
            )}>
          </div>
          <div
            className={clsx(
              'd-flex align-items-center transition-base px-4 py-1',
              { 'py-3 bg-secondary': inputBg }
            )}>
            <div className="search-wrapper w-100">
              <TextField
                variant="outlined"
                size="small"
                className="bg-white w-100"
                classes={{ root: 'input-border-0' }}
                id="search-tag"
                placeholder="Search tags..."
                onFocus={toggleInputBg}
                onBlur={toggleInputBg}
                onChange={handleSearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchTwoToneIcon className={styles.searchIcon} />
                    </InputAdornment>
                  )
                }}
              />
            </div>
          </div>
          <div className="divider" />

          <List component="div" className="list-group-flush">

            {sortedTags.slice(startIndex, endIndex).map((tag, index) => {
              return (
                <ListItem
                  key={index}
                  button
                  disableRipple
                  onClick={(e: any) => e.preventDefault()}
                  className="d-flex bg-white justify-content-between align-items-center py-3"
                >
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="font-weight-bold font-size-sm text-black">
                        {tag.Tag}
                      </div>

                      <div className="d-flex align-items-center font-size-xs">
                        <Tooltip title="Tag Creator" arrow placement="top">
                          <div className={styles.badge}>
                            <div className="badge badge-first badge-circle border-white border-1 mr-2">
                              {tag["Tag Creator"]}
                            </div>
                            <div className="text-first">{tag["Tag Creator"]}</div>
                          </div>
                        </Tooltip>
                      </div>

                      <div className="d-flex align-items-center font-size-xs">
                      </div>
                    </div>
                  </div>

                  <div>
                    <Tooltip title="Delete Tag" arrow placement="top">
                      <Delete
                            className={styles.buttonClickable}
                            onClick={() => handleDeleteTagOption(tag["Cinchy Id"])}
                        ></Delete>
                    </Tooltip>
                  </div>

                </ListItem>
              );
            })}
          </List>

          {/* Pagination */}
          <div className={clsx("d-flex justify-content-between mt-3 mb-3", styles.pagination)}>
            <Button
              size="small"
              className="btn-primary px-3"
              disabled={currentPage === 1 ? true : false}
              onClick={() => setCurrentPage((prevPage) => prevPage - 1)}
            >
              <span className="btn-primary">
                <FontAwesomeIcon icon={['fas', 'arrow-left']} />
              </span>
            </Button>

            <div className={styles.currentPage}>
              {currentPage}
            </div>

            <Button
              size="small"
              className="btn-primary px-3"
              disabled={currentPage === totalPages ? true : false}
              onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
            >
              <span className="btn-wrapper--icon">
                <FontAwesomeIcon icon={['fas', 'arrow-right']} />
              </span>
            </Button>
          </div>
        </>


    </Card>

  )
}

export default ModalContent;
