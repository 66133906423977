import React from "react";
import OnwersInfo from "./OwnerInfo/OwnerInfo";
import DashboardInvestors from "./DashboardInvestors/DashboardInvestors";
import CategoryCoverage from "./CategoryCoverage/CategoryCoverage";
import ActiveCompanies from "./ActiveCompanies/ActiveCompanies";
import RecommendedBacklog from "./RecommendedBacklog/RecommendedBacklog";
import Charts from "../Charts/Charts";

export default function Forgepoint() {

  return (
    <>
      <h3 className="ml-3 mb-5">Forgepoint</h3>
      <OnwersInfo isFpc={true}/>
      <ActiveCompanies isFpc={true}/>
      <RecommendedBacklog isFpc={true}/>
      <CategoryCoverage isFpc={true}/>
      <DashboardInvestors isFpc={true}/>
      <Charts/>
    </>
  );
}
