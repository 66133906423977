import { CompanyFilterOptions, FilterHelpers, INullableCompanyFilters } from '../../../types/filter/filterByCompanies';
import { ICompany } from './types';
import { reShapeOwner } from '../../../utils/dataGrouping';

const filterByCompanyOwner = (rowItemValue: null | string, selectedFilterOptions: string[]) => {
    const rowOwners = reShapeOwner(rowItemValue || '');
    let options = new Set(selectedFilterOptions);
    // @ts-ignore
    return !!rowOwners?.filter?.(item => {
        return options.has(item.trim())
    })?.length
}

const openTextSearch = (rowItemValue: any, openText: string) => {
    return rowItemValue.Description?.includes(openText) || rowItemValue['Key Words']?.includes(openText);
}

const openSearch = (rowItemValue: any, openText: string) => {
    return rowItemValue.toLocaleLowerCase()?.includes(openText.toLocaleLowerCase())
}

const openTextSearchLastSequence = (rowItemValue: any, openText: string) => {
    return rowItemValue['Last Sequence Enrolled']?.toLocaleLowerCase().includes(openText.toLocaleLowerCase());
}

const filterByCompanyInvestor = (rowItemValue: null | string, selectedFilterOptions: string[]) => {
    const rowOwners = rowItemValue?.split(',').map(item => item.trim());
    let options = new Set(selectedFilterOptions);
    // @ts-ignore
    return !!rowOwners?.filter?.(item => {
        return options.has(item)
    })?.length
}

const filterByCompanyStatus = (rowItemValue: string, selectedFilterOptions: string[]) => {
    return !!selectedFilterOptions?.find(item => item.includes(rowItemValue));
}

const filterByCompanyRegion = (rowItemValue: string, selectedFilterOptions: string[]) => { // region, city
    return !!selectedFilterOptions?.find(item => item.includes(rowItemValue));
}

const filterByCompanyYearFounded = (rowItemValue: string, selectedFilterOptions: string[]) => { // region, city
    return !!selectedFilterOptions?.find(item => item === rowItemValue);
}

const NOT_SELECTED = 'N/A';
const filterByRange = (rowItemValue: number, selectedFilterOptions: any[]) => {
    const value =  Number(rowItemValue)
    console.log('Log ::: cocos ===', value, selectedFilterOptions);
    if(selectedFilterOptions[0] === NOT_SELECTED && selectedFilterOptions[1] === NOT_SELECTED) return true;
    if(selectedFilterOptions?.[0] === NOT_SELECTED) {
        return value < selectedFilterOptions?.[1]
    }
    if(selectedFilterOptions?.[1] === NOT_SELECTED) {
        return value > selectedFilterOptions?.[0]
    }
    if(selectedFilterOptions[0] === 0 && selectedFilterOptions[0] === 0) return true;
    return selectedFilterOptions[0] <= value && value <= selectedFilterOptions[1];
}

const filterByDate = (rowItemValue: Date, selectedFilterOptions: any[]) => {
    const value =  new Date(rowItemValue)
    if(selectedFilterOptions[0] === NOT_SELECTED && selectedFilterOptions[1] === NOT_SELECTED) {
        return true
    };
    if(selectedFilterOptions?.[0] === NOT_SELECTED) {
        return value < new Date(selectedFilterOptions?.[1])
    }
    if(selectedFilterOptions?.[1] === NOT_SELECTED) {
        return value > new Date(selectedFilterOptions?.[0])
    }
    if(new Date(selectedFilterOptions[0]).setHours(0,0,0,0) === new Date().setHours(0,0,0,0)
     && new Date(selectedFilterOptions[1]).setHours(0,0,0,0) === new Date().setHours(0,0,0,0))
     {
        return true;
     }
    return new Date(selectedFilterOptions[0]).setHours(0,0,0,0) <= value.setHours(0,0,0,0) && value.setHours(0,0,0,0) <= new Date(selectedFilterOptions[1]).setHours(0,0,0,0);
}

const filterByRangeM = (rowItemValue: number, selectedFilterOptions: any[]) => {
    if(!rowItemValue) return false;
    return filterByRange(Number((rowItemValue / 1e6).toFixed(1)), selectedFilterOptions);
}

const filterByOwner = (rowItemValue: null | string[], selectedFilterOptions: string[]) => {
    let options = new Set(selectedFilterOptions);
    return !!rowItemValue?.filter?.(item => {
        return options.has(item)
    })?.length
}

const filterByOwners = (rowItemValue: null | string, selectedFilterOptions: string[]) => {
    let options = new Set(selectedFilterOptions);
    console.log('Log ::: Owners ===', rowItemValue, selectedFilterOptions)
    if(!rowItemValue) return;

    return rowItemValue.split(';').some(item => options.has(item))
}

const filterByStage = (rowItemValue: null | string, selectedFilterOptions: string[]) => {
    let options = new Set(selectedFilterOptions);
    console.log('Log ::: Stage ===', rowItemValue, selectedFilterOptions)
    if(!rowItemValue) return;

    return rowItemValue.split(';').some(item => options.has(item))
}

const filterBySeries = (rowItemValue: null | string, selectedFilterOptions: string[]) => {
    let options = new Set(selectedFilterOptions);
    console.log('Log ::: Series ===', rowItemValue, selectedFilterOptions)
    if(!rowItemValue) return;

    return rowItemValue.split(';').some(item => options.has(item))
}

const filterByMPL = (rowItemValue: null | string, selectedFilterOptions: string[]) => {
    let options = new Set(selectedFilterOptions);
    console.log('Log ::: Miss / Pass / Lost ===', rowItemValue, selectedFilterOptions)
    if(!rowItemValue) return;

    return rowItemValue.split(';').some(item => options.has(item))
}

const filterByResearchOwner = (rowItemValue: null | string, selectedFilterOptions: string[]) => {
    let options = new Set(selectedFilterOptions);
    if(rowItemValue) {
        return !!rowItemValue.split(',').filter(item => options.has(item)).length
    }
}

const filterByResearchTitle = (rowItemValue: null | string, selectedFilterOptions: string[]) => {
    let options = new Set(selectedFilterOptions);
    if(rowItemValue) {
        return options.has(rowItemValue);
    }
}

const filterByCurrent = (rowItemValue: null | string, selectedFilterOptions: string[]) => {
    let options = new Set(selectedFilterOptions);
    if(rowItemValue) {
        return options.has(rowItemValue);
    }
}

const options = {
    Yes: true,
    No: false
}
const filterByResearchCurrent = (rowItemValue: boolean, selectedOption: 'Yes' | 'No') => {
    return options[selectedOption] === rowItemValue;
}

const filterByText = (rowItemValue: string, selectedOption: string) => {
    return selectedOption === rowItemValue;
}

const filterByStatus = (rowItemValue: string, selectedFilterOptions: string[]) => {
    console.log('Log ::: rowItemValue, selectedFilterOptions  status ===', rowItemValue, selectedFilterOptions)
    if(!rowItemValue) return;
    return selectedFilterOptions?.some(item => item.includes(rowItemValue) || rowItemValue.includes(item));
}

const filterByInvestor = (investorNames: string, selectedInvestors: string[]) => {
    console.log('Log ::: investor', investorNames, selectedInvestors );
    if(!investorNames) return;
    let options = new Set(selectedInvestors);
    return investorNames.split(',').some(item => options.has(item))
}

const rangeFilter = (rowItemValue: string, selectedFilterOptions: number[]) => {
    if(selectedFilterOptions[0] === 0 && selectedFilterOptions[0] === 0) return true;
    if(rowItemValue && selectedFilterOptions) {
        const value =  Number(rowItemValue)
        return selectedFilterOptions[0] <= value && value <= selectedFilterOptions[1]
    }
}

const taxonomyFilter = (rowItem: string, selectedTechnologies: string[]) => {
    const selectedTechnologiesSet = new Set(selectedTechnologies.map(item => item.toLowerCase()));
    const rowItemTechnologies = rowItem?.split(',');
    return rowItemTechnologies?.some(item => selectedTechnologiesSet.has(item.toLowerCase().trim()))
}

const taxonomyFilterNew = (rowItem: string, selectedTechnologies: string[]) => {
    const selectedTechnologiesSet = selectedTechnologies
        ?.map(item => item?.toLowerCase()?.split(' | ')).flat();
    // const rowItemTechnologies = rowItem?.split(',');
    console.log('Log ::: row item value ===', rowItem, selectedTechnologiesSet);
    return selectedTechnologiesSet?.some(technologyItem => (rowItem?.toLowerCase()?.includes(technologyItem) || technologyItem?.includes(rowItem?.toLowerCase())))
}

const companyNamesFilter = (rowItem: string, selectedCompanyNames: string[]) => {
    const selectedCompanyNamesSet = new Set(selectedCompanyNames.map(item => item.toLowerCase()));
    return selectedCompanyNamesSet.has(rowItem?.toLowerCase());
}

const checkInSequence = (rowItemValue: string, selectedFilterOption: string) => {
    const sequence = {
        'Yes': true,
        'No': false,
    }
    // @ts-ignore
    return sequence[selectedFilterOption as keyof sequence] === rowItemValue;
}

const checkHelpers = {
//Financing filters
date: filterByDate,
// 'Investors': filterByFinancingInvestor,
owners: filterByOwners,
series: filterBySeries,
stage: filterByStage,
'miss / pass / lost': filterByMPL,
category: filterByInvestor,
company: filterByInvestor,
// 'region':  filterByMPL,
//People filters
'city': filterByResearchTitle,
'current': filterByResearchCurrent,
'stealth': filterByText,
'target entrepreneur': filterByResearchCurrent,
'bd contact': filterByResearchCurrent,
'priority': filterByText,
'fpc contact': filterByResearchOwner,
'current co in database': filterByText,
'full name': filterByResearchTitle,
'tracked company': filterByResearchTitle,
'past experiences': openSearch,
'current experiences': openSearch,
'person url': filterByResearchTitle,
'state': filterByResearchTitle,

 //Customers Filters
// 'arr': rangeFilter,
'champion title': filterByResearchTitle,
// 'company name': filterByResearchTitle,
'company status': filterByResearchTitle,
'customer name': filterByResearchTitle,
'replaced if rip and replace': filterByResearchTitle,
// competitors if competitive process:null
// 'customer linkedin id': "https://www.linkedin.com/company/bank-of-jerusalem/"
// sub category:"xxx"
// table cinchy id:35
// category:"xxx"
// technology:"xxx"
// use case:null

 //Research Filters
'Companies': () => null,
'Current':  filterByResearchCurrent,
'Owner':  filterByResearchOwner,
'Technology': taxonomyFilterNew,
'Title': filterByResearchTitle,
'Type': filterByResearchTitle,


// Company Names
'company name':  companyNamesFilter,
// Taxonomy
technology: taxonomyFilterNew,
//CRM
owner: filterByOwner,
status: filterByStatus,
'months since last contact': rangeFilter,
'months since last sequence': rangeFilter,
'last sequence enrolled': rangeFilter,
'in sequence?': checkInSequence,

// lastSequenceEnrolled: any;  todo ok
// monthsSinceLastSequence: any;  todo ok

// Fundrising
headcount: rangeFilter,
'headcount growth': rangeFilter,
region: filterByInvestor,
'year founded': rangeFilter,

// Fundraising
'total raised': rangeFilter,
'months since last funding': rangeFilter,
'recent valuation': rangeFilter,
'total raised / hedcount': rangeFilter,
'total raised / last valuation': rangeFilter,

//Investors
investors: filterByInvestor,
'relationships': openSearch,
'key investors': filterByInvestor,
'key people': filterByInvestor,
'has fpc relationship': filterByText,
'has target entrepreneurs': filterByText,

//Financials
'customer count': rangeFilter,
arr: rangeFilter,
// 'arr / headcount': rangeFilter,
'saas metrics as of date': rangeFilter,
cash: rangeFilter,
'cash burned': rangeFilter,
}

const companyKeyFilters = {
    'company name':  companyNamesFilter,
    // Taxonomy
    technology: taxonomyFilterNew,
    'key words': openTextSearch,
    // CRM
    'in sequence?': checkInSequence,
    owner:  filterByCompanyOwner,
    sectors:  filterByCompanyOwner,
    status: filterByCompanyStatus,
    'last contact date': filterByDate,
    'last meeting date': filterByDate,
    'next meeting date': filterByDate,
    'last sequence enrolled': openTextSearchLastSequence,
    //DEMOGRAPHICS
    region: filterByCompanyRegion,
    city: filterByCompanyRegion,
    'year founded': filterByRange,
    headcount: filterByRange,
    'headcount growth': filterByRange,
    //Financing
    'date': filterByDate,
    'total raised ($m)': filterByRangeM,
    'recent valuation ($m)': filterByRangeM,
    'recent investment stage': filterByCompanyOwner,
    'months since last funding': filterByRange,
    'ownership status': filterByCompanyStatus,
    'total raised / last valuation': filterByRange,
    'total raised / hedcount': filterByRange,
    //Inversotrs
    'investors': filterByCompanyInvestor,
    'relationships': openSearch,
    'has fpc relationship': filterByText,
    'has target entrepreneurs': filterByText,
    // Financials
    'customer count': filterByRange,
    'arr ($m)': filterByRangeM,
    'arr / headcount': filterByRange,
    'saas metrics as of date': filterByRange,
    //other
    tags: filterByCompanyInvestor,

}

type FilterWithNullable = {
    [key in keyof Partial<CompanyFilterOptions>]: { value: any; nullable: boolean}
}

const extractNullableFilters = (filterOptions: Partial<CompanyFilterOptions & INullableCompanyFilters>) => {
    const optionsEntries = Object.entries(filterOptions);
    return  optionsEntries.reduce((acc, [option, value]) => {
            if(option.includes('nullable_')) {
                // @ts-ignore
                acc.nullableFilters[option] = value;
            }
            else {
                // @ts-ignore
                acc.filters[option] = value;
            }

            return acc;
        },
        {
            filters: {},
            nullableFilters: {}
        })
}
export const getFilterWithINullable = (filterOptions: Partial<CompanyFilterOptions & INullableCompanyFilters>): FilterWithNullable => {
    const { filters, nullableFilters } = extractNullableFilters(filterOptions);

    const finalFilterOptions =   Object.entries(filters).reduce((acc, [option, value]) => {
        const nullableProperty = `nullable_${option}` as keyof typeof nullableFilters;
        const nullable = !!nullableFilters[nullableProperty]
        acc[option as keyof typeof acc] = { value, nullable };

        if(nullableProperty in nullableFilters) {
            delete nullableFilters[nullableProperty];
        }

        return acc;
    }, {} as Record<string, { value: any; nullable: boolean }>);

    Object.entries(nullableFilters).forEach(([option, value]) => {
        const initialProperty = option.replace('nullable_', '') as keyof typeof finalFilterOptions;
        finalFilterOptions[initialProperty] = { value: FilterHelpers.MISSING_MAIN_FILTER, nullable: !!value };
    });

    return finalFilterOptions;
}

export function capitalizeFirstLetter(string: string): keyof CompanyFilterOptions {
     if (string === 'current co in database') {
        return 'Current Co in Database' as keyof CompanyFilterOptions
    }

    if (string === 'bd contact') {
        return 'BD Contact' as keyof CompanyFilterOptions
    }
    return string.split(' ').map(subString => subString.charAt(0).toUpperCase() + subString.slice(1)).join(' ') as keyof CompanyFilterOptions;
}

const rowColumns = ['Key Words', 'Last Sequence Enrolled'];
const RELATIONSHIOP_TYPES = {
    INVESTORRELATIONSHIPS: 'Investor Relationships',
    COMPANYRELATIONSHIPS: 'Company Relationships',
    CUSTOMERRELATIONSHIPS: 'Customer Relationships',
}
const checkRowByFilterOptions = (filterOptions: CompanyFilterOptions, row: Partial<ICompany>, callbacksObjectType: string) => {
    const filters = getFilterWithINullable(filterOptions);

    const filterEntries = Object.entries(filters);
    const callbacksObject = callbacksObjectType ? companyKeyFilters : checkHelpers ;
    for(let i = 0; i < filterEntries.length; ++i) {
        // @ts-ignore
        const [option, { value, nullable }] = filterEntries[i];
        let fieldValue =  rowColumns.includes(capitalizeFirstLetter(option)) ? row :  row[capitalizeFirstLetter(option) as keyof typeof row];
        const nullableCheck = nullable && fieldValue === null;

        if(value === FilterHelpers.MISSING_MAIN_FILTER && nullableCheck) {
            continue;
        }

        // @ts-ignore
        let optionCheck = callbacksObject?.[option.toLowerCase() as keyof typeof callbacksObject]?.(fieldValue, value)
        if(option === "Relationships") {
            // @ts-ignore
            optionCheck = callbacksObject?.[option.toLowerCase() as keyof typeof callbacksObject]?.(row[RELATIONSHIOP_TYPES.INVESTORRELATIONSHIPS], value) || callbacksObject?.[option.toLowerCase() as keyof typeof callbacksObject]?.(row[RELATIONSHIOP_TYPES.COMPANYRELATIONSHIPS], value) || callbacksObject?.[option.toLowerCase() as keyof typeof callbacksObject]?.(row[RELATIONSHIOP_TYPES.CUSTOMERRELATIONSHIPS], value)
        }
        if (!(optionCheck || nullableCheck)) {
            return false;
        }
    }
    return true;
}

export const filterCompany = (filterOptions: CompanyFilterOptions, rows: Array<Partial<ICompany>>, callbacksObjectType: string) => {
    return rows.filter((row) => checkRowByFilterOptions(filterOptions, row, callbacksObjectType))
}