
import React, {useState} from 'react';
import { Button, makeStyles, TextField } from '@material-ui/core';
import ModalForm from '../ModalForm';
import CompanyNameSelect from './CompanyNameSelect';
import OpportunityStatusSelect from './OpportunityStatusSelect';
import { useCompanyNameOptions } from "../../hooks/useCompanyNameOptions";

const useStyles = makeStyles((theme) => ({
    saveBtn: {
        color: '#ffffff',
        fontSize: '1rem',
        padding: '0.75rem 2rem',
        backgroundColor: '#6e3cfa',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: 'rgb(86, 47, 204)',
        },
    },
    textField: {
        marginTop: '2px',
        '& p': {
            fontSize: '10px',
            color: '#7a7b97',
            fontFamily: 'Quicksand',
            margin: 0,
        },
        '& input': {
            fontFamily: 'Quicksand'
        }
    }
}));

const ADD_FIELDS = [
    { name: 'Company Name', key: '@companyname', Component: CompanyNameSelect },
    { name: 'Series', key: '@series', component: 'Input', type: TextField},
    { name: 'Opportunity Status', key: '@status', component: 'Input', type: 'singleSelect'},
    { name: 'Series Size', key: '@roundsize', component: 'Input', type: 'number'},
    { name: 'FPC Invested ($M)', key: '@fpcinvestment', component: 'Input', type: 'number'},
    { name: 'Total Raised ($M)', key: '@valuation', component: 'Input', type: 'number'},
];

function AddOpportunityModal({ isOpen, onClose, onAdd, selectedStatus }) {
    const classes = useStyles();
    const [companyName, setCompanyName] = useState('');
    const [series, setSeries] = useState('');
    const [status, setOpportunityStatus] = useState(selectedStatus);
    const [fpcInvestment, setFpcInvestment] = useState(null);
    const [roundSize, setRoundSize] = useState(null);
    const [valuation, setValuation] = useState(null);

    const options = useCompanyNameOptions();


    const addCompany = () => {
        onAdd({ companyName, series, status, fpcInvestment, roundSize, valuation });
    }

    return (
        <>
            <ModalForm
                open={isOpen}
                handleClose={onClose}
                maxWidth="sm"
                fullWidth={true}
            >
                <div className="border-0">
                    <div className="card-body px-lg-5 py-lg-5">
                        <div className="text-center mb-4">
                            <h6>
                                Add opportunity
                            </h6>
                        </div>
                        <div>
                            <label className={`font-14`}>Company Name</label>
                            <CompanyNameSelect options={options} setName={setCompanyName} />

                            <label className={`font-14`}>Series</label>
                            <TextField
                                type="text"
                                label={null}
                                className={classes.inputField}
                                variant="outlined"
                                fullWidth
                                margin="dense"
                                value={series}
                                onChange={(e) => setSeries(e.target.value)}
                            />

                            <label className={`font-14`}>Opportunity Status</label>
                            <OpportunityStatusSelect setName={setOpportunityStatus} selectedStatus={selectedStatus} />

                            <label className={`font-14`}>Series Size</label>
                            <TextField
                                type="number"
                                placeholder="$"
                                className={classes.inputField}
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                label={null}
                                value={roundSize}
                                onChange={(e) => setRoundSize(e.target.value)}
                            />

                            <label className={`font-14`}>FPC Investment</label>
                            <TextField
                                type="number"
                                placeholder="$"
                                className={classes.inputField}
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                label={null}
                                value={fpcInvestment}
                                onChange={(e) => setFpcInvestment(e.target.value)}
                            />

                            <label className={`font-14`}>Pre-$ Valuation</label>
                            <TextField
                                type="number"
                                placeholder="$"
                                className={classes.inputField}
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                label={null}
                                value={valuation}
                                onChange={(e) => setValuation(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="text-center pb-4">
                    <Button
                        variant="contained"
                        disabled={!companyName}
                        className={classes.saveBtn}
                        onClick={addCompany}
                    >
                        <span className='font-12'>Add Opportunity</span>
                    </Button>
                </div>
            </ModalForm>
        </>
    );
}

export default AddOpportunityModal;