import React from 'react';
import { StatusSelect } from '../SingleSelect/SIngleSelect';


function CompanyNameSelect({ options, setName, defaultValue = '' }) {

    return (
        <>
            <StatusSelect withoutColor defaultValue={{ label: defaultValue, value: defaultValue }} name='Company name' options={options} onChange={setName} />
        </>
    );
}

export default CompanyNameSelect;