import React from "react";
import styles from "./styles.module.scss";
import HeadcountHistory from "../HeadcountHistory";

const OverviewDemographics = ({
  headcountHistory,
  handleRefresh,
  companyId,
}: any) => {
  let filteredHeadcountData = headcountHistory.map(
    (headcount: any, index: number) => {
      return {
        TableId: headcount["Table Cinchy Id"],
        Date: headcount.Date,
        Headcount: headcount.Headcount,
      };
    }
  );

  return (
    <section className={styles.demographics}>
      <div className={styles.container}>
        <div className={`${styles.headcountTable}`}>
          <HeadcountHistory
            tableData={filteredHeadcountData}
            companyId={companyId}
            handleRefresh={handleRefresh}
          ></HeadcountHistory>
        </div>
      </div>
    </section>
  );
};

export default OverviewDemographics;
