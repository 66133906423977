import React, { FunctionComponent } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ForgePontTabs from "../../components/Tabs";
import { makeStyles } from "@material-ui/core/styles";
import Data from "./Main/Data";
import Forgepoint from "./Main/Forgepoint";
import UniversalSearch from "../../components/UniversalSearch";

interface ISourcingProps {
  loading: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  border0: {
    borderRadius: 0,
  },
}));

const Sourcing: FunctionComponent<ISourcingProps> = ({ loading }) => {

  return (
    <>
        <ForgePontTabs mainPath="dashboard">
            <Switch>
            <Route path="/dashboard/personal" component={Data} />
            <Route path="/dashboard/forgepoint" component={Forgepoint} />
            <Route path="/dashboard">
                    <Redirect to="/dashboard/personal" />
                </Route>
            </Switch>
        </ForgePontTabs>
    </>
  );
};
export default Sourcing;
