import React, { useState, useCallback, useEffect } from "react";
import { Table, Switch } from "@material-ui/core";
import { getFinancingSyncedData, getFundraisingColumnWidths, syncFinancingData } from "../../store/sourcing/sourcingThunks";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "../../example-components/FormsDatepicker/FormsDatepicker1";
import styles from "./styles.module.scss";
import { selectFundraisingColumnWidths } from "../../store/sourcing/sourcingSlice";
import { formatColumnFormats, formatContent, parseDate } from "../../utils/formatting";
import { selectFinancingSyncedData } from "../../store/sourcing/sourcingSlice";

interface FundraisingHistoryProps {
  part1: any;
  part2: any;
  companyId: number
}

const FundraisingHistory = ({ part1, part2, companyId }: FundraisingHistoryProps) => {
  const dispatch = useDispatch();
  const [rowsFirst, setRowsFirst] = useState([] as any);
  const [headersFirst, setHeadersFirst] = useState([] as any);
  const [rowsSecond, setRowsSecond] = useState([] as any);
  const [headersSecond, setHeadersSecond] = useState([] as any);
  const [isEditable, setIsEditable] = useState(false);
  const [columnFormatValues, setColumnFormatValues] = useState([]);
  let columnWidths = useSelector(selectFundraisingColumnWidths);
  // const [pitchbookId, setPitchbookId] = useState("");
  // const [firstRowUpdate, setFirstRowUpdate] = useState([] as any);
  // const [secondRowUpdate, setSecondRowUpdate] = useState([] as any);

  let syncedData = useSelector(selectFinancingSyncedData);
  const [isFinancingSynced, setIsFinancingSynced] = useState(false);

  useEffect(() => {
    dispatch(getFinancingSyncedData(companyId));
    dispatch(getFundraisingColumnWidths());
  }, []);

  useEffect(() => {
    if (columnWidths?.rows?.length) {
      setColumnFormatValues(formatColumnFormats(columnWidths.rows));
    }
  }, [columnWidths]);

  useEffect(() => {
    let tableHeaders = part1.columns;
    if (part1?.data?.length) {
      tableHeaders = Object.keys(part1.data[0]);
      setRowsFirst(
        Object.values(part1.data[0]).map((v: any, i: number) => {
          if (tableHeaders[i] === "Recent Financing Date") {
            return parseDate(v);
          }
          return v;
        })
      );
    }
    if(tableHeaders?.length) {
      setHeadersFirst(tableHeaders);
    }
  }, [part1]);

  useEffect(() => {
    let tableHeaders = part2.columns;
    if (part2?.data?.length) {
      tableHeaders = Object.keys(part2.data[0]);
      setRowsSecond(
        Object.values(part2.data[0]).map((v: any, i: number) => {
          if (tableHeaders[i] === "LK Valuation Date") {
            return parseDate(v);
          }
          return v;
        })
      );
    }
    if(tableHeaders?.length) {
      setHeadersSecond(tableHeaders);
    }    
  }, [part2]);

  // const toggleEditable = useCallback(() => {
  //   setIsEditable(!isEditable);
  //   setFirstRowUpdate(rowsFirst.map((r: string) => r));
  //   setSecondRowUpdate(rowsSecond.map((r: string) => r));
  // }, [rowsFirst, rowsSecond, isEditable]);

  // const save = () => {
  //   dispatch(
  //     updateFundraisingHistory({
  //       pitchbookId: pitchbookId,
  //       recentFinancing: rowsFirst[0],
  //       date: rowsFirst[1],
  //       amount: rowsFirst[2],
  //       postValuation: rowsFirst[3],
  //       totalRaised: rowsSecond[0],
  //       lastValuation: rowsSecond[1],
  //       lastValuationDate: rowsSecond[2],
  //     })
  //   );
  //   toggleEditable();
  // };

  // const cancel = useCallback(() => {
  //   setRowsFirst(firstRowUpdate);
  //   setRowsSecond(secondRowUpdate);
  //   toggleEditable();
  // }, [firstRowUpdate, secondRowUpdate]);

  const handleUpdateChangeFirst = useCallback(
    (value: string, index: number) => {
      setRowsFirst((previous: any) => {
        if (typeof value === "object") {
          previous[index] = parseDate(value);
        } else {
          previous[index] = value;
        }
        return [...previous];
      });
    },
    []
  );

  const handleUpdateChangeSecond = useCallback((value: any, index: number) => {
    setRowsSecond((previous: any) => {
      if (typeof value === "object") {
        previous[index] = parseDate(value);
      } else {
        previous[index] = value;
      }
      return [...previous];
    });
  }, []);

  const handleSyncWithCompanies = useCallback(
    async () => {
        await dispatch(syncFinancingData(companyId))
        setIsFinancingSynced(true)
    },
    [dispatch]
  );

  return (
    <>
      <section className={styles.headerSection}>
        <p className={`${styles.fundraisingText} font-14 font-weight-bold`}>
          Fundraising History
        </p>
        {syncedData.length && <div className={styles.syncContainer}>
                <div className={styles.syncLabelContainer}><label className="font-12">Sync Most Recent Financing</label>
                <label className='font-12'>Last sync {parseDate(syncedData?.[0]?.["Last Sync"])}</label></div>
                <div className={styles.syncToggleContainer}>
                <Switch
                    checked={isFinancingSynced}
                    onClick={handleSyncWithCompanies}
                    disabled={isFinancingSynced}                            
                    className="switch-small toggle-switch-primary ml-auto"
                />
                </div>
                
        </div>}
        {/* TODO: Remove Update Code Later */}
        {/* {!isEditable && (
          <img
            onClick={toggleEditable}
            className={styles.editButton}
            rgba(110, 60, 250, 0.05)
            alt="Company Logo"
            id="blockElement"
          />
        )}
        {isEditable && (
          <Button
            size="small"
            id="blockElement"
            className={`${"btn-neutral-primary"} mb-2`}
          >
            <Done onClick={save} />
          </Button>
        )}
        {isEditable && (
          <Button
            size="small"
            id="blockElement"
            className={`${"btn-neutral-primary"} mb-2 ml-2`}
          >
            <Close onClick={cancel} />
          </Button>
        )} */}
      </section>
      <section className={styles.fundraisingSection}>
        <div className="table-responsive-md mb-spacing-2-x2">
          <Table
            className={`table table-hover table-striped table-bordered ${styles.table}`}
          >
            <thead className={styles.tableHead}>
              <tr className="font-12">
                {headersFirst?.map((header: string, index: number) => (
                  <th key={index}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr className="font-12">
                {columnFormatValues?.length && rowsFirst?.map((row: string, index: number) =>
                  !isEditable ? (
                    <td key={index}>
                        {formatContent(
                              rowsFirst,
                              row,
                              headersFirst?.[index],
                              columnFormatValues
                            )}
                    </td>
                  ) : (
                    <td key={index}>
                      {headersFirst[index] !== "Date" ? (
                        <input
                          type="text"
                          value={row}
                          className={styles.input}
                          onChange={(e) =>
                            handleUpdateChangeFirst(e.target.value, index)
                          }
                        />
                      ) : (
                        <DatePicker
                          handleSelectDate={(date: any) =>
                            handleUpdateChangeFirst(date, index)
                          }
                          selectedDate={row}
                        ></DatePicker>
                      )}
                    </td>
                  )
                )}
              </tr>
            </tbody>
          </Table>
        </div>
        <div className="table-responsive-md mb-spacing-2-x2">
          <Table
            className={`table table-hover table-striped table-bordered ${styles.table}`}
          >
            <thead className={styles.tableHead}>
              <tr className="font-12">
                {headersSecond?.map((header: string, index: number) => (
                  <th key={index}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr className="font-12">
                {columnFormatValues?.length && rowsSecond?.map((row: string, index: number) =>
                  !isEditable ? (
                    <td key={index}>
                        {formatContent(
                              rowsSecond,
                              row,
                              headersSecond?.[index],
                              columnFormatValues
                            )}
                    </td>
                  ) : (
                    <td key={index}>
                      {headersSecond[index] !== "LK Valuation Date" ? (
                        <input
                          type="text"
                          value={row}
                          className={styles.input}
                          onChange={(e) =>
                            handleUpdateChangeSecond(e.target.value, index)
                          }
                        />
                      ) : (
                        <DatePicker
                          handleSelectDate={(date: any) =>
                            handleUpdateChangeSecond(date, index)
                          }
                          selectedDate={row}
                        ></DatePicker>
                      )}
                    </td>
                  )
                )}
              </tr>
            </tbody>
          </Table>
        </div>
      </section>
    </>
  );
};

export default FundraisingHistory;
