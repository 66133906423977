import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import GenericTable from "../../../../components/GenericTable";
import TSkeleton from "../../../../components/TSkeleton";
import { selectActiveCompanies, selectFPCActiveCompanies } from "../../../../store/dashboard/dashboardSlice";
import { getActiveCompanies, getFPCActiveCompanies } from "../../../../store/dashboard/dashboardThunks";

import styles from "./styles.module.scss";
import usePagination from "../../../../hooks/usePagination";
import Pagination from "../../../../components/Pagination/Pagination";
import { defaultColumn } from "../../../Sourcing/utils";
import { formatEmptyCell } from "../../../../utils/utils";
import { formatInvestors } from "../../../../utils/formatting";
import StatusSelect from "../../../../components/StatusSelect";
import { useStatuses } from "../../../../hooks/useStatus";
import { useFPCIPriorities } from "../../../../hooks/useFPCIPriorities";
import FPCIPriorities from "../../../../components/FPCIPrio";
import OwnersSelect from "../../../../components/OwnersSelect";
import { useOwners } from "../../../../hooks/useOwners";

const fixedWidthColumns = ["Status", "Priority"]

export default function ActiveCompanies({isFpc} : any) {
  const dispatch = useDispatch();
  const { rows, cells, isLoading } = useSelector(isFpc ? selectFPCActiveCompanies : selectActiveCompanies);

  useEffect(() => {
    if(isFpc) {
      dispatch(getFPCActiveCompanies());
    } else {
      dispatch(getActiveCompanies());
    }
  }, [isFpc]);

  useStatuses();
  useFPCIPriorities();
  useOwners();

  const getCellStyle = (cell: any, index: number) => {
    return cell === "Coverage %"
      ? {
          color: `#${
            rows[(currentPage - 1) * 5 + index]["Coverage % Text Color"]
          }`,
        }
      : defaultColumn;
  };

  const getHeadCellStyle = (cell: any) =>  ({
            minWidth: fixedWidthColumns.includes(cell) && "300px",
  })

  const handleOpenCompanyDetails = (companyId: string) => {
    window.open(`/sourcing/companies/details/${companyId}`, "_blank");
  };

  const handleCompanyWebsiteSplit = useCallback((value, id) => {
    const [companyName, website] = value.split(" | ");
    return (
      <div className="mr-5">
        <span
          className={styles.hoverBlack}
          onClick={() => handleOpenCompanyDetails(id)}
          style={{
            cursor: "pointer",
            color: "black",
            display: "block",
            fontSize: "14px",
            fontWeight: "bold",
          }}
        >
          {companyName}
        </span>
        <span style={{ cursor: "pointer", color: "black" }}>
          <a
            href={website?.startsWith("http") ? website : `http://${website}`}
            target="_blank"
            className={styles.hoverBlack}
            style={{ color: "grey", fontSize: "12px" }}
          >
            {website}
          </a>
        </span>
      </div>
    );
  }, []);

  const renderTCell = useCallback((column, row) => {
    const defaultValue = formatEmptyCell(row[column]);
    switch (column) {
      case "Company Name":
        return (
          <div className="d-flex align-items-center relative pr-1">
            {handleCompanyWebsiteSplit(
              formatEmptyCell(row[column]),
              row["Cinchy Id"]
            )}
            <div className={styles.boxContainer}>
              <div
                style={{
                  background: `#${row["Company Name2"].split("|")[1].trim()}`,
                }}
                className={styles.fpcScoreBoxContainer}
              >
                <span className={styles.fpcScore} style={{ fontSize: "12px" }}>
                  {row["Company Name2"].split("|")[0]}
                </span>
              </div>
              <div
                className={styles.flagColor}
                style={{
                  background: `#${row["Company Name2"].split("|")[2].trim()}`,
                }}
              ></div>
            </div>
          </div>
        );
      case "Technology":
        return formatInvestors(row[column], styles["highlight-technology"]);
      case "Status":
        return (
          <StatusSelect
            cinchyId={row["Cinchy Id"]}
            defaultValue={{ label: row[column], value: row[column] }}
            name=""
          />
        );
      case "Priority":
        return (
          <FPCIPriorities
            key={row["Cinchy Id"]}
            cinchyId={row["Cinchy Id"]}
            defaultValue={{ label: row[column], value: row[column] }}
            name=""
          />
        );
      case "Owners":
        const refactoredData = row[column] ? row[column].split(";") : ""
        return (
          <OwnersSelect
            key={row["Cinchy Id"] || row["cinchy id"]}
            affinityId={row["Cinchy Id"] || row["cinchy id"]}
            ownersInfo={refactoredData}
          ></OwnersSelect>
        );
      default:
        return defaultValue;
    }
  }, []);

  const {
    currentPage,
    goToPageValue,
    goToPage,
    totalPages,
    handleRowsPerPageChange,
    handleGoToPageChange,
  } = usePagination({ rows, rowsPerPageValue: 5 });

  const rowsToDraw = useMemo(
    () => rows?.slice((currentPage - 1) * 5, (currentPage - 1) * 5 + 5),
    [rows, currentPage]
  );

  return (
    <>
      {isLoading ? (
        <TSkeleton />
      ) : (
        <div
          className={`table-responsive-md mb-spacing-2-x2 ml-3 mr-3`}
        >
          <GenericTable
            title={`Active Companies (${rows?.length || 0} results)`}
            rows={rowsToDraw}
            fixedColumnHint=""
            headCells={cells?.filter(
              (_: any, index: number) => index !== 0 && index !== 2
            )}
            tableActions={null}
            getHeadCellStyle={getHeadCellStyle}
            getCellStyle={getCellStyle}
            renderCell={renderTCell}
            getActionsCell={null}
            searchBar={null}
            scrollable={true}
          >
            <Pagination
              goToPage={goToPage}
              rowsPerPage={5}
              currentPage={currentPage}
              goToPageValue={goToPageValue}
              totalPages={totalPages}
              handleRowsPerPageChange={handleRowsPerPageChange}
              handleGoToPageChange={handleGoToPageChange}
            />
          </GenericTable>
        </div>
      )}
    </>
  );
}
