import React, { useEffect } from "react";

import TroubleBreakin from "../Main/TroubleBreakingIn/TroubleBreakingIn";
import { useDispatch, useSelector } from "react-redux";
import OnwersInfo from "./OwnerInfo/OwnerInfo";
import DashboardInvestors from "./DashboardInvestors/DashboardInvestors";
import CategoryCoverage from "./CategoryCoverage/CategoryCoverage";
import { selectPersonalDashboardName } from "../../../store/dashboard/dashboardSlice";
import { getPersonalDashboardName } from "../../../store/dashboard/dashboardThunks";
import ActiveCompanies from "./ActiveCompanies/ActiveCompanies";
import RecommendedBacklog from "./RecommendedBacklog/RecommendedBacklog";

export default function Data() {
  const dispatch = useDispatch();

  const data = useSelector(selectPersonalDashboardName);

  useEffect(() => {
    dispatch(getPersonalDashboardName());
  }, []);

  return (
    <>
      {data && <h3 className="ml-3 mb-5">{data[0]["Name Only"]}</h3>}
      {data && <OnwersInfo personalName={data[0]["Name Only"]} isFpc={false}/>}
      <ActiveCompanies />
      <TroubleBreakin />
      <RecommendedBacklog />
      <CategoryCoverage />
      <DashboardInvestors />
    </>
  );
}
