import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Done } from '@material-ui/icons';
import { updateInternalNotes } from '../../store/sourcing/sourcingThunks';

import styles from './styles.module.scss';
import EditIcon from "../EditIcon/EditIcon";

interface InternalNotesProps {
  data: any;
  companyId: number
}

const InternalNotes = ({ companyId, data }: InternalNotesProps) => {
  const [isEditable, setIsEditable] = useState(false);
  const [problemSolvingText, setProblemSolvingText] = useState("");
  const [problemSolvingPreviousText, setProblemSolvingPreviousText] = useState(
    ""
  );
  const [differentiationText, setDifferentiationText] = useState("");
  const [
    differentiationPreviousText,
    setDifferentiationPreviousText,
  ] = useState("");
  const [teamText, setTeamText] = useState("");
  const [teamPreviousText, setTeamPreviousText] = useState("");
  const [gotomarketText, setGotomarketText] = useState("");
  const [gotomarketPreviousText, setGotomarketPreviousText] = useState("");

  const toggleEditable = () => {
    setIsEditable(!isEditable);
  };

  const dispatch = useDispatch();

  const save = () => {
    setProblemSolvingPreviousText(problemSolvingText);
    setDifferentiationPreviousText(differentiationText);
    setTeamPreviousText(teamText);
    setGotomarketPreviousText(gotomarketText);
    toggleEditable();

    dispatch(updateInternalNotes({
      companyId: companyId,
      differentiation: differentiationText,
      gtm: gotomarketText,
      problem: problemSolvingText,
      team: teamText,
    }));
  };

  const cancel = () => {
    setProblemSolvingText(problemSolvingPreviousText);
    setDifferentiationText(differentiationPreviousText);
    setTeamText(teamPreviousText);
    setGotomarketText(gotomarketPreviousText);
    toggleEditable();
  };

  const handleTextChange = (value: any, setter: any) => {
    setter(value);
  };

  useEffect(() => {
    if (data?.length) {
      setProblemSolvingText(data?.[0]?.["What Problem Are They Solving"]);
      setProblemSolvingPreviousText(data?.[0]?.["What Problem Are They Solving"]);

      setDifferentiationText(data?.[0]?.["Product Differentiation"]);
      setDifferentiationPreviousText(data?.[0]?.["Product Differentiation"]);

      setTeamText(data?.[0]?.["Why Do They Lose"]);
      setTeamPreviousText(data?.[0]?.["Why Do They Lose"]);
      
      setGotomarketText(data?.[0]?.["Why Right Team / Product / Strategy / Timing"]);
      setGotomarketPreviousText(data?.[0]?.["Why Right Team / Product / Strategy / Timing"]);
    }
  }, [data]);

  return (
    <>
      <div className={styles.internalNotesTitleContainer}>
        <p className={`${styles.internalNotesText} font-14 font-weight-bold`}>Internal Notes</p>
        {!isEditable && (
            <IconButton
                aria-label="eidt"
                size="small"
                onClick={toggleEditable}
            >
              <EditIcon fontSize='small' />
            </IconButton>
        )}
        {isEditable && (
          <Button size="small" id="blockElement" className={`${"btn-neutral-primary"} mb-2`} onClick={save}>
            <Done/>
          </Button>
        )}
        {isEditable && (
          <Button size="small" id="blockElement" className={`${"btn-neutral-primary"} mb-2 ml-2`} onClick={cancel}>
            <Close/>
          </Button>
        )}
      </div>
      <div className={styles.internalNotesCardContainer}>
        <div className={styles.singleSection}>
          <p className={`${styles.internalNotesSectionText} font-12`}>
            Product: What problem do they solve? Secret Sauce? Tech Moat?
          </p>
          <div className={styles.internalNotesTextSection}>
            <div>
              {isEditable ? (
                <textarea
                  value={problemSolvingText}
                  className={`${`${styles.editableTextArea} font-12`} font-12`}
                  onChange={(event) =>
                    handleTextChange(event.target.value, setProblemSolvingText)
                  }
                />
              ) : (
                <textarea
                  value={problemSolvingText}
                  className={`${styles.fixedTextArea} font-12`}
                  readOnly
                />
              )}
            </div>
          </div>
        </div>
        {/* TODO: check this part with client */}
        {/* <div className={styles.singleSection}>
          <p className={`${styles.internalNotesSectionText} font-12`}>
            How is the product unique? What's the secret sauce?
          </p>
          <div className={styles.internalNotesTextSection}>
            <div>
              {isEditable ? (
                <textarea
                  value={differentiationText}
                  className={`${styles.editableTextArea} font-12`}
                  onChange={(event) =>
                    handleTextChange(event.target.value, setDifferentiationText)
                  }
                />
              ) : (
                <textarea
                  value={differentiationText}
                  className={`${styles.fixedTextArea} font-12`}
                  readOnly
                />
              )}
            </div>
          </div>
        </div> */}
        <div className={styles.singleSection}>
          <p className={`${styles.internalNotesSectionText} font-12`}>
            GTM: Who buys and why? Brownfield or Greenfield? Why do they say no?
          </p>
          <div className={styles.internalNotesTextSection}>
            <div>
              {isEditable ? (
                <textarea
                  value={teamText}
                  className={`${styles.editableTextArea} font-12`}
                  onChange={(event) =>
                    handleTextChange(event.target.value, setTeamText)
                  }
                />
              ) : (
                <textarea
                  value={teamText}
                  className={`${styles.fixedTextArea} font-12`}
                  readOnly
                />
              )}
            </div>
          </div>
        </div>
        <div className={styles.singleSection}>
          <p className={`${styles.internalNotesSectionText} font-12`}>
            Considerations: team, market, product, strategy, timing
          </p>
          <div className={styles.internalNotesTextSection}>
            <div>
              {isEditable ? (
                <textarea
                  value={gotomarketText}
                  className={`${styles.editableTextArea} font-12`}
                  onChange={(event) =>
                    handleTextChange(event.target.value, setGotomarketText)
                  }
                />
              ) : (
                <textarea
                  value={gotomarketText}
                  className={`${styles.fixedTextArea} font-12`}
                  readOnly
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InternalNotes;
