import React, { FunctionComponent, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';
import { TFunction, withTranslation } from 'react-i18next';
import { withRouter, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { Button } from '@material-ui/core';

import { setSidebarToggleMobile } from '../../store/themeOptions/themeOptionsSlice';
import { logout } from '../../store/user/userSlice';
import { MapDispatch, RootState } from '../../store';
import { useLocation } from "react-router";
import SidebarHeader from "../Sidebar/SidebarHeader/SidebarHeader";
import { makeStyles } from '@material-ui/core/styles';
import MenuSection from '../../components/Menu';
import UniversalSearch from '../../components/UniversalSearch';

const MENU_ITEMS = {
  SOURCING: [
  {
    name: 'Companies',
    path: '/sourcing/companies/company'
  },
  {
    name: 'Categories',
    path: '/sourcing/categories'
  },
  {
    name: 'Financings',
    path: '/sourcing/financings/all'
  },
  {
  name: 'Hygiene',
  path: '/sourcing/hygiene'
  },
  {
    name: 'Opportunities',
    path: '/sourcing/opportunities'
  },
  {
    name: 'Meeting Notes',
    path: '/sourcing/ai-notes'
  }
  // {
  //   name: 'Whitespace',
  //   path: '/sourcing/whitespace/category'
  // }
  ],
  RELATIONSHIPS: [
    {
      name: 'People',
      path: '/relationships/people'
    },
    {
      name: 'Investors',
      path: '/relationships/investors/all'
    },
    {
      name: 'Customers',
      path: '/relationships/customers'
    }],
  RESEARCH: [
    {
      name: 'Forgepoint Live',
      path: 'https://forgepoint.photoninsights.com/'
    },
    {
      name: 'DocuInsights',
      path: 'https://forgepoint.photoninsights.com/document-insight'
    },
    {
      name: 'DD Button',
      path: 'https://forgepoint.photoninsights.com/diligence-suite'
    }],
  DASHBOARD: [
      {
        name: 'Personal',
        path: '/dashboard/personal'
      },
      {
        name: 'Forgepoint',
        path: '/dashboard/forgepoint'
      }],
}

export type TElement = Element | ((element: Element) => Element);

interface HeaderProps extends ConnectedProps<typeof connector> {
  t: TFunction<string>;
}

const useStyles = makeStyles((theme) => ({
  button: {
    color: 'black !important',
    '&:hover': {
      color: 'black !important',
    },
  },
}));

const Header: FunctionComponent<HeaderProps> = ({
  headerShadow,
  headerBgTransparent,
  sidebarToggleMobile,
  setSidebarToggleMobile,
  logout,
  t,
}) => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();

  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  const [anchorEl, setAnchorEl] = useState<TElement | null>(null);

  const handleClick = (event: { currentTarget: TElement }) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [value, setValue] = useState(0);
  // @ts-ignore
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div
        className={clsx('app-header', {
          'app-header--shadow': false,
          'app-header--opacity-bg': headerBgTransparent,
        })}
      >
        <div className="app-header--pane">
          <SidebarHeader />
          <Button
            className={clsx(
              'navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
              { 'is-active': sidebarToggleMobile }
            )}
            onClick={toggleSidebarMobile}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </Button>
        </div>
        <div className="app-header--pane">
          <div className="d-flex align-items-center justify-content-center flex-wrap">
            {!location.pathname.includes('silent-refresh') && <UniversalSearch />}
            <MenuSection menuItems={MENU_ITEMS.DASHBOARD} activeTab={location.pathname.includes('dashboard')} name='dashboard'/>
            <MenuSection menuItems={MENU_ITEMS.SOURCING}  activeTab={location.pathname.includes('sourcing')} name='sourcing'/>
            <MenuSection menuItems={MENU_ITEMS.RELATIONSHIPS} activeTab={location.pathname.includes('relationships')} name='relationships'/>
            <MenuSection menuItems={MENU_ITEMS.RESEARCH} activeTab={location.pathname.includes('research')} name='research' type='absolute'/>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  headerShadow: state.ThemeOptions.headerShadow,
  headerBgTransparent: state.ThemeOptions.headerBgTransparent,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
});

const mapDispatchToProps = (dispatch: MapDispatch) => ({
  setSidebarToggleMobile: (enable: boolean) =>
    dispatch(setSidebarToggleMobile(enable)),
  logout: () => dispatch(logout()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default compose<FunctionComponent>(
  withTranslation(),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Header);
