import React, { useEffect, useState } from "react";

export const useCinchyUser = () => {
    const [userData, setUserData] = useState(() => {
        // Try to get user data from localStorage on initial load
        const storedUser = localStorage.getItem('cinchyUser');
        return storedUser ? {...JSON.parse(storedUser), fromStorage: true} : null;
    });

    useEffect(() => {
        const handleUserLoad = (event) => {
            // Get user data from localStorage when event is caught
            const user = event.detail;
            console.log('User loaded Detail', user);
            setUserData(user);
        };

        document.addEventListener('CinchyUserLoaded', handleUserLoad);

        return () => document.removeEventListener('CinchyUserLoaded', handleUserLoad);
    }, []);

    return userData;
};