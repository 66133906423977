import React, { useState } from 'react';
import { Button } from '@material-ui/core';

import CreateCompanyModal from '../../../components/CreateCompanyModal';
import InsertRowModal from './InsertRowModal';
import ColumnsModal from './ColumnsModal';

import styles from './styles.module.scss';

export default function TableActions({
     columns,
     selectedColumns,
     handleSelectColumns,
     openNotification
}) {

    const handleOpenNotification = (message) => {
        openNotification(message)
    }

    const [isColumnsModalOpen, setIsColumnsModalOpen] = useState(false);
    const [isCreateCompModalOpen, setIsCreateCompModalOpen] = useState(false);
    const [isInsertModalOpen, setIsInsertModalOpen] = useState(false);


    return (
        <>
            <div className="d-flex justify-content-between align-items-center pb-2">
                <div className={styles.actions}>
                    <Button
                        size="medium"
                        onClick={() => setIsColumnsModalOpen(!isColumnsModalOpen)}
                        className='btn-second mr-2'
                    >
                        <span className="btn-wrapper--label font-14">edit columns</span>
                    </Button>
                    <Button
                        size="medium"
                        onClick={() => setIsInsertModalOpen(true)}
                        className='btn-second mr-2'
                    >
                        <span className="btn-wrapper--label font-14">Add Person</span>
                    </Button>
                </div>
            </div>

            <CreateCompanyModal
                isOpen={isCreateCompModalOpen}
                onClose={() => setIsCreateCompModalOpen(false)}
            />

            <InsertRowModal
                isOpen={isInsertModalOpen}
                onClose={() => setIsInsertModalOpen(false)}
                openNotification={(message) => handleOpenNotification(message)}
            />

            <ColumnsModal
                isOpen={isColumnsModalOpen}
                setIsOpen={setIsColumnsModalOpen}
                columns={columns}
                selectedColumns={selectedColumns}
                handleSelectColumns={handleSelectColumns}
            />
        </>
    );
}
